import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import { Calendar, Formats, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarHeader from './CalendarHeader';
import WeekEvent from '../../../../components/src/calendarWeb/WeekEvent';
import MonthEvent from '../../../../components/src/calendarWeb/MonthEvent';
import DayCell from '../../../../components/src/calendarWeb/DayCell';
import { Box, Typography, styled } from '@material-ui/core';
import { leftArrow, rightArrow } from '../assets';
import { getWeekRange } from '../../../../components/src/helpers';
import { Shedule } from '../CustomisableUserProfilesController';

type Keys = keyof typeof Views;

type Props = {
    calendarView: (typeof Views)[Keys];
    calendarDate: Date;
    changeView?: (view: (typeof Views)[Keys]) => void;
    changeDate?: (date: Date) => void;
    events: Shedule[];
    onEventSelect: (event: Shedule | null) => void;
    fetchAppointments: (view: (typeof Views)[Keys], date: Date) => void;
}

export interface FormattedEvent extends Shedule {
  start: Date;
  end: Date;
}

const localizer = momentLocalizer(moment);

const formats: Formats = {
    timeGutterFormat: (date, _, localizer) => localizer?.format(date, "ha") ?? "",
    agendaTimeFormat: (date, _, localizer) =>
      localizer?.format(date, "h:mma") ?? "",
};

const CustomerCalendar = ({ events, onEventSelect, fetchAppointments, calendarDate, calendarView, changeDate = () => undefined, changeView = () => undefined }: Props) => {
    const [formattedEvents, setFormattedEvents] = useState<FormattedEvent[]>([]);

    useEffect(() => {
      fetchAppointments(calendarView, calendarDate);
    }, [calendarDate, calendarView])

    useEffect(() => {
      if(events.length) {
        const formatted = events.map(event => ({
          ...event,
          start: moment(event.start_date).toDate(),
          end: moment(event.end_date).toDate(),
        }))
        setFormattedEvents(formatted)
      }
    }, [events])

    const components = {
        header: CalendarHeader,
        event: calendarView === Views.WEEK ? WeekEvent : MonthEvent,
        dateCellWrapper: (props: any) => (
            <DayCell events={events} calendarDate={calendarDate} view={calendarView} {...props} />
        ),
    };

    const onPrevClick = useCallback(() => {
        if (calendarView === Views.WEEK) {
          changeDate(moment(calendarDate).subtract(1, "w").toDate());
        } else {
          changeDate(moment(calendarDate).subtract(1, "M").toDate());
        }
      }, [calendarView, calendarDate]);
    
      const onNextClick = useCallback(() => {
        if (calendarView === Views.WEEK) {
          changeDate(moment(calendarDate).add(1, "w").toDate());
        } else {
          changeDate(moment(calendarDate).add(1, "M").toDate());
        }
      }, [calendarView, calendarDate]);

    
      const renderHeaderContent = () => {
        if(calendarView === Views.MONTH) {
          return (
            <>
              <Typography className='month-name'>{moment(calendarDate).format("MMMM")}</Typography>
              <Typography className='year-name'>{moment(calendarDate).year()}</Typography>
            </>
          )
        } else {
          return (
            <Typography className='week-name'>{getWeekRange(calendarDate)}</Typography>
          )
        }
      }

    return (
        <AppWrapper>
            <style>{ScrollbarStyles}</style>
                <Box className="border-wrapper">
                    <Box className="calendar-wrapper">
                        <Box className="calendar-control">
                            <Box className='header-wrapper'>
                                <Box className="header-month">
                                    <Box data-test-id="prev-arrow" onClick={onPrevClick}>
                                        <img src={leftArrow} className='arrow-style'/>
                                    </Box>
                                    <Box className='header-year-month'>
                                        {renderHeaderContent()}
                                    </Box>
                                    <Box data-test-id="next-arrow" onClick={onNextClick}>
                                        <img src={rightArrow} className='arrow-style'/>
                                    </Box>
                                </Box>
                                <Box className='view-switch'>
                                    <Box data-test-id="switch-week" onClick={() => changeView(Views.WEEK)} className={calendarView === Views.WEEK ? 'view-item-active' : 'view-item'}>Week</Box>
                                    <Box data-test-id="switch-month" onClick={() => changeView(Views.MONTH)} className={calendarView === Views.MONTH ? 'view-item-active' : 'view-item'}>Month</Box>
                                </Box>
                            </Box>
                        </Box>
                        <Calendar
                            localizer={localizer}
                            startAccessor="start"
                            endAccessor="end"
                            defaultDate={calendarDate}
                            date={calendarDate}
                            events={formattedEvents}
                            toolbar={false}
                            view={calendarView}
                            onView={changeView}
                            onNavigate={changeDate}
                            views={["week", "month"]}
                            defaultView="week"
                            style={{ height: calendarView === Views.WEEK ? '480px' : '420px' }}
                            components={components}
                            formats={formats}
                            onSelectEvent={(event) => onEventSelect(event)}
                            scrollToTime={new Date(2024, 7, 3, 8, 0, 0)}
                        />
                    </Box>
                </Box>
        </AppWrapper>
    )

}


const ScrollbarStyles = `
  .rbc-time-content::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
  }

  .rbc-time-content::-webkit-scrollbar-track {
    background: #CBD5E1;
    border-radius: 6px;
  }

  .rbc-time-content::-webkit-scrollbar-thumb {
    background: #6F05EA;
    border-radius: 4px; 
    width: 6px; 
  }

  .rbc-time-content::-webkit-scrollbar-thumb:hover {
    background: #6F05EA;
  }
`;

const AppWrapper = styled("div")(({ theme }) => ({
  fontFamily: 'Montserrat',

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: 'Montserrat',
  },

  "& .arrow-style": {
    userSelect: 'none', cursor: 'pointer'
  },
  "& .border-wrapper": {
    border: '1px solid #E2E8F0',
    padding: '8px',
    [theme.breakpoints.up("md")]: {
        padding: '16px',
      },
  },

  "& .month-name": {
    color: '#6F05EA',
    fontSize: '36px',
    fontWeight: 600
  },

  "& .week-name": {
    color: '#000',
    fontSize: '36px',
    fontWeight: 600
  },

  "& .year-name": {
    color: '#000000',
    fontSize: '36px',
    fontWeight: 600,
    borderBottom: '1px solid #000',
    padding: '0 12px'
  },

  "& .header-year-month": {
    display: 'flex',
    gap: '8px'
  },

  "& .view-switch": {
    background: '#F5F5F4',
    borderRadius: '50px',
    padding: '4px',
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    color: '#64748B',
    fontSize: '12px',
    fontWeight: 500
  },

  "& .view-item": {
    padding: '6px 12px'
  },

  "& .view-item-active": {
    padding: '6px 12px',
    background: '#F0E5FF',
    borderRadius: '50px',
    fontSize: '14px',
    fontWeight: 700,
    border: '1px solid #E1CCFF',
    color: '#6200EA',
  },

  "& .header-wrapper": {
    width: '100%',
    display: 'flex',
    padding: '16px 32px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up("md")]: {
        flexDirection: 'row',
        padding: '16px 60px',
      },
  },
  "& .header-month": {
    display: 'flex',
    alignItems: 'center',
    gap: '28px'
  },
  "& .calendar-wrapper": {
    width: "100%",
  },
  "& .rbc-allday-cell": {
    height: "auto",
    "& .rbc-row": {
      minHeight: 0,
    },
    "& .rbc-event": {
      "& .week-event-wrapper": {
        background: "#8833FF",
        padding: '3px 16px',
        "& .event-title": {
          color: "#fff",
        }
      },
      "& .top-time": {
        display: 'none'
      }
    },
  },
  "& .rbc-calendar": {
    padding: "0px",
    [theme.breakpoints.up("sm")]: {
        padding: "0 28px",
      },
  },
  "& .rbc-time-view": {
    border: "none",
  },
  "& .rbc-time-content": {
    border: "none",
  },
  "& .rbc-time-header": {
    marginRight: "6px !important",
  },
  "& .rbc-header": {
    borderLeft: "none !important",
    borderBottom: "none !important",
    paddingBottom: "8px",
  },
  "& .rbc-current-time-indicator": {
    backgroundColor: "#6F05EA",
    height: "2px",
  },
  "& .rbc-overflowing": {
    borderRight: "none !important",
    paddingTop: "16px",
    borderTop: "1px solid #B7B7B7",
  },
  "& .rbc-event, .rdc-background-event": {
    padding: "0px !important",
    border: "none !important",
    borderRadius: "18px",
    backgroundColor: "transparent",
  },
  "& .rbc-event-label": {
    display: "none",
  },
  "& .rbc-events-container": {
    width: "100% !important",
    borderRadius: "18px",
    border: "none",
  },
  "& .custom-header": {
    fontFamily: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: '8px',
  },
  "& .c-header-text": {
    fontSize: "14px",
    fontWeight: 600,
    color: "#6F05EA",
  },
  "& .calendar-control": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .rbc-timeslot-group": {
    minHeight: "66px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    borderLeft: "none !important",
    borderBottom: "1px solid #B7B7B7",
  },
  "& .rbc-time-slot": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flex: 0,
    fontWeight: 600,
    fontSize: '14px'
  },
  "& .rbc-time-header-content": {
    border: "none",
  },
  "& .rbc-month-header": {
    paddingTop: "12px",
    borderTop: "1px solid #B7B7B7",
  },
  "& .rbc-month-view": {
    border: 0,
  },
  "& .rbc-month-row": {
    maxHeight: "70px",
    borderTop: "none",
  },
  "& .rbc-date-cell": {
    display: "none",
  },
  "& .rbc-show-more": {
    display: "none",
  },
}));



export default CustomerCalendar;

