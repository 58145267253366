import React from 'react';
import { Shedule } from '../CustomisableUserProfilesController';
import Field from './Field';
import moment from 'moment';


type Props = {
    event: Shedule;
    isRequestCustomer: boolean;
    isRight?: boolean;
}

const RequestFields = ({ event, isRequestCustomer, isRight }: Props) => {
    const request = event.requests.request_details;

    if(isRight) {
        return (
            <>
                {isRequestCustomer && <Field title='Updated Start Date' value={moment(request?.start_date).format('DD/MM/YYYY')} />}
                {isRequestCustomer && request?.end_date && <Field title='Updated End Date' value={moment(request?.end_date).format('DD/MM/YYYY')} />}
                {isRequestCustomer && request?.shift && <Field title='Updated Time' value={request?.shift} />}
                <Field title='Service Type' value={event.service.service_type} />
            </>
        )
    }

    return (
        <>
        <Field title={isRequestCustomer ? 'Old Start Date' : 'Date'} value={moment(event.start_date).format('DD/MM/YYYY')} />
            {!!event.end_date && <Field title={isRequestCustomer && request?.end_date ? 'Old End Date' : 'End Date'} value={moment(event.end_date).format('DD/MM/YYYY')} />}
            {!!event.shift && <Field title='Time' value={event.shift} />}
            <Field title='Price' value={`${event.total_price}€`} />
        </>
    )
}

export default RequestFields;