import { Box, Typography, styled } from '@material-ui/core';
import React from 'react';


const ContentWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
    borderBottom: '1px solid #D9D9D9',
    "& .field-label": {
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 400
    },
    "& .field-value": {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 500
    },
});


const Field = ({title, value}: { title: string, value: string | undefined}) => {
    return (
        <ContentWrapper>
            <Typography className='field-label'>{title}</Typography>
            <Typography className='field-value'>{value ? value : '---'}</Typography>
        </ContentWrapper>
    )
}

export default Field;