import {
  Box,
  Button,
  CircularProgress,
  Typography,
  styled,
} from "@material-ui/core";
import { Formik } from "formik";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import CommonDatePicker from "../../../../components/src/Calendar.web";
import DatePickerInput from "../../../../components/src/DatePickerInput";
import GenericModal from "../../../../components/src/GenericModal";
import InputField from "../../../../components/src/InputField";
import {
  resheduleValidationDogWalking,
  resheduleValidationGeneral,
  resheduleValidationHotel,
} from "../../../../components/src/validators/validatiors";
import { ICustomSelect } from "../CustomisableUserProfiles.web";
import { Shedule } from "../CustomisableUserProfilesController";
import { ResheduleRequestFields } from "../types";
import Field from "./Field";
import { defaultButton } from "./FormWrapper";

interface IProps {
  isOpen: boolean;
  event: Shedule | null;
  title?: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (values: ResheduleRequestFields) => void;
  renderCustomField: (props: ICustomSelect) => JSX.Element;
}

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  "& .fields-wrapper": {
    display: "flex",
    gap: "32px",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .field-set": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    width: "100%",
  },
  "& .secondary-btn": {
    ...defaultButton,
    border: "2px solid #6200EA",
    padding: "10px 20px",
    color: "#6200EA",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "265px",
    },
  },
  "& .main-btn": {
    ...defaultButton,
    border: "2px solid #4B4B4B",
    color: "#4B4B4B",
    whiteSpace: "nowrap",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "265px",
    },
  },
  "& .box-wrapper": {
    marginTop: "35px",
    display: "flex",
    gap: "14px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      gap: "26px",
    },
  },
  "& .header-section": {
    dsiplay: "flex",
    flexDirection: "column",
    gap: "7px",
    marginBottom: "24px",
    [theme.breakpoints.up("lg")]: {
      marginBottom: "40px",
    },
  },
  "& .MuiSelect-select.MuiSelect-select": {
    paddingBottom: 0,
    height: "28px",
  },
  "& .header-text": {
    fontFamily: "Montserrat",
    fontSize: "20px",
    color: "#000",
    fontWeight: 500,
  },
  "& .header-title": {
    fontFamily: "Montserrat",
    fontSize: "23px",
    color: "#6F05EA",
    fontWeight: 700,
  },
}));

const labelStyle = {
  fontSize: "12px",
  fontWeight: 400,
  color: "#6F05EA",
  fontFamily: "Montserrat",
};

type ServiceType =
  | "Grooming"
  | "Dog Walking"
  | "Hotels"
  | "Training"
  | "Day Care"
  | "Pet Sitting";

const initialValues: ResheduleRequestFields = {
  start_date: "",
  end_date: "",
  shift: "",
  request_type: "Reschedule Request",
};

const ChangeRequestModal = (props: IProps) => {
  const {
    isOpen,
    title = "",
    event,
    onClose,
    onSubmit,
    isLoading,
    renderCustomField,
  } = props;

  const validationSchema: Record<ServiceType, Yup.ObjectSchema> = {
    Grooming: resheduleValidationGeneral,
    "Dog Walking": resheduleValidationDogWalking,
    Hotels: resheduleValidationHotel,
    Training: resheduleValidationGeneral,
    "Day Care": resheduleValidationGeneral,
    "Pet Sitting": resheduleValidationGeneral,
  };

  if (!event) {
    return null;
  }

  const isHotels = event.service.service_type === "Hotels";
  const isDogWalking = event.service.service_type === "Dog Walking";
  const others = !isHotels && !isDogWalking;

  const renderButtonContent = () => {
    if (isLoading) {
      return <CircularProgress style={{ color: "#6200EA" }} size="24px" />;
    } else {
      return "Request";
    }
  };

  const renderOldStartDate = () => {
    return (
      <Box style={{ opacity: 0.6 }}>
        <Field
          title="old Start Date"
          value={moment(event.start_date).format("DD/MM/YYYY")}
        />
      </Box>
    );
  };

  return (
    <GenericModal
      id="cancel-request"
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      bodyStyle={{ padding: "30px 50px" }}
      withoutCloseBtn
    >
      <ContentWrapper>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={
            validationSchema[event.service.service_type as ServiceType]
          }
          onSubmit={(values) => {
            onSubmit(values);
          }}
          data-test-id="reshedule-form"
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            handleBlur,
          }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Box className="header-section">
                  <Typography className="header-title">
                    Request a change
                  </Typography>
                  <Typography className="header-text">
                    we will wait for vendor approval for the change request.
                  </Typography>
                </Box>
                {isHotels && (
                  <Box className="fields-wrapper">
                    <Box className="field-set">
                      {renderOldStartDate()}
                      <DatePickerInput
                        errors={errors}
                        field="start_date"
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    </Box>
                    <Box className="field-set">
                      <Box style={{ opacity: 0.6 }}>
                        <Field
                          title="old End Date"
                          value={moment(event.end_date).format("DD/MM/YYYY")}
                        />
                      </Box>
                      <Box style={{ position: "relative" }}>
                        <CommonDatePicker
                          data-test-id="new-end-date"
                          selectedDate={
                            values.end_date
                              ? moment(values.end_date, "DD-MM-YYYY").toDate()
                              : null
                          }
                          minDate={new Date()}
                          handleDateChange={(date: Date | null) => {
                            setFieldValue(
                              "end_date",
                              moment(date).format("DD/MM/YYYY")
                            );
                          }}
                        />
                        <InputField
                          {...getFieldProps("end_date")}
                          error={
                            Boolean(errors["end_date"]) && touched["end_date"]
                          }
                          id="end_date"
                          name="end_date"
                          fullWidth={true}
                          helperText={(
                            touched["end_date"] && errors["end_date"]
                          )?.toString()}
                          labelStyle={labelStyle}
                          label="updated End Date"
                          placeholder="Select date"
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {isDogWalking && (
                  <Box className="fields-wrapper">
                    <Box className="field-set">
                      {renderOldStartDate()}
                      <DatePickerInput
                        errors={errors}
                        field="start_date"
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    </Box>
                    <Box className="field-set">
                      <Box style={{ opacity: 0.6 }}>
                        <Field title="old Time" value={event.shift} />
                      </Box>
                      <Box style={{ position: "relative" }}>
                        {renderCustomField({
                          handleBlur,
                          errors,
                          field: "shift",
                          disabledField: "isEditRequestChange",
                          values,
                          list: [
                            { label: "Morning", value: "morning" },
                            { label: "Evening", value: "evening" },
                          ],
                          touched,
                          handleChange: (event: any) => {
                            setFieldValue("shift", event.target.value);
                          },
                          getFieldProps,
                          editLabel: {
                            enabled: true,
                            styles: { fontSize: "12px" },
                          },
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
                {others && (
                  <Box className="fields-wrapper">
                    <Box className="field-set">{renderOldStartDate()}</Box>
                    <Box className="field-set">
                      <DatePickerInput
                        errors={errors}
                        field="start_date"
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    </Box>
                  </Box>
                )}
                <Box className="box-wrapper">
                  <Button className="main-btn" onClick={() => onClose()}>
                    Cancel
                  </Button>
                  <Button
                    className="secondary-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {renderButtonContent()}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </ContentWrapper>
    </GenericModal>
  );
};

export default ChangeRequestModal;
