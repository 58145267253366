import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Typography,CircularProgress, Grid,IconButton } from "@material-ui/core";
import { upload,Cross } from "./assets";
// Customizable Area End

import CustomisableUserGallaryController, {
  Props,
} from "./CustomisableUserGallaryController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
      
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const blobUrlMap = new Map();
const GallarySection = styled(Box)({
    width:'100%',
    minHeight:450,
    height:'auto',
    background:'#fff',
    border:'0px 0px 0px 4px',
    boxShadow:'0px 2px 8px 0px #0000000F',
    alignSelf: 'center',
    padding:30,
  display: 'flex',
  flexDirection:'column',
  boxSizing:'border-box',

  marginBottom:40,
    borderRadius:16,
    "& .Upload-button":{width:'100%',display:'contents',boxSizing:'border-box'},
    "& .button-box": {
        display:'flex',
        alignSelf:'flex-end',
        marginRight:35,
        marginTop:35
    },
    "& .UploadedImage-section":{
      marginTop:45,
      "@media (max-width: 959px)": {
        marginTop: "130px",
      },
      "@media (min-width: 960px)": {
        marginTop: "55px",
      }
     
    },
    
    "& .Login-Btn": {
        border: "1px solid #6F05EA",
        borderRadius: "100px",
        padding: "10px 0px 10px 0px",
        height: "37px",
        fontSize: "18px",
        gap: "10px",
        fontWeight: 700,
        textTransform: "none",
        color: "#6200EA",
        backgroundColor: "transparent",
        boxShadow: '0px 8px 32px 0px #0000000F',
        width:137
      },
      "& .cancel-Btn": {
        border: '1px solid #78716C',
        borderRadius: "100px",
        padding: "10px 0px 10px 0px",
        height: "37px",
        fontSize: "18px",
        gap: "10px",
        fontWeight: 700,
        textTransform: "none",
        color: "#78716C",
        backgroundColor: "transparent",
        
        width:137,
        boxShadow: '0px 8px 32px 0px #0000000F',
      },
      "& .booking-single-img": {
        width: "100%",
        height: "417px",
        "@media (max-width: 1280px)": {
          height: "494px",
        },
        "@media (max-width: 960px)": {
          height: "434px",
        },
        "@media (max-width: 600px)": {
          height: "242px",
        },
        "@media (max-width: 430px)": {
          height: "176px",
        },
      },
      "& .cross-icon":{
        position: "absolute",
        top: -9,
        right: -11,
        
        borderRadius: "50%",
      },
    "& .upload-imagelogo": {
        display:'flex',alignSelf:'center',marginTop:110,flexDirection:'column',width:'100%',alignItems:'center'
       },
})
const ContentStyle=styled(Box)({
    display:'flex',
    width:'100%',
    flexDirection:'column',
    maxHeight: '60px',
  marginTop: 10,
  height:' 100%',
  boxSizing:'border-box',
  "& .header-upload": {
    marginTop:10
   },
   "& .header-title": {
    fontSize:20,
    fontWeight:700,
    color:'#000',
    width:'100%'
   },
   "& .header-subtitle": {
    fontSize:14,
    fontWeight:400,
    color:'#000'
   },
  
})
// Customizable Area End

export default class CustomisableUserGallary extends CustomisableUserGallaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getMediaType = (url:string) => {
    if (/\.(jpg|jpeg|png|gif)$/i.test(url)) {
      return "image";
    } else if (/\.(mp4|webm|ogg)$/i.test(url)) {

      return "video";
    } else if (url && url.startsWith("blob:")) {

      const mimeType = blobUrlMap.get(url);
      if (mimeType) {
        if (mimeType.startsWith("image/")) {
          return "image";
        } else if (mimeType.startsWith("video/")) {
          return "video";
        } else  {
          return "unknown";
        }
      }
    }
    return "image";
  };
  
  handleMediaSelect(event:any) {
    const files = event.target.files;
    this.setState({ referenceImage: event.target.files?.[0] });
    const newMediaArray = Array.from(files).map((file, index) => ({
      id: this.state.uploadedImagePreview.length + index,
      src: this.createBlobUrl(file),
      imagefile:file
    }));
    this.setState((prevState) => ({
        uploadedImagePreview: [...prevState.uploadedImagePreview, ...newMediaArray],
        uploadNewImagePre: [...prevState.uploadNewImagePre, ...newMediaArray],
        isEditImage:false
    }));
  }
      
     createBlobUrl = (file:any) => {
        const url = URL.createObjectURL(file);
        blobUrlMap.set(url, file.type);
        return url;
      };
   
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { uploadedImagePreview } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <GallarySection>
     <ContentStyle>
  <Box style={{width: '100%',
  textAlign: 'center'}}>{this.state.uploadedImagePreview.length<=0 && (<Typography className="header-title">No images added yet.</Typography>)}</Box>
  
  </ContentStyle>

  {this.state.uploadedImagePreview.length<=0 && (
  <Box className="upload-imagelogo"> 
  <Box >
      <Box><img src={upload}/></Box>
  </Box>
  <Box className="Upload-button">
  <Box className="button-box">
                    <Button  className="Login-Btn" component="label" data-test-id="uploadBtn2">
                       {this.state.uploadedImagePreview.length<=0 && (
                      <>
                      <div >Upload</div>
                       </>
                         )}
                        <input
                         style={{display:'none'}}
                          name="image"
                          type="file"
                          multiple
                          data-test-id="uploadInputBtn"
                         accept="image/*,video/*"
                        onChange={(event) => {
                         this.handleMediaSelect(event);
                          }}
                          />
                        </Button>
                         </Box>
                         </Box>
                         </Box>
                          )}
                          
 {this.state.uploadedImagePreview.length>=1 && ( 
    <Box className="UploadedImage-section">
                      <Grid container spacing={2}>
        {uploadedImagePreview.map((media: { id: number ; src: string ;imageId:number }) => {
          return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={media.id}
            style={{ position: "relative"}}
          >
           <Box
           data-test-id='mediatype'
  component={this.getMediaType(media.src) === "image" ? "img" : "video"}
  sx={{
    width: "100%",
    borderRadius: 20,
    height: "auto",

  }}
  {...(this.getMediaType(media.src) === "image" || this.getMediaType(media.src) === "video"|| this.getMediaType(media.src) === "unknown"  
    ? { src: media.src, alt: `Media ${media.id}` }:{ src: media.src})} 
/>
            {!this.state.isEditImage && (  
                <IconButton
              data-test-id="deleteBtn"
              aria-label="delete"
              onClick={() => this.handleDeleteMedia(media.id)}
              className="cross-icon"
            >
              <img src={Cross}/>
            </IconButton>
            ) }
            
          </Grid>
           )
         })}
      </Grid>
                        <Box style={{display:'flex',justifyContent:'flex-end'}}>
  <Box className="button-box">
                    
                   {this.state.isEditImage && ( 
                <Button component="label" className="Login-Btn" data-test-id="closeAddImageSection"  
                    onClick={() => this.CalleditImage()}>
                      <Typography>Edit</Typography>
                        </Button>
                        )}
                        {!this.state.isEditImage && ( 
                            <Box style={{display:'flex',gap:'10px'}}>
                        <Button component="label" className="cancel-Btn" data-test-id="closeAddImageSection2"  onClick={() => this.CalleditImage()}>
                      <Typography>Cancel</Typography>
                        </Button>
                        <Button className="Login-Btn" component="label" >
                      <Typography>Upload</Typography>
                      <input
                         style={{display:'none'}}
                          data-test-id="upload_referenceImage"
                         type="file"
                         accept="image/png, image/jpeg"
                         multiple
                         name="image"
                        onChange={(event) => {
                         this.handleMediaSelect(event);
                          }}
                          />
                        </Button>
                        <Button  data-test-id="handleSaveImage"  component="label" className="Login-Btn"
                        onClick={() => this.SaveUploadedImage()}>
                        {!this.state.loading ? (
          <CircularProgress size={25} />
        ) : (
          <Typography>Save</Typography>
        )}
                        </Button>
                        </Box>
                         )}
                        </Box>
                        </Box>
                        </Box>
        )}
   
          </GallarySection>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
