// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";

interface IAddFormStep1Modal {
  name: string;
  role: string;
  email: string;
  phoneNumber: string;
  service: string;
  subService: string;
  profileImg: File;
}

const empList = [
  {
    id: 1,
    name: "Dianne Russell",
    email: "deanna.curtis@gmail.com",
    serviceType: "Grooming",
  },
  {
    id: 2,
    name: "Courtney Henry",
    email: "courtney.henry@example.com",
    serviceType: "Day Care",
  },
  {
    id: 3,
    name: "Bessie Cooper",
    email: "bessie.cooper@yopmail.com",
    serviceType: "Training",
  },
  {
    id: 4,
    name: "Ronald Richards",
    email: "ronald.richards@example.com",
    serviceType: "Dog Walking",
  },
  {
    id: 5,
    name: "Jane Cooper",
    email: "jane.cooper@example.com",
    serviceType: "Training",
  },
  {
    id: 6,
    name: "Wade Warren",
    email: "wade.warren@example.com",
    serviceType: "Day Care",
  },
];

const initialDaysCheckbox = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
};

type DayKey =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorElMoreVert: HTMLElement | null;
  openPopoverId:number | null;
  isDeleteOpen:boolean,
  getEmployeeList:{
    id: number;
    name: string;
    email: string;
    serviceType: string;
  }[],
  isAddOpen:boolean,
  addModalStep1InitialValue: IAddFormStep1Modal,
  stepNumber:number,
  daysCheckbox: Record<DayKey, boolean>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EmployeeManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      anchorElMoreVert: null,
      openPopoverId: null,
      isDeleteOpen: false,
      getEmployeeList: empList,
      isAddOpen: false,
      addModalStep1InitialValue: {} as IAddFormStep1Modal,
      stepNumber: 1,
      daysCheckbox: initialDaysCheckbox,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleMoreVertClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    this.setState({
      anchorElMoreVert: event.currentTarget,
      openPopoverId: this.state.openPopoverId === id ? null : id,
    });
  };
  handleMoreVertClose = () => {
    this.setState({
      anchorElMoreVert: null,
      openPopoverId: null,
    });
  };

  handleDeleteOpen = () => {
    this.setState({ isDeleteOpen: true });
    this.handleMoreVertClose();
  };

  handleDeleteClose = () => {
    this.setState({ isDeleteOpen: false });
  };

  handleAddClose = () => {
    this.setState({ isAddOpen: false, stepNumber: 1 });
  };

  handleNextClick = (value: IAddFormStep1Modal) => {
    this.setState({ addModalStep1InitialValue: value, stepNumber: 2 });
  };

  handleAddBackClick = () => {
    this.setState({ stepNumber: 1 });
  };

  SetScheduleDayCheckbox = (checkedValue: boolean, Day: DayKey) => {
    const updatedDaysCheckbox = {
      ...this.state.daysCheckbox,
      [Day]: checkedValue,
    };
    this.setState({
      daysCheckbox: updatedDaysCheckbox,
    });
  };

  handleAddEmpClick = () => {
    this.setState({ isAddOpen: true });
  };
  // Customizable Area End
}