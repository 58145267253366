import { Box, Typography, TextField, styled } from '@material-ui/core';
import React, { useState } from 'react'
import { appStoreLogo, applePayLogo, facebookLogo, googleSafeBrowserLogo, instaLogo, linkedInLogo, masterPayLogo, mbWayPayLogo, payPalPayLogo, visaPayLogo, youtubeLogo } from './assets';
import TermsConditions from '../../../blocks/termsconditions/src/TermsConditions.web';
interface IAppFooterModel {
  navigation?:any
}

interface IAppFooterModel {
  navigation?:any
}

export function AppFooter(props: IAppFooterModel) {
  const year = new Date(Date.now()).getFullYear().toString();
  const [termConditionModelType, setTermConditionModelType] =  useState<string>("");
  const [footerModelOpen, setFooterModelOpen] = useState<boolean>(false);
  const checkIsIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
  const handleAppStoreClick = (event:any) => {
    event.preventDefault();
    if (checkIsIOS()) {
        window.open("https://www.apple.com/in/app-store", '_blank');
    } 
  }

  const handleTermConditionClick = (type:string) => {
    setTermConditionModelType(type);
    setFooterModelOpen(!footerModelOpen);
  }
  const isVendor = localStorage.getItem("login_user_role") === "vendor";
  return (
    <AppFooterComponent>
      <style>
        {`
          .MuiOutlinedInput-notchedOutline{
            border: none
          }
        `}
      </style>
      <Box className="main-container">
        <Box className="first-container">
          <Box
            className="first-common first-petzai"
          >
            <Typography className="header">PETZAI</Typography>
            <Typography className="list-item" onClick={isVendor ? (() => props?.navigation?.navigate("Cfentrepriseaccount")) :() => props?.navigation?.navigate("Home")}>Home</Typography>
            <Typography className="list-item">About</Typography>
            <Typography className="list-item" onClick={() => props?.navigation?.navigate("EmailAccountLoginBlock")}>Login</Typography>
          </Box>
          <Box
            className="first-common service-provider"
          >
            <Typography className="header">Service Provider</Typography>
            <Typography className="list-item" style={{width:"15vw"}}  onClick={() => props?.navigation?.navigate("EmailAccountVendorRegistration")}>
              Become a Service Provider
            </Typography>
            <Typography className="list-item">Sell your services</Typography>
          </Box>
          <Box
            className="first-common sell-service"
          >
            <Typography className="header">Support</Typography>
            <Typography className="list-item" onClick={() => props?.navigation?.navigate("Contactus")}>Contact Us</Typography>
            <Typography className="list-item">FAQ</Typography>
          </Box>
          <Box
            className="first-common payment-method"
          >
            <Typography className="header">Payment methods</Typography>
            <Box className="payment-logos">
              <img src={visaPayLogo} alt="visa-pay-logo" />
              <img src={masterPayLogo} alt="master-pay-logo" />
              <img src={mbWayPayLogo} alt="mb-way-pay-logo" />
              <img src={payPalPayLogo} alt="pay-pal-pay-logo" />
              <img src={applePayLogo} alt="apple-pay-logo" />
            </Box>
          </Box>
          <Box
            className="first-common petzai-app"
          >
            <Typography className="header">Petzai App</Typography>
            <Box className="apple-download">
              <img src={appStoreLogo} alt="apple-store-logo" onClick={(event) => handleAppStoreClick(event)} className="apple-store-logo" />
            </Box>
          </Box>
          <Box
            className="first-common safety"
          >
            <Typography className="header">Safety</Typography>
            <img
              style={{ height: "45px", width: "156px" }}
              className='safety-logo'
              src={googleSafeBrowserLogo}
              alt="google-safe-browser-logo"
            />
          </Box>
        </Box>
        <Box className="second-container">
          <Box className='terms-condition'>
            <Box style={{display:"none"}}><TermsConditions navigation={props.navigation} id={''} footerType={termConditionModelType} footerOpen={footerModelOpen}/></Box>
            <Typography className="typography" onClick={() => handleTermConditionClick("termsCondition")}>Terms & Conditions</Typography>
          </Box>
          <Box className='privacy-policy'>
            <Typography className="typography" onClick={() => handleTermConditionClick("privacyPolicy")}>Privacy Policy</Typography>
          </Box>
          <Box className='cookie-policy'>
            <Typography className="typography" onClick={() => handleTermConditionClick("cookiePolicy")}>Cookie Policy</Typography>
          </Box>
          <Box
          className='follow-us-container'
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#C4C4C4",
                fontFamily: "Montserrat",
              }}
            >
              Follow us
            </Typography>
            <Box className='follow-us-icon'>
              <img src={facebookLogo} alt="facebook-logo" />
              <img src={instaLogo} alt="insta-logo" />
              <img src={linkedInLogo} alt="linkedIn-logo" />
              <img src={youtubeLogo} alt="youtube-logo" />
            </Box>
          </Box>
          <Box
          className='subscribe-container'
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#C4C4C4",
                fontFamily: "Montserrat",
              }}
            >
              Subscribe to our newsletter
            </Typography>
            <TextField
              placeholder="Email"
              variant="outlined"
              inputProps={{
                style: {
                  border: "1px solid #ffffff",
                  color: "#ffffff",
                  borderRadius: "25px",
                  padding: "12px",
                fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Montserrat",
                },
              }}
            />
          </Box>
        </Box>
        <Box className="third-container">
          <Typography>{`PETZAI © ${year} All rights reserved.`}</Typography>
        </Box>
      </Box>
    </AppFooterComponent>
  )
}

const AppFooterComponent = styled(Box)(({ theme }) => ({
    "& .main-container": {
      backgroundColor: "#3A3A3A",
      fontFamily: "Montserrat",
      width: "100%",
      display: "flex",
      alignContent: "center",
      flexDirection: "column",
      marginTop:"-2px",
      "& .first-container": {
        margin: "40px 160px 32px 160px",
        padding: "6px 95px",
        boxSizing:"border-box",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "& .first-common": {
          color: "#C4C4C4",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
          "& .payment-logos": {
            display: "flex",
            gap: "13px",
            flexWrap: "wrap",
          },
          "& .apple-download": {
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "fit-content",
            borderRadius: "10px",
            "& .apple-store-logo":{
              width:"100%",
              [theme.breakpoints.down("md")]: {
                width:"110px",
              }
            }
          },
          "& .header": {
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#FFFFFF",
            fontFamily: "Montserrat",
          },
          "& .list-item": {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "Montserrat",
            cursor:"pointer"
          },
        },
        "& .first-petzai":{
          width:"100px",
          [theme.breakpoints.between("md","lg")]: {
            width:"75px",
          },
          [theme.breakpoints.down(550)]: {
            width:"50%"
          }
        },
        "& .safety":{
          width: "180px",
          [theme.breakpoints.down("md")]: {
            width:"150px",
          },
          [theme.breakpoints.between("xs",905)]: {
            marginTop:"20px"
          },
          [theme.breakpoints.down(550)]: {
            width:"50%"
          }
        },
        "& .petzai-app":{
          width: "180px",
          [theme.breakpoints.down("md")]: {
            width:"150px",
          },
          [theme.breakpoints.between("xs",755)]: {
            marginTop:"20px"
          },
          [theme.breakpoints.down(550)]: {
            width:"50%"
          }
        },
        "& .payment-method":{
          width: "170px",
          [theme.breakpoints.between("xs",605)]: {
            marginTop:"20px"
          },
          [theme.breakpoints.down(550)]: {
            width:"50%"
          }
        },
        "& .service-provider":{
          width:"160px",
          [theme.breakpoints.down(550)]: {
            width:"50%",
          }
        },
        "& .sell-service":{
          width:"105px",
          [theme.breakpoints.down(550)]: {
            width:"50%",
            marginTop:"20px",
          }
        },
        [theme.breakpoints.between(1300,1540)]: {
          margin: "40px 85px 32px 85px",
          padding: "6px 60px"
        },
        [theme.breakpoints.between("md",1300)]: {
          margin: "40px 30px 32px 30px",
          padding: "6px 40px"
        },
        [theme.breakpoints.down("md")]: {
          margin: "40px 30px 32px 30px",
        },
        [theme.breakpoints.between("sm","md")]: {
          padding: "6px 15px"
        },
        [theme.breakpoints.down("sm")]: {
          margin: "20px 20px 32px 20px",
          padding: "6px 15px"
        },
        [theme.breakpoints.down(450)]: {
          margin: "20px 20px 0px 20px",
        },
      },
      "& .second-container": {
        margin: "0px 132px 32px 132px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        "& .typography": {
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "20px",
          color: "#C4C4C4",
          fontFamily:"Montserrat",
          cursor:"pointer"
        },
        "& .terms-condition":{
          width:"203px",
          [theme.breakpoints.between("md","lg")]: {
            width:"auto",
          },
          [theme.breakpoints.between(450,"md")]: {
            width:"33.33%",
          },
          [theme.breakpoints.between("xs",450)]: {
            width:"50%",
            marginTop:"20px",
          }
        },
        "& .privacy-policy":{
          width:"143px",
          [theme.breakpoints.between("md","lg")]: {
            width:"auto",
          },
          [theme.breakpoints.between(450,"md")]: {
            width:"33.33%",
          },
          [theme.breakpoints.between("xs",450)]: {
            width:"50%",
            marginTop:"20px",
          }
        },
        "& .cookie-policy":{
          width:"136px",
          [theme.breakpoints.between("md","lg")]: {
            width:"auto",
          },
          [theme.breakpoints.between(450,"md")]: {
            width:"33.33%",
          },
          [theme.breakpoints.between("xs",450)]: {
            width:"50%",
            marginTop:"20px",
          }
        },
        "& .follow-us-container":{
          width: "205px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          "& .follow-us-icon":{
            display: "flex", 
            gap: "29px",
            [theme.breakpoints.between("sm","md")]: {
              gap:"15px",
            },
            [theme.breakpoints.between(450,"sm")]: {
              gap:"10px",
            },
            [theme.breakpoints.between("xs",450)]: {
              gap:"10px",
            },
          },
          [theme.breakpoints.between("md","lg")]: {
            width:"auto",
          },
          [theme.breakpoints.between(450,"md")]: {
            width:"50%",
            marginTop:"20px",
          },
          [theme.breakpoints.between("xs",450)]: {
            width:"50%",
            marginTop:"20px",
          }
        },
        "& .subscribe-container":{
          width: "335px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          [theme.breakpoints.between("md","lg")]: {
            width:"auto",
          },
           [theme.breakpoints.between(450,"md")]: {
            width:"50%",
            marginTop:"20px"
          },
          [theme.breakpoints.between("xs",450)]: {
            width:"100%",
            marginTop:"20px",
          }
        },
        [theme.breakpoints.between("md","lg")]: {
          margin: "0px 30px 32px 30px",
        },
        [theme.breakpoints.between("xs","md")]: {
          margin: "0px 35px 32px 35px"
        },
      },
      "& .third-container": {
        display: "flex",
        height: "15px",
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: "15px",
        alignItems: "center",
        color: "#C4C4C4",
        justifyContent: "center",
        marginBottom: "50px",
        fontFamily: "Montserrat",
      },
    },
  }));