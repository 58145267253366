import React from 'react';
import { Button, CircularProgress, ButtonProps, styled, Box, Typography, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { returnTruthyString } from './HelperUtils';

interface BookingButtonProps extends ButtonProps {
    buttonText: string;
    imageTag: React.ReactNode;
    isLoading?: boolean;
}

export const BookingButton: React.FC<BookingButtonProps> = ({
    buttonText,
    imageTag,
    isLoading = false,
    disabled = false,
    ...rest
}) => {
    const buttonTitle = isLoading ? "Booking..." : buttonText
    return (
        <BookingButtonStyled disabled={isLoading || disabled} {...rest} >
            {isLoading ?
                (<>
                    {buttonTitle}  <CircularProgress style={defaultStyle.bookingButtonLoader} />
                </>) :
                (<>
                    {buttonTitle}  {imageTag}
                </>)
            }
        </BookingButtonStyled>
    );
};

const BookingButtonStyled = styled(Button)({
    width: "100%",
    borderRadius: "100px",
    padding: "16px 0px",
    textTransform: "none",
    color: "#fff",
    background: "linear-gradient(94.72deg, #6928F2 2.25%, #884FFF 97.5%)",
    boxShadow: "none",
    // FONT
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    "&.Mui-disabled": {
        color: "#fff !important"
    },
})
interface CustomButtonProps extends ButtonProps {
    isLoading?: boolean;
    buttonText: string;
    circularProgressStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
}

export const LoadingButton: React.FC<CustomButtonProps> = ({
    isLoading = false,
    buttonText,
    circularProgressStyle = defaultStyle.buttonLoader,
    buttonStyle,
    disabled = false,
    ...rest
}) => {
    return (
        <Button disabled={isLoading || disabled} {...rest} >
            {isLoading ? (<CircularProgress style={circularProgressStyle} />) : (
                buttonText
            )}
        </Button>
    );
};
interface RepeatButtonProps extends ButtonProps {
    checked: boolean;
    buttonText: string;
    checkedIcon: string
    unCheckedIcon: string
}
export const RepeatButton: React.FC<RepeatButtonProps> = ({
    checked,
    buttonText,
    checkedIcon,
    unCheckedIcon,
    ...rest
}) => {
    const checkedImage = <img src={checkedIcon} />
    const uncheckedImage = <img src={unCheckedIcon} />
    return (
        <CustomRepeat {...rest} style={checked ? defaultStyle.checkedStyle : defaultStyle.uncheckedStyle}>
            {checked ? checkedImage : uncheckedImage}
            {buttonText}
        </CustomRepeat>
    );
};
const CustomRepeat = styled(Button)({
    height: "32px",
    minWidth: "133px",
    borderRadius: "23px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    textTransform: "none",
    "& .MuiButton-label img": {
        marginRight: 6
    }
})

interface RepeatDayButtonProps extends ButtonProps {
    checked: boolean;
    buttonText: string;
}
export const RepeatDayButton: React.FC<RepeatDayButtonProps> = ({
    checked,
    buttonText,
    ...rest
}) => {
    return (
        <CustomRepeatDay {...rest} style={checked ? defaultStyle.checkedRepeatBtn : defaultStyle.unCheckedRepeatBtn}>
            {buttonText}
        </CustomRepeatDay>
    );
};
const CustomRepeatDay = styled(Button)({
    minWidth: 0,
    width: "44px",
    height: "49px",
    border: "0.5px solid #77777777",
    borderRadius: "6px",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform: "none",
    "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26) !important"
    }
})
interface CommoditiesProps {
    commoditiesArray: any[],
    CommoditiesIcon: string
}
export const CommoditiesList: React.FC<CommoditiesProps> = ({
    commoditiesArray,
    CommoditiesIcon,
    ...rest
}) => {
    const isCommoditiesLength = commoditiesArray.length
    const commoditiesList = commoditiesArray ? commoditiesArray : []
    return (
        <>
            {isCommoditiesLength ? (
                <CommoditiesBox container>
                    {commoditiesList?.map((record, index) => {
                        const commodityName = returnTruthyString(record.label)
                        return (
                            <CommoditiesLabel key={index} item lg={6} md={6} sm={12} xs={12}>
                                <img src={CommoditiesIcon} style={defaultStyle.commoditiesImg} />
                                <Typography>{commodityName}</Typography>
                            </CommoditiesLabel>
                        );
                    })}
                </CommoditiesBox>
            ) : (
                <Box style={{ margin: "33px 0 0 24px" }}>
                    <NoDataSpan>No Commodities Available</NoDataSpan>
                </Box>
            )}
        </>
    );
};
const CommoditiesBox = styled(Grid)({
    marginTop: "15px",
})
const CommoditiesLabel = styled(Grid)({
    marginTop: "25px",
    display: "flex",
    flexDirection: "row",
    columnGap: "21px"
})

interface CancellationPolicyProps {
    loading: boolean;
    policyText: string;
}

export const CancellationPolicy: React.FC<CancellationPolicyProps> = ({
    policyText,
    loading,
    ...rest
}) => {
    return (
        <>
            {loading ? <><LoaderContainer><CustomLoader /></LoaderContainer></> :
                <PolicySpanWrapper>
                    {policyText ? (<PolicySpan>{policyText}</PolicySpan>) : (
                        <NoDataSpan>No Cancellation Policy Available</NoDataSpan>
                    )}
                </PolicySpanWrapper>
            }
        </>
    );
};
const PolicySpanWrapper = styled(Box)({
    marginTop: "16px",
    padding: "18px 24px",
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
})
const PolicySpan = styled(`span`)({
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "27px",
    color: "#000000",
    whiteSpace: "pre-wrap",
})
const NoDataSpan = styled(`span`)({
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "27px",
    color: "#A6A6A6",
    whiteSpace: "pre-wrap",
})
const CustomLoader = styled(CircularProgress)({
    color: "#6F05EA"
});
const LoaderContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "50px",
    marginBottom: "50px"
})

interface SimpleDialogProps {
    open: boolean;
    handleClose?: () => void;
    message: string;
    showLeftBtn?: boolean,
    leftBtnTitle?: string,
    handleLeftClick?: () => void;
    showRightBtn?: boolean,
    rightBtnTitle?: string,
    handleRightClick?: () => void;
}

export const SimpleDialog: React.FC<SimpleDialogProps> = ({
    open, handleClose, message, showLeftBtn, leftBtnTitle, handleLeftClick, showRightBtn, rightBtnTitle, handleRightClick
}) => {
    return (
        <>
            <StyledDialog
                fullWidth
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
            >
                {handleClose ?
                    <DialogClose>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CrossIcon />
                        </IconButton>
                    </DialogClose>
                    : <></>}

                <DialogWrapper>
                    <Typography style={defaultStyle.dialogFont}>{message}</Typography>
                    <DialogButtonsWrapper>
                        {showLeftBtn && leftBtnTitle && handleLeftClick ?
                            <DialogBtn onClick={handleLeftClick} style={defaultStyle.dialogLeftBtn}>
                                {leftBtnTitle}
                            </DialogBtn> : <></>
                        }
                        {showRightBtn && rightBtnTitle && handleRightClick ?
                            <DialogBtn onClick={handleRightClick} style={defaultStyle.dialogRightBtn}>
                                {rightBtnTitle}
                            </DialogBtn> : <></>}
                    </DialogButtonsWrapper>
                </DialogWrapper>
            </StyledDialog>

        </>
    );
};
const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthMd": {
        maxWidth: "850px",
    },
})

const DialogClose = styled(Box)({
    display: "flex",
    justifyContent: "end",
})
const DialogWrapper = styled(Box)({
    padding: "90px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: "40px",
    alignItems: "center",
    textAlign: "center",
})
const DialogButtonsWrapper = styled(Box)({
    margin: "24px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    flexWrap: "wrap"
})
const CrossIcon = styled(CloseIcon)({
    height: 32,
    width: 32,
    color: "#000000"
})
const DialogBtn = styled(Button)({
    minWidth: 0,
    padding: "0 27px",
    height: "56px",
    borderRadius: "40px",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "35px",
    textTransform: "none",

    "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26) !important"
    }
})

interface DialogWithImageProps {
    open: boolean;
    handleClose: () => void;
    imageTag: React.ReactNode;
    title: string;
    subTitle: string;
    btnText: string;
    handleBtnClick: () => void;
}

export const DialogWithImage: React.FC<DialogWithImageProps> = ({
    open, handleClose, imageTag, title, subTitle, btnText, handleBtnClick
}) => {
    return (
        <>
            <DialogImage
                fullWidth
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
            >
                <ContentWrapper>
                    <DialogClose>
                        <IconButton aria-label="close" onClick={handleClose} style={{ padding: 0 }}>
                            <CrossIcon />
                        </IconButton>
                    </DialogClose>
                    <SubContentWrapper>
                        <Box>{imageTag}</Box>
                        <Box>
                            <Typography style={defaultStyle.imgDialogTitleFont}>{title}</Typography>
                            <Typography style={defaultStyle.imgDialogSubTitleFont}>{subTitle}</Typography>
                        </Box>
                        <DialogButtonsWrapper>
                            <DialogBtn onClick={handleBtnClick} style={defaultStyle.dialogRightBtn}>
                                {btnText}
                            </DialogBtn>
                        </DialogButtonsWrapper>
                    </SubContentWrapper>
                </ContentWrapper>
            </DialogImage>

        </>
    );
};
const DialogImage = styled(Dialog)({
    "& .MuiDialog-paperWidthMd": {
        maxWidth: "750px",
    },
})
const ContentWrapper = styled(Box)({
    padding: "32px"
})
const SubContentWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    rowGap: "40px",
    margin: "36px"
})

const defaultStyle = {
    bookingButtonLoader: {
        marginLeft: "32px",
        color: "#FFF",
        height: "24px",
        width: "24px"
    } as React.CSSProperties,
    buttonLoader: {
        color: "#6F05EA",
        height: "24px",
        width: "24px",
    } as React.CSSProperties,
    checkedStyle: {
        color: "#6F05EA",
        border: "0.5px solid #6F05EA",
    } as React.CSSProperties,
    uncheckedStyle: {
        color: "#B7B7B7",
        border: "0.5px solid #B7B7B7",
    } as React.CSSProperties,
    checkedRepeatBtn: {
        color: "#FFFFFF",
        backgroundColor: "#6F05EA"
    } as React.CSSProperties,
    unCheckedRepeatBtn: {
        color: "#0F172A",
        backgroundColor: "#FFFFFF"
    } as React.CSSProperties,
    commoditiesImg: {
        height: 24,
        width: 24
    } as React.CSSProperties,
    dialogFont: {
        maxWidth: "500px",
        fontFamily: "Montserrat",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    } as React.CSSProperties,
    dialogLeftBtn: {
        border: "2px solid #4B4B4B",
        color: "#4B4B4B"
    } as React.CSSProperties,
    dialogRightBtn: {
        border: "2px solid #6200EA",
        color: "#6200EA"
    } as React.CSSProperties,
    imgDialogTitleFont: {
        maxWidth: "540px",
        fontFamily: "Montserrat",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "34px",
        color: "#1E293B",
    } as React.CSSProperties,
    imgDialogSubTitleFont: {
        marginTop: "12px",
        maxWidth: "540px",
        fontFamily: "Montserrat",
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "34px",
        color: "#1E293B",
    } as React.CSSProperties,
}