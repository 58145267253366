import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SearchData } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";

export interface ISearchModel {
  country?: string;
  city?: string;
  startDate?: Date;
  endDate?: Date;
  pet?: string;
  everyWeek?: number;
  everyDay?: string;
  endAfter?: Date;
}
interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}

interface IFindServiceApiSuccessResponse {
  data: {
    id: number;
    service_type: string;
    image_url: string;
    about: string;
  }[];
}
interface IGetDiscountApiSuccessResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      image: {
        id: number;
        url: string;
      };
    };
  }[];
}


interface ListErrorDetail {
  detail?: string;
  account?: string;
  token?: string;
  error?: string;
}

interface IRecentlyBooked {
  schedule_id: number;
  service_id: number;
  sub_service_id: number;
  sub_services_price: number;
  establishment_id: number;
  establishment_name: string;
  establishment_address: string;
  establishment_latitude: number;
  establishment_longitude: number;
  sub_service_image: string;
}

interface IRecentlyBookedApiSuccessResponse {
  data: IRecentlyBooked[];
  meta: {
    message: string
  }
  errors?: ListErrorDetail[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  searchData: ISearchModel;
  findServiceDataList: {
    id: number;
    service_type: string;
    image_url: string;
    about: string;
  }[];
  discountBannerData: IGetDiscountApiSuccessResponse;
  recentlyBookedList: IRecentlyBooked[];
  //Loader
  getRecentlyBookedLoader:boolean
  getFindServiceLoader:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  findServiceListApiCallId: string = "";
  discountBannerApiCallId: string = "";
  recentlyBookedListApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      tabValue: 0,
      searchData: {},
      findServiceDataList: [],
      discountBannerData: {
        data: [],
      },
      recentlyBookedList: [],
      getRecentlyBookedLoader:false,
      getFindServiceLoader:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.findServiceListApiCallId:
            this.handleFindServiceListResponse(responseJson);
            break;
          case this.discountBannerApiCallId:
            this.handleDiscountBannerResponse(responseJson);
            break;
          case this.recentlyBookedListApiCallId:
            this.handleRecentlyBookedResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getFindServiceList(0);
    this.getDiscountBanner(0);
    this.getRecentlyBookedData(0);
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabValue: Number(newValue), recentlyBookedList:[], findServiceDataList:[] });
    this.getFindServiceList(Number(newValue));
    this.getDiscountBanner(Number(newValue));
    this.getRecentlyBookedData(Number(newValue));
  };

  goToRegisterPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegistration"
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  apiCall = async (data: IApiModel) => {
    const {
      contentType,
      method,
      endPoint,
      body,
      token,
      isJsonStringify,
    } = data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getFindServiceList = async (tabValue: number) => {
    this.setState({getFindServiceLoader:true});
    const serviceType = this.getTabTitle(tabValue);
      this.findServiceListApiCallId = await this.apiCall({
        method: configJSON.validationApiMethodType,
        token: localStorage.getItem("login_token"),
        endPoint: `${configJSON.landingPageFindServiceListApiEndPoint}${serviceType}`,
      });
  };

  handleFindServiceListResponse = (
    responseJson: IFindServiceApiSuccessResponse
  ) => {
    this.setState({getFindServiceLoader:false});
    if (responseJson) {
      this.setState({ findServiceDataList: responseJson.data });
    }
  };

  getTabTitle = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return "Hotels";
      case 1:
        return "Dog Walking";
      case 2:
        return "Grooming";
      case 3:
        return "Pet Sitting";
      case 4:
        return "Day Care";
      case 5:
        return "Training";
      default:
        return "Hotels";
    }
  };

  getDiscountBanner = async (tabData: number) => {
    const serviceType = this.getTabTitle(tabData);
    this.discountBannerApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getDiscountBannerApiEndPoint}${serviceType}`,
    });
  };

  handleDiscountBannerResponse = (
    responseJson: IGetDiscountApiSuccessResponse
  ) => {
    if (responseJson) {
      this.setState({ discountBannerData: responseJson });
    }
  };

  getRecentlyBookedData = async (tabData: number) => {
    this.setState({getRecentlyBookedLoader:true});
    const serviceType = this.getTabTitle(tabData);
    this.recentlyBookedListApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.landingPageRecentlyBookedListApiEndPoint}${serviceType}`,
    });
  };

  handleRecentlyBookedResponse = (
    responseJson: IRecentlyBookedApiSuccessResponse
  ) => {
    this.setState({getRecentlyBookedLoader:false});
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({ recentlyBookedList: responseJson.data });
    }else {
      this.setState({ recentlyBookedList: [] });
    }
  };

  goToAdvancedSearchPage = (data: SearchData) => {
    const NavigateMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    NavigateMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdvancedSearch"
    );
    NavigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);
    NavigateMsg.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(NavigateMsg);
  };

  goTOBookingPage = (data:any) => {
    localStorage.setItem("service_id", data.service_id);

    const ServiceId = String(data.service_id)
    const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
    NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Booking"); // ROUTE
    NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), ServiceId); // PARAM

    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      landingPageTabValue: this.state.tabValue,
    });
    NavigateMsg.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(NavigateMsg);
  };
  // Customizable Area End
}
