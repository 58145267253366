import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Modal,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@material-ui/core";
import { CommonBlackClose, cookieIcon } from "../../../components/src/assets";
import { closeIcon } from "../../../components/src/CustomDialog.web";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  palette:{
  secondary: {
    main: "#6200EA",
  },
  }
});

const ModalChildContainer = styled(Box)(({ theme }) => ({
  overflowY: "scroll",
  maxHeight: "565px",
  paddingRight: "33px",
  "& .MuiTypography-root": {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "495px",
  },
  [theme.breakpoints.down("xs")]: {
    maxHeight: "445px",
    paddingRight:"10px"
  },
  "@media (max-height: 720px)": {
    height: "430px",
  },
  "-ms-overflow-style": "none",
  "& .termConditionModelDescription > p":{
    margin:"0px",
    height:"100%"
  },
  "& .termConditionModelDescription":{
    fontSize:"16x",
    lineHeight:"25px",
    height:"100%"
  }
}));
const ModalTitleContainer = styled(Typography)(({ theme }) => ({
  color: "#6F05EA",
  fontWeight: 700,
  fontSize:"28px",
  margin: "4px auto auto auto",
  [theme.breakpoints.down("xs")]: {
    fontSize: "16px",
  },
}));
const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  textAlign: "justify",
  padding:"36px 28px 60px 60px",
  maxWidth: "955px",
  maxHeight: "650px",
  borderRadius: 0,
  margin: "0px 40px",
  [theme.breakpoints.down("md")]: {
    maxHeight: "650px",
  },
  [theme.breakpoints.down(1024)]: {
    maxHeight: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "800px",
    maxHeight: "550px",
  },
  [theme.breakpoints.down("xs")]: {
    margin: "0px 20px",
    maxHeight: "500px",
    padding:"16px 16px 24px 26px"
  },
  "@media (max-height: 720px)": {
    height: "500px",
  },
  "& .cookie-title-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  "& .cookies-btn": {
    fontWeight: "500",
    fontSize: "18px",
    textTransform: "none",
    borderRadius: "40px",
  },
  "& .cookies-select-btn": {
    color: "#B7B7B7",
    border: "1px solid #B7B7B7",
    padding: "5px 71px",
    [theme.breakpoints.down(766)]: {
      fontSize: "14px",
      padding:"5px 50px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding: "5px 71px",
    },
  },
  "& .cookies-all-btn": {
    color: "#6F05EA",
    border: "1px solid #6F05EA",
    padding: "5px 100px",
    [theme.breakpoints.down(766)]: {
      fontSize: "14px",
      padding:"5px 50px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding:"5px 95px "
    },
  },
  "& .cookies-btn-container": {
    display: "flex",
    gap: "18px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "152px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  "& .cookie-text": {
    fontSize: "16px",
    fontWeight: "400",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  "& .cookie-type-container": {
    display: "flex",
    alignItems: "flex-start",
    marginBottom:"20px",
  },
  "& .cookie-checkbox": {
    padding: "0px",
    marginRight: "25px",
    marginTop:"10px",
    '&:hover': {
        backgroundColor:"transparent",
      }
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#6F05EA",
  },
  "& .cookie-title-name": {
    fontSize: "16px",
    fontWeight: "600",
  },
  "& .cookie-info-text": {
    fontSize: "14px",
  },
  "& .close-icon":{
    height:"24px",
    width:"24px",
    "&:hover": {
      backgroundColor: "transparent"
    },
  }
}));
const CookieModalTitleContainer = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "28px",
  display:"flex",
  alignItems:"center",
  gap:"10px",
  height:"40px",
  [theme.breakpoints.down("xs")]: {
    fontSize: "16px",
  },
  "& .cookie-icon":{
    height:"69px",
    width:"83px",
    [theme.breakpoints.down("sm")]: {
      height:"49px",
      width:"63px",
    },
    [theme.breakpoints.down("xs")]: {
      height:"29px",
      width:"43px",
    },
  }
}));

// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderCommonModel = (
    isOpen: boolean,
    title: string,
    content: string,
    onClose: () => void
  ) => {
    return (
      <Modal
        open={isOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        data-test-id="modal"
      >
        <ModalContainer data-test-id="modal-container">
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom:"24px" }}>
            <ModalTitleContainer
              variant="h6"
              id="modal-title"
              data-test-id="modal-title-container"
              style={{height:"34px"}}
            >
              {title}
            </ModalTitleContainer>
            <IconButton
              onClick={onClose}
              aria-label="close"
              style={{ fontSize: "13px" }}
              className="close-icon"
            >
              <img src={CommonBlackClose} />
            </IconButton>
          </div>
          <ModalChildContainer
            id="modal-description"
            data-test-id="modal-child-container"
            className=""
          >
            <Typography
              className="termConditionModelDescription"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </ModalChildContainer>
        </ModalContainer>
      </Modal>
    );
  }

  renderModal = (
    isOpen: boolean,
    title: string,
    content: string,
    onClose: () => void
  ) => {
    return this.RenderCommonModel(isOpen, title, content, onClose);
  };

  renderModelForCookies = (
    isOpen: boolean,
    title: string,
    content: string,
    onClose: () => void
  ) => {
    return (
      <Modal
        open={isOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ModalContainer style={{padding:"36px 32px 60px 60px"}}>
          <Box className="cookie-title-container">
            <CookieModalTitleContainer>
              {title}
              <img src={cookieIcon} alt="cookie-icon" className="cookie-icon"/>
            </CookieModalTitleContainer>
            <IconButton
              onClick={onClose}
              aria-label="close"
              className="close-icon"
            >
              <img src={closeIcon}/>
            </IconButton>
          </Box>
          <ModalChildContainer id="modal-description" className="termConditionModelDescription" style={{paddingRight:"28px"}}>
            <Box className="model-description-container">
              <Typography className="cookie-text">
                We've baked some cookies for you! To enjoy our website fully,
                you'll need to accept them. Our site won't work properly without
                these essential cookies. Additionally, we use third-party
                cookies to gather information and make improvements.Can we?
              </Typography>
              <Typography className="cookie-text">
                We use our own and partner cookies to ensure website
                functionality, enhance your experience, and deliver personalized
                content and ads, helping us improve continuously.
              </Typography>
              {configJSON.cookiesDummyData.map(
                (item: { id: number; title: string; description: string }) => (
                  <Box className="cookie-type-container">
                    <Checkbox className="cookie-checkbox" color="secondary" />
                    <Box>
                      <Typography className="cookie-title-name">
                        {item.title}
                      </Typography>
                      <Typography className="cookie-info-text">
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                )
              )}
            </Box>
            <Box className="cookies-btn-container">
              <Button className="cookies-btn cookies-select-btn">
                Accept Selected
              </Button>
              <Button className="cookies-btn cookies-all-btn">
                Accept all
              </Button>
            </Box>
          </ModalChildContainer>
        </ModalContainer>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        {this.state.isLoading && <Typography>Loading</Typography>}
        {!this.state.isLoading &&
          this.renderModal(
            this.state.openTermCondition,
            configJSON.TermsConditionsTitle,
            this.state.termsConditionData,
            () => this.setState({ openTermCondition: false })
          )}

        {!this.state.isLoading &&
          this.renderModal(
            this.state.openPrivacyPolicy,
            configJSON.PrivacyPolicyTitle,
            this.state.privacyPolicyData,
            () =>
              this.setState({
                openPrivacyPolicy: false,
              })
          )}

        {!this.state.isLoading &&
          this.renderModelForCookies(
            this.state.openCookiesPolicy,
            "Have a Cookies!",
            this.state.cookiePolicyData,
            () =>
              this.setState({
                openCookiesPolicy: false,
              })
          )}

        <TermConditionContainer>
          By signing up, you are accepting our {" "}
          <span
            data-test-id="term-condition"
            className="title"
            onClick={() => {
              this.setState({
                openTermCondition: true,
                openCookiesPolicy: false,
                openPrivacyPolicy: false,
              });
            }}
          >
            Terms and Conditions, {" "}
          </span>
          <span
            className="title"
            onClick={() => {
              this.setState({
                openPrivacyPolicy: true,
                openTermCondition: false,
                openCookiesPolicy: false,
              });
            }}
            data-test-id="privacy-policy"
          >
            Privacy Policy
          </span>
          {" "} and {" "}
          <span
            className="title"
            data-test-id="cookies-policy"
            onClick={() => {
              this.setState({
                openCookiesPolicy: true,
                openPrivacyPolicy: false,
                openTermCondition: false,
              });
            }}
          >
            Cookies Policy{" "}
          </span>
          .
        </TermConditionContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const TermConditionContainer = styled(Typography)({
  fontSize: "12px",
  color: "#424242",
  fontWeight: 300,
  fontFamily: "Montserrat",
  "& .title": {
    fontWeight: "700",
  },
});

// Customizable Area End
