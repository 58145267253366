import React from 'react';
import { SearchItem, configJSON } from '../AdvancedSearchController';
import { Box, Button, Typography, styled } from '@material-ui/core';
import pluralize from 'pluralize';
import { MarkerSmall } from '../assets';
import { SearchData } from "../../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import { countNights } from '../../../../components/src/helpers';
import moment from 'moment';

type Props = {
    item: SearchItem;
    searchData?: SearchData;
    onSeeOffer: (item: SearchItem) => void;
    MapButton: React.ReactNode;
}

const ItemWrapper = styled(Box)(({theme}) => ({
    boxShadow: "0px 0px 9.5px -1px rgba(0, 0, 0, 0.25)",
    borderRadius: '25px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(1600)]: {
        flexDirection: 'row',
    },

    "& .image-container": {
        width: '100%',
        height: '250px',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        [theme.breakpoints.up(1600)]: {
            width: '270px',
            height: 'auto',
            maxWidth: '270px',
            minWidth: '270px', 
        },
    },
    "& .image": {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        minWidth: '100%',
        minHeight: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    "& .main-content": {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',

        [theme.breakpoints.up(1600)]: {
            padding: '40px',
        },
    },
    "& .title": {
        color: '#000',
        fontSize: '26px',
        fontWeight: 600
    },
    "& .content-line": {
        display: 'flex',
        gap: '12px',
        fontSize: '14px',
        fontWeight: 500,
        color: '#909090',
        alignItems: 'flex-start'
    },
    "& .link": {
        color: '#909090',
        fontSize: '12px',
        fontWeight: 700,
        textDecoration: 'underline',
    },
    "& .warning-text": {
        color: '#FFCE00',
        fontSize: '14px',
        fontWeight: 600
    },
    "& .price-block": {
        margin: '28px',
        paddingTop: '20px',
        paddingBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        [theme.breakpoints.up(1600)]: {
            borderLeft: '0.5px solid #B7B7B7',
            paddingLeft: '40px',
            marginLeft: 'auto',
        },
    },
    "& .offer-btn": {
        color: '#6F05EA',
        textTransform: 'none',
        backgroundColor: 'transparent',
        backround: 'transparent',
        padding: '10px 36px',
        borderRadius: '35px',
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 700,
        fontFamily: 'Montserrat',
        letterSpacing: 0,
        border: '1px solid #6F05EA',
        whiteSpace: 'nowrap',
    },
    "& .price-text": {
        color: '#777777',
        fontSize: '14px',
        fontWeight: 500,
        marginBottom: '21px'
    },
    "& .price-section": {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up(1600)]: {
            alignItems: 'end',
        },
    },
    "& .price-list": {
        display: 'flex',
        alignItems: 'center',
    },
    "& .no-tax-price": {
        color: '#777777',
        fontSize: '14px',
        fontWeight: 400,
        whiteSpace: 'nowrap',
    },

    "& .main-price": {
        color: '#6F05EA',
        fontSize: '24px',
        fontWeight: 700,
        marginLeft: '16px',
        whiteSpace: 'nowrap',
    },
    "& .fees-text": {
        marginTop: '8px',
        marginBottom: '20px',
        color: '#777777',
        fontSize: '12px',
        fontWeight: 500
    }

}))

const ListItem = ({ item, searchData, onSeeOffer,MapButton }: Props) => {
    const url = item.establishment_photo ? item.establishment_photo : configJSON.noImageUrl;
    const petCount = searchData && searchData.your_pet?.toLowerCase();

    const handleGetNights = () => {
        if(searchData) {
            const start = moment(searchData.start_date).format('YYYY-MM-DD');
            const end = moment(searchData.end_date).format('YYYY-MM-DD');
            return countNights(start, end)
        }

        return 0;
    }
    const renderInfoHedaer = () => {
        if(searchData) {
            switch (searchData.service_type) {
                case 'Hotels':
                    return `${handleGetNights()} nights, 1 ${petCount}`;
                case 'Dog Walking':
                    return 'Price per session';
                case 'Pet Sitting':
                    return 'Price per hour';
                case 'Day Care':
                    return 'Price per day';
                case 'Training':
                    return 'Price per session';
                default:
                    return '';
            }
        }
    }

    return (
        <ItemWrapper>
            <Box className='image-container' style={{ maxWidth: '100%', backgroundImage: `url(${url})` }} >
            </Box>
            <Box className='main-content'>
                <Typography className='title'>{item.establishment_name}</Typography>
                <Box className='content-line'>
                    <img src={MarkerSmall}/>
                    <Typography>{item.address}</Typography>
                </Box>
                <Box>
                    {MapButton}
                </Box>
            </Box>
            <Box className='price-block'>
                <Box className='price-section'>
                    <Typography className='price-text'>{renderInfoHedaer()}</Typography>
                    <Box className='price-list'>
                        <Typography className='no-tax-price'>from</Typography>
                        <Typography className='main-price'>€ {item.service_price}</Typography>
                    </Box>
                    <Typography className='fees-text'>Includes fees and taxes</Typography>
                </Box>
                <Button className='offer-btn' onClick={() => onSeeOffer(item)}>See offer</Button>
            </Box>
        </ItemWrapper>
    )
}

export default ListItem;