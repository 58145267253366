import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Checkbox,
  Tab,
  Tabs,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import {
  LoginImage,
  loginForwardArrow,
  checkedIcon,
  unCheckedIcon,
} from "./assets";
import InputField from "../../../components/src/InputField";
import * as Yup from "yup";
import { Formik } from "formik";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
const LoginPageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height:"100%",
  "@media(max-width:960)": {
    flexDirection: "column-reverse",
  },
  "& .Login-Info-Container": {
    width: "50%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.between(960, 1050)]: {
      padding: "0px 1rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "40px auto 20px auto",
    },
  },
  "& .Tab-Container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.between("md",1150)]: {
      width: "unset",
      margin: "auto",
    },
  },
  "& .Tab": {
    width: "227px",
    textTransform: "none",
    fontSize: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      maxWidth: "222px",
      fontSize: "24px",
    },
    [theme.breakpoints.down(550)]: {
      fontSize: "18px",
    },
  },
  "& .MuiInputBase-input":{
    fontWeight:600,
    fontSize:"16px",
  },
  "& .MuiTabs-root":{
    width:"500px",
    [theme.breakpoints.down(960)]: {
      width:"unset"
    },
  },
  "& .login-eye-icon":{
    color:"#A6A6A6"
  }
}));

const FormikContainer = styled(Box)({
  width: "100%",
  maxWidth: "500px",
  paddingTop: "40px",
});

const CheckBoxContainer = styled(Box)({
  height: "10px",
  display: "flex",
  alignItems: "center",
  marginBottom: "26px",
  "& .Remember-Password": {
    fontSize: "14px",
    color: "#808080",
    fontWeight: "300",
    fontFamily: "Montserrat",
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked:hover":{
    backgroundColor:"transparent"
  }
});

const ForgotPasswordContainer = styled(Box)({
  fontSize: "12px",
  color: "#A6A6A6",
  fontWeight: 400,
  marginTop: "20px",
  height: "38px",
  marginBottom: "26px",
  "& .Forgot-Password-here": {
    color: "#6F05EA",
    fontWeight: "700",
    cursor:"pointer"
  },
});

const LoginButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width:"100%",
  alignItems: "center",
  padding: "10px 0px",
  "& .Login-Btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "10px 16px",
    height: "53px",
    fontSize: "24px",
    gap: "10px",
    fontWeight: "700",
    textTransform: "none",
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
    "& .MuiButton-endIcon":{
      marginLeft:"36px",
      width:"36px"
    }
  },
});

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  height:"100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: "0",
  top: "0",
  "& .Image": {
    width: "100%",
    height:"100%"
  },
  [theme.breakpoints.down("sm")]: { display: "none" },
}));
const ImageTextContainer = styled(Box)({
  position: "absolute",
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%", maxWidth: "500px" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ width: "100%" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("*The provided email is incorrect")
    .required("*Please enter your email to proceed.")
    .test("email validation", "*The provided email is incorrect", (value) => {
      if (!value) return false;
      return /^[a-zA-Z0-9._+~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
    })
    .trim(),

  password: Yup.string()
    .required("*Please enter your password to proceed.")
    .test(
      "password-complexity",
      "*The entered password is incorrect",
      (value) => {
        if (!value) return false;
        return /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(
          value
        );
      }
    )
    .trim(),
});
const LoginTheme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat"
  },
});
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
  render() {
    return (
    // Customizable Area Start
    // Required for all blocks
      <ThemeProvider theme={LoginTheme}>
        <AppHeader navigation={this.props.navigation}/>
        <LoginPageWrapper>
          <Box className="Login-Info-Container">
            <Box className="Tab-Container">
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleTabChange}
                textColor="inherit"
                aria-label="secondary tabs example"
                style={{
                  font: "Montserrat",
                  textTransform: "none",
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#6F05EA",
                    height: "4px",
                  },
                }}
                data-test-id="tabs"
              >
                <Tab
                  label="I'm a user"
                  style={
                    {
                      ...this.renderTabStyle(0),
                    } as React.CSSProperties
                  }
                  className="Tab"
                  {...a11yProps(0)}
                />
                <Tab
                  label="I'm a vendor"
                  style={
                    {
                      ...this.renderTabStyle(1),
                    } as React.CSSProperties
                  }
                  className="Tab"
                  {...a11yProps(1)}
                />
              </Tabs>
              <TabPanel value={this.state.tabValue} index={0}>
                <FormikContainer>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      email: this.state.email,
                      password: this.state.password,
                    }}
                    validationSchema={loginSchema}
                    onSubmit={(values) => {
                      this.setEmail(values.email);
                      this.setPassword(values.password);
                      this.loginApi(values.email, values.password);
                    }}
                    data-test-id="Formik"
                  >
                    {({ errors, touched, handleSubmit, getFieldProps }) => {
                      return (
                        <>
                          <form onSubmit={handleSubmit}>
                            <InputField
                              {...getFieldProps("email")}
                              fullWidth
                              id="email"
                              style={{ marginBottom: "40px" }}
                              placeholder="Your Email"
                              label="Email"
                              type="text"
                              error={Boolean(errors.email) && touched.email}
                              startAdornment={this.renderEmailStartAdornment()}
                              helperText={(
                                touched.email && errors.email
                              )?.toString()}
                              data-test-id="Input-Email"
                            />

                            <InputField
                              {...getFieldProps("password")}
                              style={{ marginBottom: "26px" }}
                              type={
                                this.state.enablePasswordField
                                  ? "password"
                                  : "text"
                              }
                              placeholder={"Password"}
                              fullWidth={true}
                              label="Password"
                              startAdornment={this.renderPasswordStartAdornment()}
                              endAdornment={this.renderEndAdornment()}
                              error={
                                Boolean(errors.password) && touched.password
                              }
                              helperText={(
                                touched.password && errors.password
                              )?.toString()}
                              data-test-id="Login-Password"
                            />

                            <CheckBoxContainer>
                              <Checkbox
                                disableRipple
                                icon={
                                  <img src={unCheckedIcon} alt="unchecked" />
                                }
                                checkedIcon={
                                  <img
                                    style={{ width: "10px" }}
                                    src={checkedIcon}
                                    alt="checkedIcon"
                                  />
                                }
                                data-test-id="Btn-RememberPassword"
                                onClick={() =>
                                  this.setRememberMe(
                                    !this.state.checkedRememberMe
                                  )
                                }
                                checked={this.state.checkedRememberMe}
                                style={{ fontSize: "10px", color: "#6F05EA", padding:"9px 9px 9px 0px" }}
                              />
                              <Typography className="Remember-Password">
                                Remember my password
                              </Typography>
                            </CheckBoxContainer>
                            <ForgotPasswordContainer
                              data-test-id={"Btn-ForgotPassword"}
                            >
                              Forgot your password? Click{" "}
                              <span data-test-id="forgot-password-link" onClick={() => this.goToForgotPasswordPage()} className="Forgot-Password-here">here</span>
                              .
                            </ForgotPasswordContainer>
                            <LoginButtonContainer>
                              <Typography style={{ color: "#ef4444" }}>
                                {this.state.loginApiError}
                              </Typography>
                              <Button
                                data-test-id={"Btn-EmailLogIn"}
                                variant="contained"
                                fullWidth
                                type="submit"
                                className="Login-Btn"
                                endIcon={
                                  <img
                                    src={loginForwardArrow}
                                    alt="forward-arrow"
                                  />
                                }
                              >
                                Login
                              </Button>
                            </LoginButtonContainer>
                          </form>
                        </>
                      );
                    }}
                  </Formik>
                </FormikContainer>
              </TabPanel>

              <TabPanel value={this.state.tabValue} index={1}>
                <FormikContainer>
                  <Formik
                    initialValues={{
                      password: this.state.password,
                      email: this.state.email,
                    }}
                    validationSchema={loginSchema}
                    enableReinitialize
                    data-test-id="Formik"
                    onSubmit={(values) => {
                      this.setPassword(values.password);
                      this.setEmail(values.email);
                      this.loginApi(values.email, values.password);
                    }}
                  >
                    {({  touched,errors,getFieldProps, handleSubmit,  }) => {
                      return (
                        <>
                          <form onSubmit={handleSubmit}>
                            <InputField
                              {...getFieldProps("email")}
                              id="email"
                              style={{ marginBottom: "40px" }}
                              label="Email"
                              type="text"
                              placeholder="Your Email"
                              fullWidth
                              error={Boolean(errors.email) && touched.email}
                              data-test-id="Input-Email"
                              startAdornment={this.renderEmailStartAdornment()}
                              helperText={(
                                touched.email && errors.email
                              )?.toString()}
                            />

                            <InputField
                              {...getFieldProps("password")}
                              style={{ marginBottom: "26px" }}
                              fullWidth={true}
                              label="Password"
                              placeholder={"Password"}
                              type={
                                this.state.enablePasswordField
                                  ? "password"
                                  : "text"
                              }
                              startAdornment={this.renderPasswordStartAdornment()}
                              data-test-id="Login-Password"
                              helperText={(
                                touched.password && errors.password
                              )?.toString()}
                              error={
                                Boolean(errors.password) && touched.password
                              }
                              endAdornment={this.renderEndAdornment()}
                            />

                            <CheckBoxContainer>
                              <Checkbox
                                icon={
                                  <img src={unCheckedIcon} alt="unchecked" />
                                }
                                disableRipple
                                data-test-id="Btn-RememberPassword"
                                onClick={() =>
                                  this.setRememberMe(
                                    !this.state.checkedRememberMe
                                  )
                                }
                                checkedIcon={
                                  <img
                                    style={{ width: "10px" }}
                                    src={checkedIcon}
                                    alt="checkedIcon"
                                  />
                                }
                                style={{ fontSize: "10px", color: "#6F05EA", padding:"9px 9px 9px 0px" }}
                                checked={this.state.checkedRememberMe}
                              />
                              <Typography className="Remember-Password">
                                Remember my password
                              </Typography>
                            </CheckBoxContainer>
                            <ForgotPasswordContainer
                              data-test-id={"Btn-ForgotPassword"}
                            >
                              Forgot your password? Click{" "}
                              <span data-test-id="forgot-password-span" onClick={() => this.goToForgotPassword()} className="Forgot-Password-here">here</span>
                              .
                            </ForgotPasswordContainer>
                            <LoginButtonContainer>
                              <Typography style={{ color: "#ef4444" }}>
                                {this.state.loginApiError}
                              </Typography>
                              <Button
                                data-test-id={"Btn-EmailLogIn"}
                                className="Login-Btn"
                                fullWidth
                                type="submit"
                                variant="contained"
                                endIcon={
                                  <img
                                    alt="forward-arrow"
                                    src={loginForwardArrow}
                                  />
                                }
                              >
                                Login
                              </Button>
                            </LoginButtonContainer>
                          </form>
                        </>
                      );
                    }}
                  </Formik>
                </FormikContainer>
              </TabPanel>
            </Box>
          </Box>
          <ImageContainer>
            <img src={LoginImage} className="Image" />
            <ImageTextContainer data-test-id="Img-Container" >
              <Typography className="WelCome-Text" style={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "white",
                  fontFamily: "Montserrat",
                }}>Welcome back!</Typography>
              <Typography className="Not-Account-Text" style={{ fontSize: "20px", marginTop: "40px", color: "white" }}>
                You don't have an account yet?{" "}
              </Typography>
              <Box className="Register-Here" 
                data-test-id="Register-Link"
                style={{
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                }} onClick={() => {this.goToRegisterPage()}} >Register <span style={{fontWeight:"700", cursor:"pointer"}}>here</span>.</Box>
            </ImageTextContainer>
          </ImageContainer>
        </LoginPageWrapper>
        <AppFooter navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
