import { Box, LinearProgress, Typography, styled } from "@material-ui/core";
import moment from "moment";
import React, { useCallback } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import DayCellVendor from "../../../../components/src/calendarWeb/DayCellVendor";
import MonthEventVendor from "../../../../components/src/calendarWeb/MonthEventVendor";
import VendorWeek from "../../../../components/src/calendarWeb/VendorWeek";
import WeekEventService from "../../../../components/src/calendarWeb/WeekEventService";
import WeekEventVendor from "../../../../components/src/calendarWeb/WeekEventVendor";
import {
  createCalendarEventsData,
  getWeekRange,
} from "../../../../components/src/helpers";
import useBreakpoints from "../../../../components/src/hooks/useBreakpoints";
import { IFilteredService } from "../../../../components/src/interfaces.web";
import { leftArrow, rightArrow } from "../assets";
import { Appointment, CalendarItem, Keys, VendorEvent } from "../types";
import VendorCalendarSelect from "./VendorCalendarSelect";

const ScrollbarStyles = `
  .rbc-time-content::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
  }

  .rbc-time-content::-webkit-scrollbar-track {
    background: #CBD5E1;
    border-radius: 6px;
  }

  .rbc-time-content::-webkit-scrollbar-thumb {
    background: #6F05EA;
    border-radius: 4px; 
    width: 6px; 
  }

  .rbc-time-content::-webkit-scrollbar-thumb:hover {
    background: #6F05EA;
  }
`;

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

const localizer = momentLocalizer(moment);

interface IProps {
  calendarView: (typeof Views)[Keys];
  selectedService: IFilteredService;
  changeDate: (date: Date) => void;
  changeView: (view: (typeof Views)[Keys]) => void;
  calendarDate: Date;
  formattedEvents: VendorEvent[];
  appointments: Appointment[];
  isLoading: boolean;
}

const StyledLinearProgress = styled(LinearProgress)({
  "&&": {
    backgroundColor: '#F0E5FF'
  },
  "& .MuiLinearProgress-bar": {
    backgroundColor: '#6F05EA'
  }
})

const ContentWrapper = styled(Box)(({ theme }) => ({
  background: "#fff",
  padding: "29px 26px",
  fontFamily: "Montserrat",

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: "Montserrat",
  },
  "& .header-wrapper": {
    display: "flex",
    paddingBottom: "26px",
    flexDirection: "column",
    gap: "8px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      gap: "0px",
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      alignItems: "center",
    },
  },
  "& .header-select-wrapper": {
    display: "flex",
    gap: "20px",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-end",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  "& .rbc-month-header .rbc-header": {
    color: '#6F05EA',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600
  },
  "& .week-name": {
    color: "#000",
    fontSize: "20px",
    fontWeight: 700,
  },
  "& .arrow-style": {
    userSelect: "none",
    cursor: "pointer",
    width: "7px",
    height: "auto",
  },
  "& .view-switch": {
    background: "#F5F5F4",
    borderRadius: "50px",
    padding: "4px",
    display: "flex",
    gap: "6px",
    alignItems: "center",
    color: "#64748B",
    fontSize: "12px",
    fontWeight: 500,
  },
  "& .view-item": {
    padding: "6px 12px",
  },
  "& .view-item-active": {
    padding: "6px 12px",
    background: "#F0E5FF",
    borderRadius: "50px",
    fontSize: "14px",
    fontWeight: 700,
    border: "1px solid #E1CCFF",
    color: "#6200EA",
  },

  "& .date-change-wrapper": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "14px",

    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },

    "@media (min-width: 1000px)": {
      flexDirection: "row",
      alignItems: "center",
    },
  },

  "& .date-change-arrows": {
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },

  "& .rbc-today": {
    backgroundColor: "transparent",
  },
  "& .rbc-header": {
    color: "#0F172A",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    borderLeft: "1px solid transparent",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  "& .rbc-row.rbc-time-header-cell > div:nth-last-child(1), & .rbc-row.rbc-time-header-cell > div:nth-last-child(2)":
    {
      color: "#A8A29E",
    },
  "& .rbc-header.rbc-today": {
    "& .rbc-button-link": {
      color: "#6F05EA",
      fontSize: "16px!important",
      fontWeight: 500,
    },
  },
  "& .rbc-row.rbc-time-header-cell": {
    marginBottom: "5px",
  },
  "& .rbc-time-header.rbc-overflowing": {
    borderRight: "1px solid transparent",
  },
  "& .rbc-event-label": {
    display: "none",
  },
  "& .rbc-time-slot": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flex: 0,
    fontWeight: 600,
    fontSize: "14px",
  },
  "& .rbc-time-header-content": {
    border: "none",
  },
  "& .rbc-month-header": {
    paddingTop: "12px",
    borderTop: "1px solid #B7B7B7",
  },
  "& .rbc-month-view": {
    border: 0,
  },
  "& .rbc-month-row": {
    maxHeight: "70px",
    borderTop: "none",
  },
  "& .rbc-date-cell": {
    display: "none",
  },
  "& .rbc-show-more": {
    display: "none",
  },
  "& .header-month": {
    display: 'flex',
    alignItems: 'center',
    gap: '28px'
  },
  "& .header-year-month": {
    display: 'flex',
    gap: '8px'
  },
  "& .month-name": {
    color: '#6F05EA',
    fontSize: '36px',
    fontWeight: 600
  },
  "& .year-name": {
    color: '#000000',
    fontSize: '36px',
    fontWeight: 600,
    borderBottom: '1px solid #000',
    padding: '0 12px'
  },
}));

const TimeLineWrapper = styled("div")(({ theme }) => ({
  "& .rbc-current-time-indicator ": {
    backgroundColor: "#6F05EA",
  },
  "& .rbc-day-bg": {
    borderLeft: "1px solid transparent",
  },
  "& .rbc-events-container": {
    width: "100% !important",
    borderRight: "1px solid #E2E8F0",
  },
  "& .rbc-time-content": {
    paddingTop: "7px",
  },
  "& .rbc-time-gutter": {
    marginTop: "-7px",
  },
  "& .rbc-day-slot": {
    "& .rbc-time-slot": {
      borderTop: "1px solid #E2E8F0",
    },
  },
  "& .rbc-event, .rdc-background-event": {
    padding: "0px !important",
    border: "none !important",
    backgroundColor: "transparent",
  },
  "& .rbc-timeslot-group": {
    fontSize: "14px",
    borderLeft: "none !important",
  },
  "& .rbc-time-slot": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flex: 0,
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },
  "& .rbc-allday-cell": {
    height: "auto",
    "& .rbc-row": {
      minHeight: 0,
    },
    "& .rbc-event": {
      "& .week-event-wrapper": {
        background: "#8833FF",
        padding: '3px 16px',
        "& .event-title": {
          color: "#fff",
        }
      },
      "& .top-time": {
        display: 'none'
      }
    },
  },
}));
const ServicesWrapper = styled("div")({
  "& .rbc-current-time-indicator ": {
    display: "none",
  },
  "& .rbc-header": {
    "& .rbc-button-link": {
      fontSize: "16px!important",
      fontWeight: 500,
    },
  },
  "& .rbc-allday-cell": {
    display: "none",
  },
  "& .rbc-timeslot-group": {
    minHeight: "100px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    borderLeft: "none !important",
  },
  "& .rbc-events-container": {
    width: "100% !important",
    borderRight: "1px solid #E2E8F0",
  },
  "& .rbc-day-slot": {
    borderTop: "1px solid #E2E8F0",
  },
  "& .rbc-time-content > div:nth-last-child(1) > .rbc-events-container": {
    borderRight: "1px solid transparent",
  },
  "& .rbc-time-slot": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flex: 0,
    border: "none",
  },
  "& .rbc-time-header-gutter": {
    width: "100px",
  },
  "& .rbc-event": {
    padding: "0px !important",
    border: "none !important",
    backgroundColor: "transparent",
  },
  "& .rbc-event-content": {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    justifyContent: "center"
  },
});

const VendorCalendar = ({
  calendarDate,
  changeDate,
  selectedService,
  changeView,
  calendarView,
  formattedEvents,
  appointments,
  isLoading
}: IProps) => {
  const { isTabletAndLower } = useBreakpoints();
  const isTimeLineServices = ["Dog Walking", "Grooming"];

  const onPrevClick = useCallback(() => {
    if (calendarView === Views.WEEK) {
      changeDate(moment(calendarDate).subtract(1, "w").toDate());
    } else {
      changeDate(moment(calendarDate).subtract(1, "M").toDate());
    }
  }, [calendarView, calendarDate]);

  const onNextClick = useCallback(() => {
    if (calendarView === Views.WEEK) {
      changeDate(moment(calendarDate).add(1, "w").toDate());
    } else {
      changeDate(moment(calendarDate).add(1, "M").toDate());
    }
  }, [calendarView, calendarDate]);

  const renderTimelineCalendar = () => {
    const eventsData = getEventsData(formattedEvents);
    const events = createCalendarEventsData(eventsData);

    const formatted = appointments.map(event => ({
      ...event,
      start: moment(event.start_date).toDate(),
      end: moment(event.end_date).toDate(),
    }))

    return (
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        defaultDate={calendarDate}
        date={calendarDate}
        events={formatted}
        toolbar={false}
        view={calendarView}
        onView={() => undefined}
        onNavigate={() => undefined}
        views={["week", "month"]}
        defaultView="week"
        style={{ height: calendarView === Views.WEEK ? "480px" : "460px" }}
        components={{
          event: calendarView === Views.WEEK ? WeekEventVendor : MonthEventVendor,
          dateCellWrapper: (props: any) => (
            <DayCellVendor
              events={events}
              calendarDate={calendarDate}
              view={calendarView}
              {...props}
            />
          )
        }}
        formats={{
          dayFormat: "ddd DD",
          timeGutterFormat: "HH:mm",
        }}
        scrollToTime={new Date(2024, 7, 3, 7, 0, 0)}
      />
    );
  };

  const getEventsData = (events: VendorEvent[]) => {
    const rows: Record<string, CalendarItem[]> = {};
    const names: Record<string, string> = {};

    events.forEach((event) => {
      rows[event.sub_service_id] = event.dates;
      names[event.sub_service_id] = event.sub_service_name;
    });

    return { services: rows, count: Object.keys(rows).length, names };
  };

  const getCalendarHeight = (count: number) => {
    if(count === 0) {
      return '30px'
    } else if(count > 0) {
      return `${count * 100 + 30}px`
    } 
  }

  const renderCalendar = () => {
    const eventsData = getEventsData(formattedEvents);
    const events = createCalendarEventsData(eventsData);
    const calendarHeight = getCalendarHeight(eventsData.count);

    return (
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        defaultDate={calendarDate}
        date={calendarDate}
        events={events}
        toolbar={false}
        view={calendarView}
        onView={() => undefined}
        onNavigate={() => undefined}
        views={["week", "month"]}
        defaultView="week"
        style={{
          height:
            calendarView === Views.WEEK
              ? calendarHeight
              : "460px",
        }}
        components={{
          timeGutterWrapper: (props: any) => (
            <VendorWeek
              {...props}
              services={eventsData.services}
              names={eventsData.names}
            />
          ),
          event:
            calendarView === Views.WEEK ? WeekEventService : MonthEventVendor,
          dateCellWrapper: (props: any) => (
            <DayCellVendor
              events={events}
              calendarDate={calendarDate}
              view={calendarView}
              {...props}
            />
          ),
        }}
        formats={{
          dayFormat: isTabletAndLower ? "DD" : "ddd DD",
        }}
        min={new Date(2024, 7, 3, 0, 0, 0)}
        max={new Date(2024, 7, 3, eventsData.count, 0, 0)}
      />
    );
  };

  const renderWeekRange = () => {
    return (
      <Box className="date-change-wrapper">
        <Typography className="week-name">
          {getWeekRange(calendarDate)}
        </Typography>
        <Box className="date-change-arrows">
          <Box data-test-id="prev-arrow" onClick={onPrevClick}>
            <img src={leftArrow} className="arrow-style" />
          </Box>
          <Box data-test-id="next-arrow" onClick={onNextClick}>
            <img src={rightArrow} className="arrow-style" />
          </Box>
        </Box>
      </Box>
    )
  }
  const renderMonthRange = () => {
    return (
      <Box className="header-month">
        <Box data-test-id="prev-arrow" onClick={onPrevClick}>
            <img src={leftArrow} className='arrow-style'/>
        </Box>
        <Box className='header-year-month'>
          <Typography className='month-name'>{moment(calendarDate).format("MMMM")}</Typography>
          <Typography className='year-name'>{moment(calendarDate).year()}</Typography>
        </Box>
        <Box data-test-id="next-arrow" onClick={onNextClick}>
            <img src={rightArrow} className='arrow-style'/>
        </Box>
      </Box>
    )
  }

  const renderHeader = (isTimeLine: boolean) => {
    return (
      <Box className="header-wrapper" style={{ paddingBottom:  calendarView === Views.WEEK ? '26px' : '16px'}}>
        {calendarView === Views.WEEK ? renderWeekRange() : renderMonthRange()}
        <Box className="header-select-wrapper">
          {isTimeLine && calendarView === Views.WORK_WEEK && (
            <Box>
              <VendorCalendarSelect />
            </Box>
          )}
          <Box className="view-switch">
            <Box
              data-test-id="switch-week"
              onClick={() => changeView(Views.WEEK)}
              className={
                calendarView === Views.WEEK ? "view-item-active" : "view-item"
              }
            >
              Week
            </Box>
            <Box
              data-test-id="switch-month"
              onClick={() => changeView(Views.MONTH)}
              className={
                calendarView === Views.MONTH ? "view-item-active" : "view-item"
              }
            >
              Month
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderContent = () => {
    const isTimeline = isTimeLineServices.includes(selectedService.name);

    if (isTimeline) {
      return (
        <TimeLineWrapper>
          {renderHeader(true)}
          {renderTimelineCalendar()}
        </TimeLineWrapper>
      );
    } else {
      return (
        <ServicesWrapper>
          {renderHeader(false)}
          {renderCalendar()}
        </ServicesWrapper>
      );
    }
  };

  const renderLoader = () => {
    if(isLoading) {
      return <StyledLinearProgress />
    }
  }

  return (
    <ContentWrapper>
      <style>{ScrollbarStyles}</style>
      {renderContent()}
      {renderLoader()}
    </ContentWrapper>
  );
};

export default VendorCalendar;