import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Price } from "../../../../components/src/interfaces.web";

// Customizable Area Start
interface ITableData {
  id: number,
  title: string,
  capacity: number,
  allows_pet: string,
  description: string,
  service_id: number,
  duration: string | null,
  shift: string | null,
  created_at: string,
  updated_at: string,
  prices: Price[]
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  serviceType: string;
  data: ITableData[];
  selectedSubService: null | Price,
  setSubService: (subServicePrice: Price | null) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedIds: number,
  recurrenceStates:  {[key: number]: string}; 
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ServiceBookingTableWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      selectedIds: 0,
      recurrenceStates: {}, 
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getServiceTypeHeaderName = () => {
    switch (this.props.serviceType) {
      case configJSON.SERVICES_ENUM.HOTELS:
        return "Room Type"
      case configJSON.SERVICES_ENUM.DAY_CARE:
        return "Space Type" 
      default:
        return "Service Type"
    }
  };

  getButtonName = (id:number) => {
    const { selectedIds } = this.state;
    const isSelectedService = selectedIds === id;
    switch (this.props.serviceType) {
      case configJSON.SERVICES_ENUM.HOTELS:
        return isSelectedService ? "Selected Room" : "Select Room";
      case configJSON.SERVICES_ENUM.GROOMING:
        return isSelectedService ? "Selected" : "Select";
      default:
        return isSelectedService ? "Selected" : "Select";
    }
  };
  

  handleSelectClick = (id: number, SelectedPriceRecord: Price | undefined) => {
    const selectedState = this.state.recurrenceStates[id];
    this.setState({selectedIds:id,recurrenceStates: {
      [id]: selectedState, 
    }});
    SelectedPriceRecord && this.props.setSubService(SelectedPriceRecord)
  };

  handleRecurrenceChange = (value:string,subServiceId:number) => {
    this.setState((prevState) => ({
      selectedIds: 0,
      recurrenceStates: {
        ...prevState.recurrenceStates,
        [subServiceId]: value,
      },
    }));
    this.props.setSubService(null)
    this.getButtonClass(subServiceId);
    this.getButtonName(subServiceId);
  };

  getButtonClass = (id:number) => {
    const { recurrenceStates, selectedIds } = this.state;
    const { serviceType } = this.props;
    const recurrenceServiceList = [configJSON.SERVICES_ENUM.DAY_CARE, configJSON.SERVICES_ENUM.DOG_WALKING];
    const isRecurrenceShow = recurrenceServiceList.includes(serviceType);
    const isRecurrenceSelected = recurrenceStates[id] && recurrenceStates[id] !== '';
  
    let additionalClass = '';
  
    switch (true) {
      case selectedIds === id:
        additionalClass = 'select-btn'; 
        break;

      case !isRecurrenceShow:
        additionalClass = 'purple-btn'; 
        break;
  
      case isRecurrenceShow && isRecurrenceSelected:
        additionalClass = 'purple-btn'; 
        break;
  
      case isRecurrenceShow && !isRecurrenceSelected:
        additionalClass = 'notSelectBtn'; 
        break;
  
      default:
        additionalClass = 'notSelectBtn'; 
    }
  
    return `table-btn ${additionalClass}`;
  };
  // Customizable Area End
}