import React from "react";
import {
  InputAdornment,
  InputBaseComponentProps,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

interface Props {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  onClick?: (e: React.ChangeEvent<any>) => void;
  value?: unknown;
  type?: "number" | "text" | "email" | "password";
  placeholder?: string;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  label?: string;
  inputProps?: InputBaseComponentProps;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  multiline?: boolean;
  minRows?: string | number;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  asterisk?: boolean;
  labelStyle?: GenericStyle | React.CSSProperties;
  name?: string;
  id?: string;
  variant?: "filled" | "outlined" | "standard" ;
  labelClass?:string;
}

interface GenericStyle {
  [key: string]: string | number;
}

const StyledInputField = styled("div")({
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiInput-underline::after": {
    borderBottom: "2px solid #6F05EA",
  },
  "& .MuiInput-underline.Mui-error::after": {
    borderBottom: "2px solid #EF4444",
  },
  "& .MuiInput-underline::after:hover": {
    borderBottom: "2px solid #6F05EA",
  },
  "& .MuiInput-root.Mui-focused .MuiInputAdornment-positionStart": {
    filter: "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)"
  },
  "& .MuiInputAdornment-positionStart":{
    marginLeft:"8px",
    marginRight:"13px !important"
  },
  "& .purple-icon img":{
    filter: "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)"
},
});

const InputField = (props: Props) => {
  const {
    onChange,
    onBlur,
    onClick,
    value,
    type,
    placeholder,
    disabled,
    error,
    helperText,
    multiline,
    minRows,
    label,
    inputProps,
    endAdornment,
    startAdornment,
    style,
    fullWidth = false,
    asterisk = false,
    labelStyle,
    name,
    id,
    variant,
    labelClass,
  } = props;
  return (
    <>
      {label && (
        <InputLabel
          style={{
            color: "#334155",
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 600,
            marginBottom:"8px",
            ...labelStyle,
          }}
          className={labelClass}
        >
          {label} {asterisk && <span style={{ color: "red" }}>*</span>}{" "}
        </InputLabel>
      )}
      <StyledInputField>
      <TextField
        name={name}
        id={id}
        fullWidth={fullWidth}
        inputProps={inputProps}
        autoComplete="off"
        value={value}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        placeholder={placeholder}
        disabled={disabled}
        helperText={helperText}
        variant={variant}
        error={error}
        multiline={multiline}
        minRows={minRows}
        style={style}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={value ? "purple-icon" : ""}>{startAdornment}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
      />
      </StyledInputField>
    </>
  );
};

export default InputField;
