import React, { useCallback } from 'react';
import { Appointment } from '../types';
import moment from 'moment';
import { Box, Grid } from '@material-ui/core';
import { DayCareBlue, DogWalkingIcon, GroomingIcon, HotelsIcon, PetSittingIcon, TrainingIcon, appointmentCardTitleIcon } from '../assets';

type Props = {
    currentTab: number;
    appointments: Appointment[];
}

type IconType = 'Grooming' | 'Dog Walking' | 'Hotels' | 'Training' | 'Day Care' | 'Pet Sitting';

const iconMap: Record<IconType, string> = {
    'Grooming': GroomingIcon,
    'Dog Walking': DogWalkingIcon,
    'Hotels': HotelsIcon,
    'Training': TrainingIcon,
    'Day Care': DayCareBlue,
    'Pet Sitting': PetSittingIcon
}

const VendorAppointmentList = ({ appointments, currentTab }: Props) => {

    const appointmentsList = useCallback(() => {
        const now = moment();
        const past = appointments.filter(appointment => moment(appointment.end_date, 'YYYY-MM-DD').isBefore(now.startOf('day')));
        const future = appointments.filter(appointment => moment(appointment.end_date, 'YYYY-MM-DD').isSameOrAfter(now.startOf('day')));

        return { past, future }
    }, [appointments])

    const renderList = (list: Appointment[]) => {
        return (
            <Box>
                {list.map((item) => (
                    <Grid container className="card-container" key={item.id}>
                        <Grid item xs={12} className="card-title-container">
                            <Box className="card-title">{moment(item.start_date, 'YYYY-MM-DD').format('ddd, D MMMM')}</Box>
                            <Box >
                                <img className="card-title-img" src={appointmentCardTitleIcon} />
                            </Box>
                        </Grid>
                    <Grid data-test-d='selectList' item xs={12}>
                      <Grid container className="card-body-container">
                        <Grid
                          item
                          lg={2}
                          md={3}
                          sm={4}
                          xs={4}
                          className="card-body-img-container"
                        >
                          <img
                            className="card-body-main-img"
                            src={item.pet.data.attributes.pet_photo}
                            alt="grooming-icon"
                          />
                          <img
                            className="card-body-icon-img"
                            src={iconMap[item.service.service_type as IconType]}
                            alt="grooming-icon"
                          />
                        </Grid>
                        <Grid item lg={10} md={9} sm={8} xs={8}>
                          <Box className="card-body-info">
                            <Box className="card-body-name">{item.pet.data.attributes.name}</Box>
                            <Box className="card-body-service-name">
                              {item.service.service_type}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Box>
        )
    }


    return (
        <Box>
            {currentTab === 0 && renderList(appointmentsList().future)}
            {currentTab === 1 && renderList(appointmentsList().past)}
        </Box>
    )

}

export default VendorAppointmentList;