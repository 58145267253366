import React from "react";
// Customizable Area Start
import {
  Avatar, Box, Button, Grid, Typography, Divider, Checkbox, MenuItem,
  CircularProgress, FormHelperText
} from "@material-ui/core";
import Select, { SelectProps } from '@material-ui/core/Select';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import BookingController, { Props, webConfigJSON } from "./BookingController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { LandingPageHeader } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import {
  AddressLocation, TransportationIcon, MedicationIcon, checkedIcon, unCheckedIcon, warningIcon, CommoditiesIcon,
  petPawIcon, RightBtnArrowIcon, DateCalendar, checkRepeat, unCheckRepeat,
  capacityCalendar
} from "./assets";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import BookingWebMap from "../../../components/src/landingpage/BookingWebMap";
import DatePicker from "react-datepicker";
import { BookingButton, CancellationPolicy, CommoditiesList, DialogWithImage, RepeatButton, RepeatDayButton, SimpleDialog } from "../../../components/src/HelperComponents";
import { capitalizeFirstLetter, checkCondition, nextDayDate, returnTruthyString } from "../../../components/src/HelperUtils";
import ServiceBookingTableWeb from "./component/ServiceBookingTable";
import { Price } from "../../../components/src/interfaces.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6928F2",
      contrastText: "#fff",
    }
  },
  typography: {
    fontFamily: "Montserrat",
  },
});
const selectMenuProps: Partial<SelectProps['MenuProps']> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const Adornment = (imgSrc: string) => (
  <img src={imgSrc} style={{ height: "24px", width: "24px" }} />
);
// Customizable Area End

export default class Booking extends BookingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderServiceHeader = () => {
    const establishmentData = this.state.getDetailsData?.data?.attributes?.establishment;
    const establishmentTitle = returnTruthyString(capitalizeFirstLetter(establishmentData?.establishment_name));
    const establishmentAddress = returnTruthyString(establishmentData?.address);
    return (
      <>
        <ServiceTitle>{establishmentTitle}</ServiceTitle>
        <AddressBox>
          <img src={AddressLocation} style={style.locationImg} />
          <Typography style={style.addressFont}>
            {establishmentAddress}
          </Typography>
        </AddressBox>
        <Box></Box>
        {this.renderServiceGallery()}
        {this.renderServiceAbout()}
      </>
    )
  }
  renderServiceGallery = () => {
    let img1, img2, img3, img4, img5;
    const galleryImageList = this.state.getDetailsData?.data?.attributes?.service_galleries_urls;
    if (Array.isArray(galleryImageList) && galleryImageList.length > 0) {
      img1 = <img src={galleryImageList[0]?.image_url} style={style.subImg} />
      img2 = <img src={galleryImageList[1]?.image_url} style={style.subImg} />
      img3 = <img src={galleryImageList[2]?.image_url} style={style.subImg} />
      img4 = <img src={galleryImageList[3]?.image_url} style={style.subImg} />
      img5 = <img src={galleryImageList[4]?.image_url} style={style.subImg} />
    }

    return (
      <>
        <GalleryWrapper>
          <GalleryGrid>
            <LeftImageBox>{img1}</LeftImageBox>
            <RightImageBox>
              <ImageBoxWrapper>
                <ImageBox >{img2}</ImageBox>
                <ImageBox >{img3}</ImageBox>
              </ImageBoxWrapper>
              <ImageBoxWrapper>
                <ImageBox >{img4}</ImageBox>
                <ImageBox >{img5}</ImageBox>
              </ImageBoxWrapper>
            </RightImageBox>
          </GalleryGrid>
        </GalleryWrapper>
      </>
    )
  }

  renderServiceAbout = () => {
    const establishmentInfo = this.state.getDetailsData?.data?.attributes?.establishment;
    const about = this.state.getDetailsData?.data?.attributes?.about;
    const establishmentName = returnTruthyString(capitalizeFirstLetter(establishmentInfo?.establishment_name));
    const establishmentAbout = returnTruthyString(about);
    const establishmentURL = this.state.getDetailsData?.data?.attributes?.establishment_image;

    return (
      <>
        <AboutWrapper>
          <Box>
            <EstablishmentBox>
              <EstablishmentAvatar src={establishmentURL}>{establishmentName?.charAt(0)}</EstablishmentAvatar>
              <Typography style={style.establishmentFont}>
                {establishmentName}
              </Typography>
            </EstablishmentBox>
          </Box>
          <AboutBox>
            <SubHeadingFont>About</SubHeadingFont>
            {about ? (<AboutSpan>{establishmentAbout}</AboutSpan>) : (<NoDataSpan>No Data Found</NoDataSpan>)}
          </AboutBox>
        </AboutWrapper>
      </>
    )
  }

  renderSubServicePart = () => {
    const isCommodityShow = [webConfigJSON.SERVICES_ENUM.DAY_CARE, webConfigJSON.SERVICES_ENUM.HOTELS].includes(this.state.getDetailsData?.data?.attributes?.service_type);
    return (
      <>
        <Box style={{ marginTop: "20px" }}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {this.renderSubService()}
              {isCommodityShow && this.renderCommodities()}
              {this.renderComplementary()}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {this.state.isDetailsLoading ? this.renderLoader() : this.renderBookingDates()}
              {this.renderCancellationPolicy()}
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }

  renderSubService = () => {
    const serviceType = this.serviceName()
    const tableData = this.state.getDetailsData?.data?.attributes?.sub_services_with_prices;
    return (
      <>
        <CustomHR style={{ marginBottom: 30 }} />
        {!tableData && !serviceType && this.state.isDetailsLoading ?
          this.renderLoader()
          : <></>
        }
        {tableData && tableData.length > 0 && serviceType && !this.state.isDetailsLoading ? (
          <ServiceBookingTableWeb navigation={undefined} id={""}
            data-testid="service-price-table"
            serviceType={serviceType}
            data={tableData}
            selectedSubService={this.state.selectedSubService}
            setSubService={this.setSubService}
          />
        ) : (<></>)
        }
      </>
    )
  }

  renderCommodities = () => {
    const commodities = this.getCommoditiesList();

    return (
      <>
        <CommoditiesWrapper>
          <SubHeadingFont>Commodities</SubHeadingFont>
          <CommoditiesList CommoditiesIcon={CommoditiesIcon} commoditiesArray={commodities}/>
        </CommoditiesWrapper>
      </>
    )
  }
  renderWarning = () => {
    return (
      <WarningBox>
        <img src={warningIcon} />
        <Typography style={style.warningFont}>
          {webConfigJSON.DEFAULT_MSG.TRANSPORTATION_WARNING}
        </Typography>
      </WarningBox>
    )
  }
  renderComplementary = () => {
    const complementaryServices = this.getComplementaryService();
    const { selectedTransportaion, selectedMedication, isDetailsLoading } = this.state
    const showComplementary = complementaryServices.length > 0 && !isDetailsLoading
    return (
      <>
        {showComplementary ? (
          <>
            <Box style={{ marginTop: "40px" }}>
              <CustomHR />
              <ComplementaryWrapper>
                <SubHeadingFont>Complementary services</SubHeadingFont>
                <CompServiceBoxWrapper>
                  {complementaryServices.map((compService, index) => {
                    const complementaryTitle = returnTruthyString(compService.title);
                    const complementaryPrice = returnTruthyString(compService.price.toFixed(2));
                    const complementaryChecked = checkCondition(compService.title === webConfigJSON.MEDICATION_SERVICE, selectedMedication.checked, selectedTransportaion.checked) as boolean
                    const complementaryType = checkCondition(compService.title === webConfigJSON.MEDICATION_SERVICE, "medication", "transportation") as "medication" | "transportation"
                    const complementaryImg = checkCondition(compService.title === webConfigJSON.MEDICATION_SERVICE, MedicationIcon, TransportationIcon) as string
                    return (
                      <CompServiceBox key={index}>
                        <CustomCheckbox
                          checkedIcon={<img src={checkedIcon} />}
                          icon={<img src={unCheckedIcon} />}
                          data-test-id="complementary-services"
                          checked={complementaryChecked}
                          onChange={(event) => this.setComplementaryService(event.target.checked, complementaryType, compService)}
                        />
                        <img src={complementaryImg} />
                        <Typography style={style.compServiceFont}>
                          {complementaryTitle}
                        </Typography>
                        <Typography style={style.compCurrencyFont}>
                          {complementaryPrice} €
                        </Typography>
                      </CompServiceBox>
                    );
                  })}
                </CompServiceBoxWrapper>

                {selectedTransportaion?.checked ? this.renderWarning() : <></>}
              </ComplementaryWrapper>
              <CustomHR />
            </Box>
          </>
        ) : (
          <></>
        )}
      </>
    )
  }
  renderBookingDates = () => {
    const BookNowBtnImg = <img src={RightBtnArrowIcon} style={style.rightArrowImg} />
    return (
      <>
        <BookingWrapper>
          {this.renderSelectPet()}
          {this.renderOtherFields()}
          <BookingButton
            isLoading={this.state.createBookingLoading}
            imageTag={BookNowBtnImg}
            buttonText="Book Now"
            onClick={this.onSave}
            type={"submit"}
            data-testid="booking-button">
          </BookingButton>
           
          <Typography style={style.notChargedFont}>{webConfigJSON.DEFAULT_MSG.NOT_CHARGED}</Typography>
          {this.renderBookingPrice()}
        </BookingWrapper>
      </>
    )
  }
  renderBookingPrice = () => {
    const { selectedSubService, selectedMedication, selectedTransportaion } = this.state

    const calculatedPrice = this.calculateTotalPrice()
    const noOfNights = checkCondition(Boolean(this.calculateHotelNights()), this.calculateHotelNights()+ " x ", "") as string
    const TotalPrice = checkCondition(calculatedPrice == 0, "___", calculatedPrice.toFixed(2) + " €") as string
    const subServicePrice =  checkCondition(Boolean(returnTruthyString(selectedSubService?.price)), returnTruthyString(selectedSubService?.price.toFixed(2)) + " €", "___") as string
    const isComplementarySelected = selectedMedication.checked || selectedTransportaion.checked
    const showMediation = selectedMedication.checked && selectedMedication.service !== null
    const showTransportation = selectedTransportaion.checked && selectedTransportaion.service !== null
    const compService = (service: Price | null) => {
      return (
        <SpaceBetweenBox style={{ marginTop: "20px" }}>
          <Typography style={style.compServiceNameFont}>{service?.title}</Typography>
          <Typography style={style.compServicePriceFont}>{service?.price?.toFixed(2)} €</Typography>
        </SpaceBetweenBox>
      )
    }
    return (
      <>
        <SpaceBetweenBox>
          <Typography style={style.serviceNameFont}>{this.serviceName()}</Typography>
          <Typography style={style.serviceNameFont}>{noOfNights}{subServicePrice}</Typography>
        </SpaceBetweenBox>

        {isComplementarySelected ?
          <Box style={style.compServiceSummary}>
            <Typography style={style.compServiceTitleFont}>Complementary services</Typography>
            {showMediation ? compService(selectedMedication.service) : <></>}
            {showTransportation ? compService(selectedTransportaion.service) : <></>}
          </Box>
          : <></>
        }

        <CustomHR style={style.marginHR} />
        <TotalPriceWrapper>
          <SpaceBetweenBox>
            <TotalFont>Total</TotalFont>
            <TotalPriceFont>{TotalPrice}</TotalPriceFont>
          </SpaceBetweenBox>
        </TotalPriceWrapper>
      </>
    )
  }
  renderOtherFields = () => {
    const activity_name = this.serviceName()
    switch (activity_name) {
      case webConfigJSON.SERVICES_ENUM.DAY_CARE:
      case webConfigJSON.SERVICES_ENUM.PET_SITTING:
        return this.DayCareSittingFields()
      case webConfigJSON.SERVICES_ENUM.DOG_WALKING:
        return this.DogWalkingFields()
      case webConfigJSON.SERVICES_ENUM.HOTELS:
        return this.HotelsFields()
      case webConfigJSON.SERVICES_ENUM.TRAINING:
        return this.TrainingFields()
    }
  }
  DayCareSittingFields = () => {
    return (
      <>
        <Box style={style.startDatestyle}>
          {this.renderStartDate()}
        </Box>
        {this.renderRepeatDays()}
      </>
    )
  }
  DogWalkingFields = () => {
    return (
      <>
        <CustomFieldsWrapper>
          <CustomFieldsBox>{this.renderStartDate()}</CustomFieldsBox>
          <CustomFieldsBox>{this.renderTimeField()}</CustomFieldsBox>
        </CustomFieldsWrapper>
        {this.renderRepeatDays()}
      </>
    )
  }
  HotelsFields = () => {
    return (
      <>
        <CustomFieldsWrapper style={{ marginBottom: 27 }}>
          <CustomFieldsBox>{this.renderStartDate()}</CustomFieldsBox>
          <CustomFieldsBox>{this.renderEndDate()}</CustomFieldsBox>
        </CustomFieldsWrapper>
      </>
    )
  }
  TrainingFields = () => {
    return (
      <>
        <Box style={style.startDatestyle}>
          {this.renderStartDate()}
        </Box>
      </>
    )
  }

  renderRepeatDays = () => {
    const { isRepeatWeekly, repeatDays } = this.state
    return (
      <>
        <RepeatDaysWrapper>
          <RepeatButton
            buttonText="Repeat Weekly"
            checked={isRepeatWeekly}
            checkedIcon={checkRepeat}
            unCheckedIcon={unCheckRepeat}
            onClick={this.handleRepeatBtn}
            data-testid="repeat-button"
          />
          {isRepeatWeekly ?
            (<>
              <RepeatDayBox>
                {repeatDays.map((eachDay, dayIndex) => {
                  return <RepeatDayButton key={dayIndex}
                    buttonText={eachDay.label}
                    checked={eachDay.checked}
                    onClick={() => this.setRepeatDay(dayIndex)}
                    data-testid={`day-button-${dayIndex}`}
                    disabled={this.getRepeatDayDisable(eachDay)}
                  />
                })}
              </RepeatDayBox>
            </>
            ) : <></>}

        </RepeatDaysWrapper>
      </>
    )
  }

  renderSelectPet = () => {
    const { selectedPetId, error } = this.state
    const allowedPetList = this.getAllowedPetList()
    return (
      <Box>
        <InputBoxWrapper>
          <CustomLabel>Select Pet</CustomLabel>
          <CustomField
            fullWidth
            startAdornment={Adornment(petPawIcon)}
            MenuProps={selectMenuProps}
            IconComponent={CustomMenuIcon}
            variant="outlined"
            value={selectedPetId}
            onChange={(event) => this.setPetId(event.target.value as string | number)}
            data-testid="pet-input-field"
            displayEmpty
          >
            <MenuFont value={""} disabled>Select Pet</MenuFont>
            {allowedPetList.map((petItem, index) => {
              return (
                <MenuFont key={index} value={petItem.attributes.id}>
                  {returnTruthyString(petItem.attributes.name)}
                </MenuFont>
              )
            })}
          </CustomField>
        </InputBoxWrapper>
        <FormHelperText error>{error.selectedPetId}</FormHelperText>
      </Box>
    )
  }

  renderStartDate = () => {
    const { selectedStartDate, error } = this.state
    return (
      <>
        <InputBoxWrapper>
          <CustomLabel>Start Date</CustomLabel>
          <DatePicker
            showIcon
            selectsStart
            withPortal
            toggleCalendarOnIconClick
            placeholderText="Select Start Date"
            dateFormat="EEE dd/MM/yyyy"
            minDate={new Date()}
            selected={selectedStartDate}
            onChange={(event) => this.setStartEndDate(event, "start")}
            icon={<img src={DateCalendar} alt="calendar-icon" />}
            data-testid='service-start-date'
          />
        </InputBoxWrapper>
        <FormHelperText error>{error.selectedStartDate}</FormHelperText>
      </>
    )
  }
  renderEndDate = () => {
    const { selectedStartDate ,selectedEndDate, error } = this.state
    return (
      <>
        <InputBoxWrapper>
          <CustomLabel>End Date</CustomLabel>
          <DatePicker
            showIcon
            startDate={selectedStartDate}
            selectsEnd
            withPortal
            toggleCalendarOnIconClick
            placeholderText="Select End Date"
            dateFormat="EEE dd/MM/yyyy"
            minDate={nextDayDate(selectedStartDate)}
            selected={selectedEndDate}
            onChange={(event) => this.setStartEndDate(event, "end")}
            icon={<img src={DateCalendar} alt="calendar-icon" />}
            data-testid='service-end-date'
          />
        </InputBoxWrapper>
        <FormHelperText error>{error.selectedEndDate}</FormHelperText>
      </>
    )
  }
  renderTimeField = () => {
    const { selectedShiftTime, error } = this.state
    return (
      <>
        <InputBoxWrapper>
          <CustomLabel>Select Time</CustomLabel>
          <CustomField
            fullWidth
            startAdornment={Adornment(DateCalendar)}
            MenuProps={selectMenuProps}
            IconComponent={CustomMenuIcon}
            variant="outlined"
            displayEmpty
            value={selectedShiftTime}
            onChange={(event) => this.setShiftTime(event.target.value as string)}
            data-testid='time-shift'
          >
            <MenuFont value={""} disabled>Select Time</MenuFont>
            <MenuFont value={"Morning"}>Morning</MenuFont>
            <MenuFont value={"Evening"}>Evening</MenuFont>
          </CustomField>
        </InputBoxWrapper>
        <FormHelperText error>{error.selectedShiftTime}</FormHelperText>
      </>
    )
  }

  renderCancellationPolicy = () => {
    const policyText = returnTruthyString(this.state.getDetailsData?.data?.attributes?.cancellation_policy);
    const { isDetailsLoading } = this.state
    return (
      <>
        <PolicyWrapper>
          <SubHeadingFont>Cancellation policy</SubHeadingFont>
          <CancellationPolicy loading={isDetailsLoading} policyText={policyText}/>
        </PolicyWrapper>
      </>
    );
  }
  renderLocation = () => {
    const { isDetailsLoading } = this.state
    const establishmentInfo = this.state.getDetailsData?.data?.attributes?.establishment;
    const latitude = returnTruthyString(establishmentInfo?.latitude)
    const longitude = returnTruthyString(establishmentInfo?.longitude)

    const center = {
      lat: Number(latitude),
      lng: Number(longitude),
    };
    const markers = {
      position: center,
    };
    return (
      <>
        {!isDetailsLoading ?
          <LocationWrapper>
            <SubHeadingFont>Location</SubHeadingFont>
            {latitude && longitude ?
              <MapWrapper>
                <BookingWebMap center={center} markers={markers}></BookingWebMap>
                <ViewMapBtn>View Map</ViewMapBtn>
              </MapWrapper>
              : <></>}
          </LocationWrapper>
          : <></>}
      </>
    )
  }

  renderLoader = () => {
    return (
      <LoaderContainer>
        <CustomLoader />
      </LoaderContainer>
    )
  }

  renderSimpleDialog = () => {
    const { openSimpleModal, messageSimpleModal } = this.state
    return (
      <>
        {openSimpleModal &&
          <SimpleDialog
            data-testid="simple-dialog"
            open={openSimpleModal}
            message={messageSimpleModal}
            showRightBtn
            rightBtnTitle="Okay"
            handleRightClick={this.simpleModalClose}
          />
        }
      </>
    )
  }

  capacityFullModal = () => {
    const image = <img src={capacityCalendar} alt="img" />
    const { openCapacityModal } = this.state
    const establishmentInfo = this.state.getDetailsData?.data?.attributes?.establishment;
    const establishmentName = returnTruthyString(capitalizeFirstLetter(establishmentInfo?.establishment_name));
    const modalTitle = `Looks like ${establishmentName} is booked up.`
    return (
      <>
        <DialogWithImage
          data-testid="capacity-full-dialog"
          open={openCapacityModal}
          imageTag={image}
          title={modalTitle}
          subTitle={webConfigJSON.DEFAULT_MSG.CAPACITY_SUBTITLE}
          btnText="Select different Time"
          handleClose={this.handleCloseCapacityModal}
          handleBtnClick={this.handleCloseCapacityModal}
        />
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <ThemeProvider theme={theme}>
          <AppHeader navigation={this.props.navigation} />
          <LandingPageHeader
            hideTitle
            tabValue={this.state.tabValue}
            onTabChange={this.handleTabChange}
            SearchDataFromHeader={(data) => this.goToAdvancedSearchPage(data)}
            isRepeatShow={false}
            data-test-id="landing-booking-page-header"
            propData={this.state.headerData}
          />

          <MainWrapper>
            {this.state.isDetailsLoading ? this.renderLoader() : (<></>)}
            {!this.state.isDetailsLoading ? this.renderServiceHeader() : (<></>)}
            {this.renderSubServicePart()}
            {this.renderLocation()}
            {this.renderSimpleDialog()}
            {this.capacityFullModal()}
          </MainWrapper>
          <AppFooter navigation={this.props.navigation} />
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  marginTop: "150px",
  padding: "0 150px",
  "@media (max-width: 1460px)": {
    padding: "0 120px",
  },
  "@media (max-width: 1280px)": {
    padding: "0 80px",
  },
  "@media (max-width: 960px)": {
    marginTop: "100px",
    padding: "0 40px",
  },
  "@media (max-width: 600px)": {
    padding: "0 32px",
  },
  // GLOBAL CALENDAR
  "& .react-datepicker__input-container": {
    marginTop: "2px",
    opacity: "1 !important",
    position: "unset",
    "& img": {
      width: "24px",
      height: "24px",
      padding: "0px 12px 4px 0px"
    }
  },
  "& .react-datepicker__input-container input": {
    width: "100% ",
    height: "auto !important",
    padding: "0px 10px 0px 38px !important",
    border: "none !important",
    background: "transparent !important",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#0F172A",
    "&::placeholder": {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#0F172A",
      opacity: 1,
    },
    '&:focus': {
      border: "none !important",
      outline: "none !important",
    },
  },
})

const ServiceTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "36px",
  color: "#000000",
  fontWeight: 600,
  marginBottom: "30px",
  "@media (max-width: 1280px)": {
    fontSize: "30px",
  },
  "@media (max-width: 960px)": {
    fontSize: "24px",
  },
  "@media (max-width: 600px)": {
    fontSize: "18px",
  },
})
const AddressBox = styled(Box)({
  display: "flex"
})

const SubHeadingFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "26px",
  fontWeight: 600,
  lineHeight: "27px",
  color: "#000000"
})
const GalleryWrapper = styled(Box)({
  marginTop: "30px",
  borderRadius: "20px",
  maxHeight: '626px',
  overflow: 'hidden',
});

const GalleryGrid = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: '8px',
});

const LeftImageBox = styled(Box)({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxHeight: "623px",
  "@media (max-width: 1280px)": {
    maxHeight: "523px",
  },
  "@media (max-width: 960px)": {
    maxHeight: "423px",
  },
  "@media (max-width: 600px)": {
    maxHeight: "323px",
  },
});

const RightImageBox = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const ImageBoxWrapper = styled(Box)({
  display: "flex",
  justifyContent: 'space-between',
  gap: '8px',
  height: "307px",
  "@media (max-width: 1280px)": {
    maxHeight: "257px",
  },
  "@media (max-width: 960px)": {
    maxHeight: "207px",
  },
  "@media (max-width: 600px)": {
    maxHeight: "157px",
  },
});

const ImageBox = styled(Box)({
  flex: '1 1 calc(50% - 4px)',
  maxHeight: '307px',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  "@media (max-width: 1280px)": {
    maxHeight: "257px",
  },
  "@media (max-width: 960px)": {
    maxHeight: "207px",
  },
  "@media (max-width: 600px)": {
    maxHeight: "157px",
  },
});

const AboutWrapper = styled(Box)({
  marginTop: "50px",
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  "@media (max-width: 959px)": {
    flexDirection: "column",
  }
})

const EstablishmentBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "30px",
  minWidth: "268px",
  maxWidth: "400px"
})
const EstablishmentAvatar = styled(Avatar)({
  height: "93px",
  width: "93px",
  fontFamily: "Montserrat",
  fontWeight: 700,
  backgroundColor: "#A6A6A6"
})
const AboutBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
})
const AboutSpan = styled(`span`)({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "27px",
  color: "#000000",
  whiteSpace: "pre-wrap",
})
const CommoditiesWrapper = styled(Box)({
  marginTop: "40px",
})


const ComplementaryWrapper = styled(Box)({
  padding: "40px 0px",
})
const CustomHR = styled(Divider)({
  color: "#B7B7B7"
})
const CompServiceBoxWrapper = styled(Box)({
  marginTop: "37px",
  marginBottom: "37px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "37px"
})
const CompServiceBox = styled(Box)({
  position: "relative",
  padding: "24px 13px 16px 13px",
  width: "100%",
  maxWidth: "180px",
  borderRadius: "8px",
  boxShadow: "0px 0px 7.5px 0px #0000001A",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center",
})
const CustomCheckbox = styled(Checkbox)({
  position: "absolute",
  top: "3px",
  right: "3px"
})
const WarningBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "8px"
})

const InputBoxWrapper = styled(Box)({
  borderRadius: "8px",
  border: "0.5px solid #CBD5E1",
  padding: "14px 20px"
})
const CustomLabel = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#A6A6A6"
})
const CustomMenuIcon = styled(KeyboardArrowDown)({
  color: '#0F172A',
});
const CustomField = styled(Select)({
  paddingLeft: 0,
  marginTop: 2,
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none !important",
  },
  "& .MuiInputBase-root": {
    outline: "none !important",
    border: "none !important",
  },
  "& .MuiSelect-select": {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    paddingLeft: "12px",
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'Montserrat',
    fontSize: "20px",
    fontWeight: 500,
    color: "#0F172A",
  },
  "& .MuiSelect-icon": {
    top: 'calc(50% - 12px)',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    outline: "none"
  },
  "& .MuiInputBase-input": {
    border: "none",
    outline: "none",
    height: 8,
    "&::placeholder": {
      color: "#0F172A",
      opacity: 1,
      fontFamily: 'Montserrat',
      fontSize: "20px",
      fontWeight: 500,
    },
  },
})
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400
})
const BookingWrapper = styled(Box)({
  marginTop: "40px",
  padding: "32px",
  boxShadow: "0px 0px 8px 0px #0000001A",
  borderRadius: "12px",
  backgroundColor: "#fff",
  "@media (max-width: 600px)": {
    padding: "32px 24px",
  },
  "@media (max-width: 400px)": {
    padding: "32px 16px",
  }
})

const CustomFieldsWrapper = styled(Box)({
  marginTop: "27px",
  display: "flex",
  flexDirection: "row",
  gap: "27px",
  flexWrap: "nowrap",
  "@media (min-width: 960px) and (max-width: 1460px) ": {
    flexWrap: "wrap"
  },
  "@media (max-width: 660px) ": {
    flexWrap: "wrap"
  },
})
const CustomFieldsBox = styled(Box)({
  width: "50%",
  "@media (min-width: 960px) and (max-width: 1460px) ": {
    width: "100%",
  },
  "@media (max-width: 660px) ": {
    width: "100%",
  },
})

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
})
const TotalPriceWrapper = styled(Box)({
  marginTop: "20px"
})
const TotalFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: 600,
  color: "#000000"
})
const TotalPriceFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "36px",
  fontWeight: 700,
  color: "#6F05EA"
})
const RepeatDaysWrapper = styled(Box)({
  margin: "27px 0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  rowGap: "12px",
})
const RepeatDayBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  flexWrap: "wrap"
})


const PolicyWrapper = styled(Box)({
  marginTop: "40px",
  padding: "0px 24px",
  "@media (max-width: 959px)": {
    padding: "0px",
  }
})


const LocationWrapper = styled(Box)({
  marginTop: "40px",
})
const MapWrapper = styled(Box)({
  position: "relative",
  marginTop: "27px",
  marginBottom: "8px",
  width: "100%",
  maxWidth: "692px",
  boxShadow: "0px 0px 4.7px 0px #0000001A",
})
const ViewMapBtn = styled(Button)({
  position: "absolute",
  right: 18,
  top: 18,
  padding: "8px 36px",
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "none",
  color: "#6200EA",
  backgroundColor: "#fff",
  boxShadow: "none",
  "&:hover": {
    color: "#6200EA",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  "@media (max-width: 960px)": {
    padding: "8px 26px",
    fontSize: "14px",
  },
  "@media (max-width: 600px)": {
    padding: "8px 16px",
    fontSize: "12px",
  },
})

const style: Record<string, React.CSSProperties> = {
  locationImg: {
    margin: "0 13px 0 9px"
  },
  addressFont: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#909090"
  },
  establishmentFont: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#000000"
  },
  compServiceFont: {
    marginTop: "12px",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#000000",
    textTransform: "capitalize"
  },
  compCurrencyFont: {
    marginTop: "8px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    color: "#000000"
  },
  warningFont: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#F87171"
  },
  subImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    userSelect: "none"
  },
  notChargedFont: {
    margin: "16px 0",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
    color: "#777777"
  },
  serviceNameFont: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 400,
    color: "#000000"
  },
  compServiceSummary: {
    marginTop: "20px"
  },
  compServiceTitleFont: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
    lineHeight: "14px"
  },
  compServiceNameFont: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "26px",
    textTransform: "uppercase"
  },
  compServicePriceFont: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "24px",
  },
  marginHR: {
    margin: "20px 0"
  },
  rightArrowImg: {
    marginLeft: "32px"
  },
  startDatestyle: {
    margin: "27px 0"
  }
}

const NoDataSpan = styled(`span`)({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "27px",
  color: "#A6A6A6",
  whiteSpace: "pre-wrap",
})

const CustomLoader = styled(CircularProgress)({
  color: "#6F05EA"
});
const LoaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginTop: "50px",
  marginBottom: "50px"
})
// Customizable Area End
