import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Typography, styled } from '@material-ui/core';
import moment from 'moment';

import { leftArrow, Portrait, CardDogImage, request_icon } from '../assets';
import { defaultButton } from './FormWrapper';
import { Request, Shedule } from '../CustomisableUserProfilesController';
import RequestStatus from './RequestStatus';
import Field from './Field';
import RequestFields from './RequestFields';

type Props = {
    isLoading: boolean;
    selectedEvent: Shedule;
    onBackClick: (val: Shedule | null) => void; 
    toggleCancelation: () => void;
    toggleRequestChange: () => void;
    changeRequestStatus: (type: 'rejected' | 'accepted') => void;
}

const Wrapper = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    "& .header-wrapper": {
        marginBottom: '40px',
    },

    "& .header": {
        display: 'flex',
        gap: '24px',
        color: '#000',
        alignItems: 'center'
    },
    "& .header-text": {
        fontSize: '40px',
        fontWeight: 600,
    },
    "& .sections": {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },
    "& .section-wrapper": {
        background: '#F6F6F6',
        padding: '32px 38px',
        borderRadius: '10px',
    },
    "& .fields-wrapper": {
        display: 'flex',
        gap: '32px',
        flexDirection: 'column',
        [theme.breakpoints.up("md")]: {
            flexDirection: 'row',
          },
    },
    "& .field-set": {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        width: '100%'
    },
    "& .arrow-style": {
        userSelect: 'none', cursor: 'pointer'
    },
    "& .section-header": {
        display: 'flex', gap: '7px', alignItems: 'center', marginBottom: '32px'
    },
    "& .section-text": {
        color: '#6F05EA',
        fontSize: '18px',
        fontWeight: 600
    },
    "& .buttons-wrapper": {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: '20px'
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #4B4B4B",
        color: '#4B4B4B',
        padding: '10px 26px',
        fontWeight: 700,
        fontSize: '18px'
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #6F05EA",
        color: '#6F05EA',
        fontWeight: 700,
        fontSize: '18px',
        padding: '10px 26px',
    },
    "& .accept-btn": {
        ...defaultButton,
        padding: '8px 39px',
        fontSize: '18px',
        fontWeight: 700,
        color: '#6F05EA',
        border: '1px solid #6F05EA',
        width: 'auto'
    },
    "& .reject-btn": {
        ...defaultButton,
        fontSize: '18px',
        color: '#C10000',
        fontWeight: 700,
        border: '1px solid #C10000',
        padding: '8px 39px',
        width: 'auto'
    },
    "& .req-buttons-wrapper": {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '19px',
        marginTop: '40px'
    }
}))

const LoaderButton = ({ color, size, text, isLoading }: { color: string, size: string, text: string, isLoading: boolean }) => {
    if(isLoading) {
        return <CircularProgress style={{ color }} size={size} />
    }

    return <>{text}</>;
}

const getParams = (request: Request | null) => {
    return {
        isShowRequest: request && request.request_type === 'Reschedule Request' && request.created_by === 'vendor' && request.status === 'pending',
        isRequestCustomer: !!request && request.request_type === 'Reschedule Request' && request.created_by === 'customer',
        isEndDate: request && request.end_date,
        isTime: request && request.shift
    }
}

const ApointmentDetails = ({ isLoading, selectedEvent, onBackClick, toggleCancelation, changeRequestStatus, toggleRequestChange }: Props) => {
    const [actions, setActions] = useState(false);
    const { birthdate, breed, fur, gender, size, weight, pet_category, name } = selectedEvent.pet.data.attributes;
    const request = selectedEvent.requests.request_details;

    const { isShowRequest, isEndDate, isRequestCustomer, isTime } = getParams(request);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const toggleActions = () => {
        setActions(!actions);
    }

    return (
        <Wrapper data-test-id="apointments-details">
            <Box className='header-wrapper'>
                <Box className='header'>
                    <Box data-test-id="back-btn" onClick={() => onBackClick(null)}><img src={leftArrow} className='arrow-style'/></Box>
                    <Typography className='header-text'>{pet_category}</Typography>
                    <Typography className='header-text'>{selectedEvent.service.service_type}</Typography>
                </Box>
                <RequestStatus request={selectedEvent.requests.request_details} />
            </Box>
            <Box className='sections'>
                {isShowRequest && request && 
                    <Box className='section-wrapper'>
                        <Box className='section-header'>
                            <img src={request_icon} />
                            <Typography className='section-text'>Request</Typography>
                        </Box>
                        <Box className='fields-wrapper' style={{ alignItems: 'flex-end'}}>
                            <Box className='field-set'>
                                <Field title='' value={request.request_type} />
                                <Field title='Old Date' value={moment(selectedEvent.start_date).format('DD/MM/YYYY')} />
                                {isEndDate && <Field title='Old End Date' value={moment(selectedEvent.end_date).format('DD/MM/YYYY')} />}
                                {isTime && <Field title='Old Time' value={selectedEvent.shift} />}
                            </Box>
                            <Box className='field-set'>
                                <Box />
                                <Field title='Updated Date' value={moment(request.start_date).format("DD/MM/YYYY")} />
                                {isEndDate && <Field title='Updated End Date' value={moment(request.end_date).format('DD/MM/YYYY')} />}
                                {isTime && <Field title='Updated Time' value={request.shift} />}
                            </Box>
                        </Box>
                        <Box className='req-buttons-wrapper'>
                            <Button className='reject-btn' onClick={() => changeRequestStatus('rejected')} disabled={isLoading}>
                                <LoaderButton color="#C10000" size="18px" text="Reject" isLoading={isLoading} />
                            </Button>
                            <Button className='accept-btn' onClick={() => changeRequestStatus('accepted')} disabled={isLoading}>
                                <LoaderButton color="#6F05EA" size="18px" text="Accept" isLoading={isLoading} />
                            </Button>
                        </Box>
                    </Box>
                }
                <Box className='section-wrapper'>
                    <Box className='section-header'>
                        <img src={Portrait} />
                        <Typography className='section-text'>Appointment Details</Typography>
                    </Box>
                    <Box className='fields-wrapper'>
                        <Box className='field-set'>
                            <RequestFields event={selectedEvent} isRequestCustomer={isRequestCustomer} />
                        </Box>
                        <Box className='field-set'>
                            <RequestFields event={selectedEvent} isRequestCustomer={isRequestCustomer} isRight={true}/>
                        </Box>
                    </Box>
                </Box>
                <Box className='section-wrapper'>
                    <Box className='section-header'>
                        <Avatar src={CardDogImage} style={{ width: '24px', height: '24px'}} />
                        <Typography className='section-text'>{name}`s Details</Typography>
                    </Box>
                    <Box className='fields-wrapper'>
                        <Box className='field-set'>
                            <Field title='Animal' value={pet_category} />
                            {breed && <Field title='Breed' value={breed} />}
                            {weight && <Field title='Weight' value={weight} />}
                            {fur && <Field title='Fur' value={fur} />}
                        </Box>
                        <Box className='field-set'>
                            {gender && <Field title='Gender' value={gender} />}
                            <Field title='Birthdate' value={birthdate} />
                            {size && <Field title='Size' value={size} />}
                        </Box>
                    </Box>
                </Box>
                <Box className='buttons-wrapper'>
                    {actions ? 
                    <>
                        <Button data-test-id="req-change-btn" className='secondary-btn' onClick={toggleRequestChange}>Request change</Button>
                        <Button data-test-id="cancelation-btn" className='secondary-btn' onClick={toggleCancelation}>Request cancellation</Button>
                        <Button className='main-btn' onClick={toggleActions}>Cancel</Button>
                    </> 
                    : <Button data-test-id='action-btn' className='main-btn' onClick={toggleActions}>Request change</Button>
                    }
                </Box>
            </Box>
        </Wrapper>
    )
}


export default ApointmentDetails;