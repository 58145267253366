Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";

exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
}

exports.ENDPOINTS = {
  GET_PET_LIST: "account_block/animal_types",
  GET_SERVICE_DETAILS: "account_block/services",
  CREATE_BOOKING: "bx_block_scheduling/schedules"
}

exports.SERVICES_ENUM = {
  DAY_CARE: "Day Care",
  DOG_WALKING: "Dog Walking",
  HOTELS: "Hotels",
  GROOMING: "Grooming",
  PET_SITTING: "Pet Sitting",
  TRAINING: "Training"
}

exports.RECURRENCE_KEY = ["Once a week", "Twice a week", "3 times a week", "4 times a week", "5 times a week", "6 times a week", "7 times a week"];
exports.COMPLEMENTARY_SERVICE = ["medication", "transportation"];
exports.MEDICATION_SERVICE = "medication"
exports.TRANSPORTATION_SERVICE = "transportation"

exports.FIELDS_ERROR = {
  PET: "Please select Pet",
  START_DATE: "Please select Start Date",
  END_DATE: "Please select End Date",
  SHIFT_TIME: "Please select Time"
}
exports.DEFAULT_MSG = {
  NOT_CHARGED: "You will not be charged yet",
  SELECT_SUB_SERVICE_MSG: "Please select a room or service to book.",
  TRANSPORTATION_WARNING: "Transportation will be confirmed by the vendor.",
  SUCCESS_BOOKING: "Service booked Successfully.",
  CAPACITY_SUBTITLE: "Let's find a better time for you!",
  CAPACITY_FULL_ERROR : "Capacity is full between these dates"
}

// Customizable Area End