import React from "react";

// Customizable Area Start
import CustomisableUserProfileVenderController, {
  Props,
} from "./CustomisableUserProfilesVenderController.web";
import SidebarServices from "./SidebarServices.web"
import  NavigationMenu  from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import {
  Box, styled, Tabs, Tab, IconButton, Drawer, Button
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import GeneralInformation from "./GeneralInformation.web";
import RequestDetails from "./CustomisableUserGallary.web";
import CustomisableUserCalendarVendorWeb from "./CustomisableUserCalendarVendor.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { Tab1, Tab2, Tab3, Tab4 } from "./assets";
import Settings2 from '../../settings2/src/Settings2.web';
import GeneralRequestTab from '../../requestmanagement/src/GeneralRequestTab.web'
// Customizable Area End

export default class CustomisableUserProfilesVender extends CustomisableUserProfileVenderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSettingsBlock = () => {
    return (
      <RightWrapper>
        <Box style={{ width: '100%'}}>
          <TabWrapper
            variant="scrollable"
            value={this.state.currentSettingsTab}
            data-test-id="settings-tabs"
            onChange={(_, idx) => this.setTabId(idx)}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#6F05EA',
              },
            }}
          >
            <CustomTab label={<TabBox><img src={Tab1} />Calendar</TabBox>} data-testid="Calendar-tab"/>
            <CustomTab label={<TabBox><img src={Tab2} />Gallery</TabBox>} data-testid="Gallery-tab"/>
            <CustomTab label={<TabBox><img src={Tab3} />Dashboard</TabBox>} data-testid="Dashboard-tab"/>
            <CustomTab label={<TabBox><img src={Tab4} />General Information</TabBox>} data-testid="General-Information-tab"/>
          </TabWrapper>
          {this.renderSettingsTabContent()}

        </Box>
      </RightWrapper>
    )
  }
  renderSettingsTabContent = () => {
    switch (this.state.currentSettingsTab) {
      case 0:
        return this.renderCalenderTab();
      case 1:
        return this.renderGallaryTabTab();
      case 2:
        return this.renderDashboardTab();
      case 3:
        return this.renderGeneralTab();
      default:
        break;
    }
  }
  renderCalenderTab = () => {
    return (
      <>
        <CustomisableUserCalendarVendorWeb 
          navigation={this.props.navigation} 
          id="vendor-calendar" 
          isShowrequestscreen={this.state.openRequestDetails}
          showRequestScreen ={this.handleRequestScreen} 
          selectedRequestId={this.state.selectedRequestId} 
          handleSetRequestId={this.handleSetRequestId}
          selectedService={this.state.selectedService}
        />
      </>
    )
  }
  renderGallaryTabTab = () => {
    return (
      <>
        <RequestDetails navigation={''} id="" selectedService={this.state.selectedService} />
      </>
    )
  }
  renderDashboardTab = () => {
    return (
      <>
        <UpcomingBox>Coming Soon!</UpcomingBox>
      </>
    )
  }
  renderGeneralTab=()=>{
    return(
      <>
        <GeneralInformation
          selectedService={this.state.selectedService}
          getServicesList={this.getServicesList}
        />
      </>
    )
  }
  renderServicesUI = () => {
    const { filtredServicesList } = this.state
    if (filtredServicesList.length) {
      return this.renderSettingsBlock();
    } else {
      return this.renderDefaultScrren()
    }
  }
  renderDefaultScrren = () => {
    return (
      <DefaultWrapper>
        <DefaultSubWrapper>
          Looks like you haven't created any services yet. Let's get started!
          <AddServiceBtn
            onClick={this.openServiceModal}
            data-testid="add-service-btn"
          >
            Add Service
          </AddServiceBtn>
        </DefaultSubWrapper>
      </DefaultWrapper>
    )
  }
  renderContent() {
    const { openRequestDetails, openSetting } = this.state;

    if (!openRequestDetails && !openSetting) {
      return this.renderServicesUI()
    }
    if (openRequestDetails && !openSetting) {
      return (
        <>
          <RightWrapper>
            <GeneralRequestTab
              navigation={undefined} id={""}
              selectedRequestId={this.state.selectedRequestId}
              showRequestScreen={this.handleRequestScreen}
              isShowrequestscreen={this.state.openRequestDetails}
              selectedService={this.state.selectedService}
            />
          </RightWrapper>
        </>
      )
    }

    if (!openRequestDetails && openSetting) {
      return (
        <>
          <RightWrapper>
            <Settings2 navigation={undefined} id={""} />
          </RightWrapper>
        </>
      )
    }
  }
  renderFooter = () => {
    return <AppFooter navigation={this.props.navigation} />
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationMenu id={''} navigation={this.props.navigation} isProfile={false} />
        <MobileMenuWrapper>
          <IconButton onClick={this.toggleDrawer(true)}><MenuIcon /></IconButton>
          <Drawer anchor="left" open={this.state.isSideBarOpen} onClose={this.toggleDrawer(false)}>
            <SidebarServices
              filtredServicesList={this.state.filtredServicesList}
              selectedService={this.state.selectedService}
              handleSetService={this.handleSetService}
              addServiceModalOpen={this.state.addServiceModalOpen}
              openServiceModalFn={this.openServiceModal}
              closeServiceModalFn={this.closeServiceModal}
              showsetting = {this.state.openSetting}
              showsettingFn ={this.handleSetting}
              nextButtonModalFn={this.nextButtonModal} navigation={undefined} id={""} 
              openSetting={this.state.openSetting}
              />
          </Drawer>
        </MobileMenuWrapper>
        <MainWrapper>
          <LeftWrapper data-testid="sidebar-wrapper">
            <SidebarServices
              filtredServicesList={this.state.filtredServicesList}
              selectedService={this.state.selectedService}
              handleSetService={this.handleSetService}
              addServiceModalOpen={this.state.addServiceModalOpen}
              openServiceModalFn={this.openServiceModal}
              closeServiceModalFn={this.closeServiceModal}
              nextButtonModalFn={this.nextButtonModal}
              showsetting = {this.state.openSetting}
              showsettingFn ={this.handleSetting}
              navigation={this.props.navigation} id={""}            
              openSetting={this.state.openSetting}
              />
          </LeftWrapper>
          {this.renderContent()}
        </MainWrapper>
        {this.renderFooter()}
      </>
    );
  }
}

const MainWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row"
})
const LeftWrapper = styled(Box)({
  display: "block",
  maxWidth: 313,
  width: "100%",
  "@media (max-width: 1280px)": {
    maxWidth: 253,
  },
  "@media (max-width: 960px)": {
    display: "none"
  },
})
const MobileMenuWrapper = styled(Box)({
  display: "none",
  backgroundColor: "#F6F4F9",
  "@media (max-width: 960px)": {
    display: "block"
  },
})
const RightWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  backgroundColor:"#F6F4F9",
  padding:"75px 100px",
  overflow: 'hidden',
  "@media (min-width: 1600px)": {
    padding: "75px 150px",

  },
  "@media (max-width: 1280px)": {
    padding: "75px 55px",

  },
  "@media (max-width: 960px)": {
    padding:"75px 35px",
  },
  "@media (max-width: 600px)": {
    padding:"75px 20px",
    width:"calc(100% - 40px)"
  },
  "@media (max-width: 400px)": {
    padding:"75px 12px",
    width:"calc(100% - 24px)"
  },
})

const TabWrapper = styled(Tabs)({
  marginBottom: 50,
  "& .MuiTabs-root": {
    overflow: "auto"
  },
  "& .MuiTabs-flexContainer": {
    columnGap: 8
  },
  "& .MuiTabs-indicator": {
    height: 3
  },
  "& .MuiTabs-scrollButtons": {
    display: "flex !important"
  }
})
const CustomTab = styled(Tab)({
  minWidth: 0,
  padding: "0 12px",
  opacity: 1,
  textTransform: "none",
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#000000",
})
const TabBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: 12,
  alignItems: "center",
})

const UpcomingBox = styled(Box)({
  padding: "48px",
  maxHeight: 400,
  backgroundColor: "#fff",
  boxShadow: "0px 2px 8px 0px #0000000F",
  borderRadius: "16px",
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#6F05EA"
})
const DefaultWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 32,
  backgroundColor: "#F6F4F9",
})
const DefaultSubWrapper = styled(Box)({
  maxWidth: "582px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  rowGap: "50px",
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#78716C"
})
const AddServiceBtn = styled(Button)({
  maxWidth: "300px",
  width: "100%",
  height: 58,
  borderRadius: "48px",
  border: "1px solid #6F05EA",
  textTransform: "none",
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#6F05EA"
})
// Customizable Area End
