// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IAnimal, IFilteredService, IServiceList } from "../../../../components/src/interfaces.web";
import { getAllowPetOptionList, getAnimalList, getServiceList } from "../../../../components/src/HelperUtils";
import { FormError, FormErrorTouched } from "./Services.web";
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../config.js");

interface IFormValue {
    serviceTitle: string;
    serviceCapacity: string;
    serviceAllowedPet: string;
    serviceDescription: string;
    imagesArray: File[],
    serviceTime?: string;
  }

  interface IApiModel {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string | null;
    isJsonStringify?: boolean;
  }

  interface IServiceAttribute {
    id: number,
    title: string,
    capacity: number,
    allows_pet: string,
    description: string,
    duration: null,
    shift: null,
    service_id: number,
    created_at: string,
    updated_at: string,
    images_urls: IImageResponse[],
    
  }
  interface IService {
    id:number;
    type: string;
    attributes: IServiceAttribute;
  }

  interface IImageResponse {
    id: number,
    file_name: string,
    file_url: string,
}
interface IGetServiceResponse {
    data:IService[]
  }

  interface IAddUpdateServiceResponse {
    data:{
      id:number,
      type:string,
      attributes:IServiceAttribute
    }
  }
// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    selectedService:IFilteredService;
    getServicesList:(id:string) => void;
    closePricesSections: () => void;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openAddService: boolean,
    openViewService: boolean,
    openEditService: boolean,
    serviceOptionList:IServiceList[],
    serviceFormInitialValue:IFormValue,
    allowedPetOptionList:IAnimal[],
    getServiceListData:IService[],
    getServiceListLoading:boolean,
    selectedViewData:IService,
    isEdit: boolean;
    saveBtnLoading: boolean;
    openCreateService:boolean;
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class ServicesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getServiceApiCallId : string = "";
    addServiceApiCallId : string = "";
    updateServiceApiCallId : string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            openAddService: false,
            openViewService: false,
            openEditService: false,
            serviceOptionList:[],
            serviceFormInitialValue:{  
            serviceTitle: "",
            serviceCapacity: "",
            serviceAllowedPet: "",
            serviceDescription: "",
            imagesArray:[],
          },
          allowedPetOptionList:[],
          getServiceListData:[],
          getServiceListLoading:false,
          selectedViewData: {} as IService,
          isEdit: false,
          saveBtnLoading: false,
          openCreateService:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (apiRequestCallId && responseJson) {
              switch (apiRequestCallId) {
                case this.getServiceApiCallId:
                  this.handleGetServiceResponse(responseJson);
                  break;
                case this.addServiceApiCallId:
                  this.handleAddServiceResponse(responseJson);
                  break;
                case this.updateServiceApiCallId:
                  this.handleUpdateServiceResponse(responseJson);
                  break;
                default:
                  break;
              }
            }
          }
        // Customizable Area End
    }
    // Customizable Area Start
    openAddServiceModal = () => {
      const emptyServiceId = this.props.selectedService.service_id === "";
      if (emptyServiceId) {
        {
          this.setState({
            openCreateService: true
          })
        }
      }
      else {
        this.setState({
          openAddService: true
        })
      }
    }
    closeAddServiceModal = () => {
        this.setState({
            openAddService: false,
            isEdit:false,
        })
        if(this.props.selectedService.name === "Pet Sitting"){
          this.setState({allowedPetOptionList:getAnimalList(this.props.selectedService.name)})
        }
        else{
        this.setState({allowedPetOptionList:[]})
        }
        this.closeEditServiceModal();
        this.getInitialValue();
    }

    openViewServiceModal = (selectedId:number) => {
        const selectedData = this.state.getServiceListData.find((item) => item.id === selectedId) as IService;
        this.setState({
            openViewService: true,
            selectedViewData:selectedData
        })
    }
    closeViewServiceModal = () => {
        this.setState({
            openViewService: false,
        })
    }
    openEditServiceModal = async () => {
        const data = this.state.selectedViewData;
        const imageList = await this.handleSelectedFile(data.attributes.images_urls);
        this.setState({
            openEditService: true,
            isEdit: true,
            serviceFormInitialValue:{
                serviceTitle: data.attributes.title ,
                serviceCapacity: data.attributes.capacity.toString(),
                serviceAllowedPet: data.attributes.allows_pet,
                serviceDescription: data.attributes.description,
                imagesArray:imageList,
                serviceTime: data.attributes.shift ?? "",
              }
        })
        this.closeViewServiceModal();
        if(this.props.selectedService.name === "Pet Sitting"){
          this.setState({allowedPetOptionList:getAnimalList(this.props.selectedService.name)})
        }
        else{
        this.setState({allowedPetOptionList:getAllowPetOptionList(data.attributes.title)})
        }
    }
    closeEditServiceModal = () => {
        this.setState({
            openEditService: false,
        })
    }

    async componentDidMount() {
        super.componentDidMount();
        this.setState({serviceOptionList:getServiceList(this.props.selectedService.name)});
        if(this.props.selectedService.name === "Pet Sitting"){
            this.setState({allowedPetOptionList:getAnimalList(this.props.selectedService.name)})
        }
        this.getInitialValue();
        if(this.props.selectedService.service_id){
            this.getServiceApiCall(); 
        }
    };

    handleServiceTitleChange = (serviceType:string) => {
        this.setState({allowedPetOptionList:getAllowPetOptionList(serviceType)})
    }

    getErrorAndHelperText = (
        field: string,
        errors: FormError,
        touched: FormErrorTouched
      ) => {
        const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
        const helperText: string = isError ? errors[field] ?? "" : "";
        return { isError, helperText };
      };

    getInitialValue = () => {
        switch (this.props.selectedService.name) {
            case "Dog Walking":
                return this.setState({serviceFormInitialValue:{
                    serviceTitle: "",
                    serviceCapacity: "",
                    serviceAllowedPet: "",
                    serviceDescription: "",
                    imagesArray:[],
                    serviceTime:""
                }})
            case "Grooming":
                return this.setState({serviceFormInitialValue:{
                    serviceCapacity: "",
                    imagesArray:[],
                    serviceTitle: "",
                    serviceDescription: "",
                    serviceAllowedPet: "",
                }});
            default:
                return this.setState({serviceFormInitialValue:{
                    serviceTitle: "",
                    serviceCapacity: "",
                    serviceAllowedPet: "",
                    serviceDescription: "",
                    imagesArray:[],
                }});
        }
    }

    commonApiCall = async (data: IApiModel) => {
        const {
          endPoint,
          contentType,
          method,
          token,
          isJsonStringify,
          body,
        } = data;
        const header = {
            "Content-Type": contentType,
            ...(token && { token }),
        };
        const requestedMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestedMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestedMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestedMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
          requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            isJsonStringify ? JSON.stringify(body) : body
          );
        runEngine.sendMessage(requestedMessage.id, requestedMessage);
        return requestedMessage.messageId;
      };

      getServiceApiCall = async () => {
        const token = await getStorageData("login_token");
        if (token) {
          this.setState({getServiceListLoading:true,getServiceListData:[]})
          this.getServiceApiCallId = await this.commonApiCall({
            method: configJSON.httpGetMethod,
            token: token,
            endPoint: `${configJSON.getServiceEndPoint}${this.props.selectedService.service_id}`,
          });
        }
      };

      handleGetServiceResponse = (responseJson:IGetServiceResponse) => {
        this.setState({getServiceListLoading:false})
        if(responseJson && responseJson.data && responseJson.data.length){
          this.setState({getServiceListData:responseJson.data})
        }
        else{
          this.setState({getServiceListData:[]})
        }
      }

      addUpdateApiCall = async(values: IFormValue) => {
        let newSpaceData = new FormData();
        newSpaceData.append("sub_service[title]",values.serviceTitle);
        newSpaceData.append("sub_service[capacity]",values.serviceCapacity);
        newSpaceData.append("sub_service[allows_pet]",values.serviceAllowedPet);
        newSpaceData.append("sub_service[description]",values.serviceDescription.trim());
        newSpaceData.append("sub_service[service_id]",this.props.selectedService.service_id);
        newSpaceData.append("sub_service[shift]",values.serviceTime ? values.serviceTime : "");
        if (values.imagesArray.length > 0) {
          values.imagesArray.forEach((file) => {
          newSpaceData.append("sub_service[images][]", file);
          });
          }
       
        const token = await getStorageData("login_token");
        if (token) {
          if (!this.state.isEdit) {
            this.setState({ saveBtnLoading: true })
            this.addServiceApiCallId = await this.commonApiCall({
              method: configJSON.httpPostMethod,
              token: token,
              endPoint: configJSON.addServiceEndPoint,
              body: newSpaceData,
            });
          } 
          else {
            this.setState({ saveBtnLoading: true })
            this.updateServiceApiCallId = await this.commonApiCall({
              method: configJSON.httpPutMethod,
              token: token,
              endPoint: `${configJSON.updateServiceEndPoint}${this.state.selectedViewData.id}?service_id=${this.props.selectedService.service_id}`,
              body: newSpaceData,
            });
          }
        }
      };

      handleAddServiceResponse = (responseJson:IAddUpdateServiceResponse) => {
        this.setState({ saveBtnLoading: false })
        if(responseJson && responseJson.data && responseJson.data.attributes){
          this.closeAddServiceModal();
          this.getServiceApiCall();
          this.props.closePricesSections()
        }
      };

      handleSelectedFile = async (
        images: IImageResponse[],
      ) => {
        return await Promise.all(
          images.map(async (image) => {
            const blob = await fetch(image.file_url).then((res) => res.blob());
            const urlParts = image.file_name.split(".");
            const fileExtension = urlParts.pop() || "jpg";
            let mimeType = "image/jpg";
            if (fileExtension === "png") {
              mimeType = "image/png";
            }
    
            const fileName = `backend-image-${image.file_name}`;
    
            return new File([blob], fileName, {
              type: mimeType,
            });
          })
        );
      };

      handleUpdateServiceResponse = (responseJson: IAddUpdateServiceResponse) => {
        this.setState({ saveBtnLoading: false })
        if (responseJson && responseJson.data && responseJson.data.attributes) {
            this.closeAddServiceModal();
            this.getServiceApiCall();
            this.getInitialValue();
        }
      };

      handleCreateServiceDialogClose = () => {
        this.setState({openCreateService:false})
      }
    // Customizable Area End
}

