export const AddressLocation = require("../assets/address_location.svg");
export const TransportationIcon = require('../assets/transportation-icon.svg');
export const MedicationIcon = require('../assets/medication-icon.svg');

export const CommoditiesIcon = require('../assets/comodities.svg');

export const checkedIcon = require('../assets/check_service.svg');
export const unCheckedIcon = require('../assets/uncheck_service.svg');
export const warningIcon = require('../assets/red_warning.svg');

export const capacityCalendar = require('../assets/capacity_calender.svg');

// BOOKING SUMMARY
export const petPawIcon = require('../assets/pet_paw.svg');
export const DateCalendar = require("../assets/calendar_icon.svg");
export const RightBtnArrowIcon = require('../assets/right_btn_arrow.svg');
export const checkRepeat = require("../assets/check_repeat.svg");
export const unCheckRepeat = require("../assets/uncheck_repeat.svg");
