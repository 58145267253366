import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  SelectProps,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  CheckedCheckBox,
  CommunityIcon,
  EmailIcon,
  EmployeeAvatar,
  EmployeeDeleteIcon,
  EmployeeEditIcon,
  ListActionIcon,
  NewEmployeeIcon,
  UnCheckedCheckBox,
  UserIcon,
} from "../assets";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import PhoneInput from "react-phone-input-2";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { generateTimeRange } from "../../../../components/src/HelperUtils";
import { LoadingButton } from "../../../../components/src/HelperComponents";
// Customizable Area End

import EmployeeManagementController, {
  Props,
} from "./EmployeeManagementController";
import { Formik } from "formik";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const Adornment = (imgSrc: string) => (
  <InputAdornment position="start">
    <img src={imgSrc} />
  </InputAdornment>
);

const selectMenuProps: Partial<SelectProps["MenuProps"]> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

interface IDayList {
  label: string;
  key:
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday";
}

const DaysList: IDayList[] = [
  {
    label: "Monday",
    key: "Monday",
  },
  {
    label: "Tuesday",
    key: "Tuesday",
  },
  {
    label: "Wednesday",
    key: "Wednesday",
  },
  {
    label: "Thursday",
    key: "Thursday",
  },
  {
    label: "Friday",
    key: "Friday",
  },
  {
    label: "Saturday",
    key: "Saturday",
  },
  {
    label: "Sunday",
    key: "Sunday",
  },
];

// Customizable Area End

export default class EmployeeManagementWeb extends EmployeeManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDefaultList = () => {
    return (
      <Box className="defaultListContainer">
        <img
          className="defaultNewEmpIcon"
          src={NewEmployeeIcon}
          alt="new-employee-icon"
        />
        <Box>
          <Typography className="defaultNewEmpTypo">
            The employee list is empty.
          </Typography>
          <Typography className="defaultNewEmpTypo">
            Please click 'Add New Employee' to get started.
          </Typography>
        </Box>
      </Box>
    );
  };

  renderDeleteModal = () => {
    return (
      <DeleteDialogContainer
        fullWidth
        maxWidth="md"
        open={this.state.isDeleteOpen}
        onClose={this.handleDeleteClose}
        aria-labelledby="max-width-dialog-title"
        data-test-id="delete-dialog"
      >
        <Box className="deleteModalContent">
          <Typography className="deleteModalTypo">
            Are you sure you want to permanently remove this employee?{" "}
          </Typography>
          <Box className="deleteActionContainer">
            <Button
              onClick={this.handleDeleteClose}
              className="deleteModalBtn sureBtn"
              data-test-id="sure-btn"
            >
              Yes, I'm sure
            </Button>
            <Button
              onClick={this.handleDeleteClose}
              className="deleteModalBtn removeBtn"
              data-test-id="remove-btn"
            >
              No, don't Remove
            </Button>
          </Box>
        </Box>
      </DeleteDialogContainer>
    );
  };

  renderAddEmployeeStep1 = () => {
    return (
      <Formik
        initialValues={this.state.addModalStep1InitialValue}
        enableReinitialize
        onSubmit={(values) => {
          this.handleNextClick(values);
        }}
        data-test-id="add-emp-step1"
      >
        {({ handleSubmit, getFieldProps }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={5}>
                <GridItem
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  className="imageContainer"
                >
                  <ImageWrapper>
                    <CustomFormLabel>Employee Picture</CustomFormLabel>
                    <GalleryWrapper>
                      <UploadButton>
                        <UploadBox>
                          <Box>
                            <UploadIcon />
                            <UploadText>upload file (png,jpg)</UploadText>
                            <input
                              type="file"
                              multiple
                              accept=".png, .jpg"
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                opacity: 0,
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                              data-test-id="upload-img"
                            />
                          </Box>
                        </UploadBox>
                      </UploadButton>
                    </GalleryWrapper>
                  </ImageWrapper>
                </GridItem>
                <GridItem item md={6} sm={12} xs={12}>
                  <CustomFormLabel>Employee Name</CustomFormLabel>
                  <CustomField
                    {...getFieldProps("name")}
                    InputProps={{
                      startAdornment: Adornment(UserIcon),
                    }}
                  />
                </GridItem>
                <GridItem item md={6} sm={12} xs={12}>
                  <CustomFormLabel>Select Role</CustomFormLabel>
                  <CustomField
                    select
                    {...getFieldProps("role")}
                    SelectProps={{
                      MenuProps: selectMenuProps,
                      IconComponent: CustomMenuIcon,
                    }}
                    InputProps={{
                      startAdornment: Adornment(CommunityIcon),
                    }}
                  >
                    <MenuFont value={1}>Option 1</MenuFont>
                    <MenuFont value={2}>Option 2</MenuFont>
                    <MenuFont value={3}>Option 3</MenuFont>
                  </CustomField>
                </GridItem>
                <GridItem item md={6} sm={12} xs={12}>
                  <CustomFormLabel>Email</CustomFormLabel>
                  <CustomField
                    {...getFieldProps("email")}
                    type="email"
                    InputProps={{
                      startAdornment: Adornment(EmailIcon),
                    }}
                  />
                </GridItem>
                <GridItem item md={6} sm={12} xs={12}>
                  <CustomFormLabel>Phone</CustomFormLabel>
                  <PhoneInput
                    buttonStyle={{
                      border: "none",
                      background: "transparent",
                    }}
                    country={"in"}
                    inputStyle={{
                      border: "none",
                      width: "100%",
                      borderBottom: "1px solid #D9D9D9",
                      borderRadius: "0px",
                      height: "32px",
                      paddingLeft: "40px",
                    }}
                    dropdownStyle={{ fontSize: "13px" }}
                    placeholder="Your contact number"
                    data-test-id="new-establish-phone"
                  />
                </GridItem>
                <GridItem item md={6} sm={12} xs={12}>
                  <CustomFormLabel>Select Service</CustomFormLabel>
                  <CustomField
                    select
                    {...getFieldProps("service")}
                    InputProps={{
                      startAdornment: Adornment(CommunityIcon),
                    }}
                    SelectProps={{
                      MenuProps: selectMenuProps,
                      IconComponent: CustomMenuIcon,
                    }}
                  >
                    <MenuFont value={10}>Option 1</MenuFont>
                    <MenuFont value={20}>Option 2</MenuFont>
                    <MenuFont value={30}>Option 3</MenuFont>
                  </CustomField>
                </GridItem>
                <GridItem item md={6} sm={12} xs={12}>
                  <CustomFormLabel>Select Sub Service</CustomFormLabel>
                  <CustomField
                    select
                    {...getFieldProps("subService")}
                    InputProps={{
                      startAdornment: Adornment(CommunityIcon),
                    }}
                    SelectProps={{
                      MenuProps: selectMenuProps,
                      IconComponent: CustomMenuIcon,
                    }}
                  >
                    <MenuFont value={10}>Option 1</MenuFont>
                    <MenuFont value={20}>Option 2</MenuFont>
                    <MenuFont value={30}>Option 3</MenuFont>
                  </CustomField>
                </GridItem>
                <GridItem item md={12} sm={12} xs={12}>
                  <AddActionContainer>
                    <Button
                      className="addModalBtn grayBtn"
                      onClick={this.handleAddClose}
                      data-test-id="cancel-btn"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" className="addModalBtn purpleBtn">
                      Next
                    </Button>
                  </AddActionContainer>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  };

  renderAddEmployeeStep2 = () => {
    const menuRange = generateTimeRange("00:00", "23:30", 30);
    const { daysCheckbox } = this.state;
    return (
      <Grid container spacing={5} data-test-id="add-emp-step2">
        <AvailabilityWrapper>
          {DaysList.map((dayItem, index) => {
            const checkBoxValue = daysCheckbox[dayItem.key];
            return (
              <DayBoxWrapper key={index}>
                <DayBox>
                  <DayCheckbox
                    label={dayItem.label}
                    value={checkBoxValue}
                    checked={checkBoxValue}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checkedIcon={<img src={CheckedCheckBox} />}
                        icon={<img src={UnCheckedCheckBox} />}
                        onChange={(event) =>
                          this.SetScheduleDayCheckbox(
                            event.target.checked,
                            dayItem.key
                          )
                        }
                      />
                    }
                    data-test-id={`day-checkbox-${index}`}
                  />
                </DayBox>

                {checkBoxValue ? (
                  <>
                    <Box>
                      <TimeBox>
                        <AccessTimeIcon
                          style={{
                            width: 24,
                            height: 24,
                            color: "#6F05EA",
                          }}
                        />
                        <CustomSelect
                          id="start-range-day"
                          displayEmpty
                          defaultValue={""}
                          variant="outlined"
                          IconComponent={DownArrowIcon}
                          className="edit-select"
                          data-test-id={`day-start-time-${index}`}
                        >
                          <MenuFont value={""} disabled>
                            00:00
                          </MenuFont>
                          {menuRange.map((each, index) => {
                            return (
                              <MenuFont value={each.value} key={index}>
                                {each.name}
                              </MenuFont>
                            );
                          })}
                        </CustomSelect>
                        <CustomSelect
                          className="edit-select"
                          id="end-range-day"
                          variant="outlined"
                          displayEmpty
                          IconComponent={DownArrowIcon}
                          defaultValue={""}
                          data-test-id={`day-end-time-${index}`}
                        >
                          <MenuFont value={""} disabled>
                            00:00
                          </MenuFont>
                          {menuRange.map((each, index) => {
                            return (
                              <MenuFont value={each.value} key={index}>
                                {each.name}
                              </MenuFont>
                            );
                          })}
                        </CustomSelect>
                      </TimeBox>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </DayBoxWrapper>
            );
          })}
        </AvailabilityWrapper>
        <GridItem item md={12} sm={12} xs={12}>
          <AddActionContainer>
            <Button
              className="addModalBtn grayBtn"
              onClick={this.handleAddBackClick}
              data-test-id="back-btn"
            >
              Back
            </Button>
            <LoadingButton
              buttonText="Add"
              className="addModalBtn purpleBtn"
              isLoading={false}
              data-test-id="add-btn"
              onClick={this.handleAddClose}
            />
          </AddActionContainer>
        </GridItem>
      </Grid>
    );
  };

  renderAddEmployeeModal = () => {
    const { stepNumber, isAddOpen } = this.state;
    return (
      <AddDialogContainer
        fullWidth
        maxWidth="md"
        open={isAddOpen}
        aria-labelledby="max-width-dialog-title"
        data-test-id="delete-dialog"
      >
        <FormTitle className="addDialogTitle">
          {stepNumber === 1 ? "Add New Employee" : "Select Availability"}
        </FormTitle>
        <FormWrapper>
          {stepNumber === 1 && this.renderAddEmployeeStep1()}
          {stepNumber === 2 && this.renderAddEmployeeStep2()}
        </FormWrapper>
      </AddDialogContainer>
    );
  };

  renderEmployeeList = () => {
    return (
      this.state.getEmployeeList.map((item) => (
        <Box className="empListContainer" key={item.id} data-test-id="emp-list">
          <Box className="empDataContainer">
            <Box className="infoContainer">
              <img className="empAvatarImg" src={EmployeeAvatar} alt="avatar" />
              <Box
                style={{
                  display: "flex",
                  gap: "16px",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    gap: "12px",
                    flexDirection: "column",
                  }}
                >
                  <Typography className="empName" data-test-id="emp-list-name">
                    {item.name}
                  </Typography>
                  <Typography className="empEmail">
                    Email id: {item.email}
                  </Typography>
                </Box>
                <Box className="empServiceType">{item.serviceType}</Box>
              </Box>
            </Box>
            <Box className="actionContainer">
              <IconButton
                onClick={(event) => this.handleMoreVertClick(event, item.id)}
                data-test-id="more-vert-icon"
                style={{ width: "32px", height: "32px" }}
              >
                <img src={ListActionIcon} alt="action-icon" />
              </IconButton>
              <StyledMenu
                id="simple-menu"
                anchorEl={this.state.anchorElMoreVert}
                keepMounted
                open={Boolean(this.state.anchorElMoreVert)}
                onClose={this.handleMoreVertClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                data-test-id="emp-list-action-option"
              >
                <MenuItem data-test-id="editEventBtn" className="menuItem">
                  <img
                    src={EmployeeEditIcon}
                    alt="edit-icon"
                    className="menuActionIcon"
                  />
                  <Typography component="span">Edit</Typography>
                </MenuItem>
                <MenuItem
                  onClick={this.handleDeleteOpen}
                  data-test-id="delete-event-btn"
                  className="menuItem"
                >
                  <img
                    src={EmployeeDeleteIcon}
                    alt="edit-icon"
                    className="menuActionIcon"
                  />
                  <Typography component="span">Delete</Typography>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box>
        </Box>
      ))
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <EmployeeManagementContainer>
          <Box className="titleBtnContainer">
            <Typography className="empListTitle">Employees list</Typography>
            <Button
              className="addNewEmpBtn"
              onClick={this.handleAddEmpClick}
              data-test-id="add-new-emp-btn"
            >
              Add New Employee
            </Button>
          </Box>
          {this.renderEmployeeList()}
          {this.state.isDeleteOpen && this.renderDeleteModal()}
          {this.state.isAddOpen && this.renderAddEmployeeModal()}
        </EmployeeManagementContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const EmployeeManagementContainer = styled(Box)({
  backgroundColor: "#ffffff",
  borderRadius: "16px",
  "& .titleBtnContainer": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "51px",
    alignItems: "center",
    padding: "58px 30px 0 30px",
    "@media (max-width: 600px)": {
      alignItems: "center",
      gap: "12px",
      padding: "58px 20px 0 20px",
    },
    "@media (max-width: 450px)": {
      padding: "58px 15px 0 15px",
    },
  },
  "& .empListTitle": {
    fontSize: "20px",
    fontWeight: 700,
    "@media (max-width: 600px)": {
      fontSize: "18px",
    },
    "@media (max-width: 450px)": {
      fontSize: "16px",
    },
  },
  "& .addNewEmpBtn": {
    width: "253px",
    fontSize: "18px",
    fontWeight: 700,
    color: "#6F05EA",
    textTransform: "none",
    borderRadius: "100px",
    border: "1px solid #6F05EA",
    "@media (max-width: 600px)": {
      fontSize: "16px",
      maxWidth: "253px",
      padding: "5px 12px",
      width: "unset",
    },
    "@media (max-width: 450px)": {
      fontSize: "14px",
      padding: "5px 8px",
    },
  },
  "& .defaultListContainer": {
    display: "flex",
    alignItems: "center",
    padding: "58px 10px",
    flexDirection: "column",
    gap: "23px",
  },
  "& .defaultNewEmpIcon": {
    height: "68px",
    width: "68px",
  },
  "& .defaultNewEmpTypo": {
    color: "#B7B7B7",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
  },
  "& .empListContainer": {
    padding: "32px 62px 35px 62px",
    "@media (max-width: 860px)": {
      padding: "32px 42px 35px 42px",
    },
    "@media (max-width: 821px)": {
      padding: "32px 32px 35px 32px",
    },
  },
  "& .empDataContainer": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .infoContainer": {
    display: "flex",
    gap: "32px",
    "@media (max-width: 600px)": {
      fontSize: "26px",
    },
    "@media (max-width: 450px)": {
      gap: "20px",
    },
  },
  "& .actionContainer": {
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },
  "& .empName": {
    fontSize: "24px",
    fontWeight: 600,
    color: "#0F172A",
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
    "@media (max-width: 450px)": {
      fontSize: "16px",
    },
  },
  "& .empEmail": {
    color: "#B7B7B7",
    fontSize: "16px",
    fontWeight: 600,
    wordBreak: "break-word",
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
    "@media (max-width: 450px)": {
      fontSize: "12px",
    },
  },
  "& .empServiceType": {
    backgroundColor: "#E2CDFB",
    color: "#6F05EA",
    fontSize: "12px",
    fontWeight: 400,
    borderRadius: 40,
    textAlign: "center",
    width: "86px",
  },
  "& .empAvatarImg": {
    width: "100px",
    height: "100px",
    borderRadius: "136px",
    "@media (max-width: 600px)": {
      width: "70px",
      height: "70px",
    },
    "@media (max-width: 450px)": {
      width: "50px",
      height: "50px",
    },
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    width: "147px !important",
    marginTop: "45px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px 0px #00000008",
  },
  "& .menuItem": {
    fontSize: "12px !important",
    fontWeight: 400,
    height: 44,
    cursor: "pointer",
  },
  "& .MuiList-root": {
    padding: "0px",
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: "#F3EBFF",
    color: "#6F05EA",
    "& img": {
      filter:
        "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)",
    },
  },
  "& .menuActionIcon": {
    height: "16px",
    width: "16px",
    marginRight: "13px",
  },
});

const DeleteDialogContainer = styled(Dialog)({
  "& .MuiDialog-paperWidthMd": {
    maxWidth: "850px",
    alignItems: "center",
  },
  "& .deleteModalContent": {
    padding: "48px 116px",
    "@media (max-width: 960px)": {
      padding: "48px 75px",
    },
    "@media (max-width: 600px)": {
      padding: "36px",
    },
  },
  "& .deleteModalTypo": {
    fontSize: "23px",
    fontWeight: 700,
    textAlign: "center",
    maxWidth: "586px",
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
  },
  "& .deleteActionContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    marginTop: "44px",
    "@media (max-width: 960px)": {
      gap: "20px",
    },
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  "& .deleteModalBtn": {
    fontSize: "24px",
    fontWeight: "600",
    borderRadius: "40px",
    textTransform: "none",
    border: "1px solid",
    "@media (max-width: 960px)": {
      fontSize: "18px",
    },
  },
  "& .sureBtn": {
    color: "#B7B7B7",
    borderColor: "#B7B7B7",
    maxWidth: "265px",
    width: "100%",
  },
  "& .removeBtn": {
    color: "#6F05EA",
    borderColor: "#6F05EA",
    maxWidth: "274px",
    width: "100%",
  },
});

const AddDialogContainer = styled(Dialog)({
  "& .MuiDialog-paperWidthMd": {
    maxWidth: "883px",
    borderRadius: "16px",
    "& .imageContainer": {
      alignItems: "center",
      display: "flex",
      marginBottom: "14px",
    },
  },
});

const CustomFormLabel = styled(FormLabel)({
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "16px",
  color: "#6F05EA",
  fontFamily: "Montserrat",
  marginBottom: "4px",
});
const GridItem = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});
const CustomMenuIcon = styled(KeyboardArrowDown)({
  color: "#A6A6A6",
});
const CustomField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInputBase-input": {
    height: 20,
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontWeight: 500,
    color: "#1E293B",

    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: 500,
    },
  },
  "& .MuiSelect-select": {
    backgroundColor: "transparent",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "2px solid #6F05EA",
  },
});
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400,
});

const ImageWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "11px",
  alignItems: "center",
});
const GalleryWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "12px",
  rowGap: 20,
  marginTop: 6,
});
const UploadButton = styled(Button)({
  padding: 0,
  border: "1px solid #D9D9D9",
  borderRadius: "9px",
  width: "84px",
  height: "84px",
  textTransform: "none",
});
const UploadIcon = styled(AddCircleOutline)({
  color: "#6F05EA",
  height: 24,
  width: 24,
});
const UploadText = styled(Typography)({
  marginTop: "8px",
  fontFamily: "Montserrat",
  fontSize: "10px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center",
});
const UploadBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
});
const AddActionContainer = styled(Box)({
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  gap: 26,
  "@media (max-width: 600px)": {
    gap: 20,
  },
  "& .grayBtn": {
    color: "#4B4B4B",
    borderColor: "#4B4B4B",
  },
  "& .addModalBtn": {
    textTransform: "none",
    width: "180px",
    height: "56px",
    borderRadius: "31px",
    fontSize: 24,
    fontWeight: 600,
    border: "1px solid",
    "@media (max-width: 960px)": {
      width: "150px",
      height: "46px",
      fontSize: 20,
    },
    "@media (max-width: 600px)": {
      height: "36px",
      fontSize: 18,
    },
  },
  "& .purpleBtn": {
    color: "#6F05EA",
    borderColor: "#6F05EA",
  },
});
const AvailabilityWrapper = styled(Box)({
  marginTop: "13px",
  width: "100%",
});
const DayBoxWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  backgroundColor: "#F8F8F8",
  borderRadius: "4px",
  marginBottom: "10px",
});
const DayBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "0 20px",
  height: "60px",
  width: "100px",
  "@media (max-width: 600px)": {
    padding: "0 10px",
  },
});
const TimeBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "60px",
  padding: "0 20px",
  "@media (max-width: 600px)": {
    padding: "0 10px",
  },
});
const DayCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "&.checked-label .MuiFormControlLabel-label": {
    color: "#000000",
  },
  "&.default-label .MuiFormControlLabel-label": {
    color: "#A8A29E",
  },
  "& .MuiFormControlLabel-label": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
    fontFamily: "Montserrat",
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
  },
  "& .MuiCheckbox-root": {
    padding: "0px",
    marginRight: "16px",
  },
});
const CustomSelect = styled(Select)({
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiSelect-select": {
    backgroundColor: "transparent",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
  },
  "&.edit-select .MuiSelect-select": {
    color: "#6F05EA",
  },
  "&.edit-select .MuiSelect-icon": {
    color: "#6F05EA",
    marginTop: "-2px",
  },
});
const DownArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
    marginTop: "-2px",
  },
});

const FormTitle = styled(DialogTitle)({
  padding: "61px 36px 0px 36px",
  "@media (max-width: 600px)": {
    padding: "61px 16px 0px 16px",
    fontSize: "24px",
  },
  "& h2": {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "27px",
    color: "#6F05EA",
    "@media (max-width: 600px)": {
      fontSize: "24px",
    },
  },
});

const FormWrapper = styled(DialogContent)({
  padding: "33px 63px 61px 63px",
  "@media (max-width: 600px)": {
    padding: "33px 33px 61px 33px",
  },
});
// Customizable Area End
