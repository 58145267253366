// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SearchData } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import { ICommodities, ICustomerPet, IPropHeaderData, Price } from "../../../components/src/interfaces.web";
import { checkCondition, defaultScroll, getDayDifference, isValidValue, returnTruthyString } from "../../../components/src/HelperUtils";
import moment from "moment";

type TDayvalue = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"
interface IRepeatDays {
    label: string,
    value: TDayvalue,
    checked: boolean
}
const getTabValue = (ServiceName: string) => {
    const mapped: Record<string, number> = {
        'Hotels': 0,
        'Dog Walking': 1,
        'Grooming': 2,
        'Pet Sitting': 3,
        'Day Care': 4,
        'Training': 5,
    }
    return mapped[ServiceName]
}
const initialRepeatDays: IRepeatDays[] = [
    {
        label: "Sun",
        value: "Sunday",
        checked: false
    },
    {
        label: "Mon",
        value: "Monday",
        checked: false
    },
    {
        label: "Tue",
        value: "Tuesday",
        checked: false
    },
    {
        label: "Wed",
        value: "Wednesday",
        checked: false
    },
    {
        label: "Thu",
        value: "Thursday",
        checked: false
    },
    {
        label: "Fri",
        value: "Friday",
        checked: false
    },
    {
        label: "Sat",
        value: "Saturday",
        checked: false
    }
]
const initialHeaderData: IPropHeaderData = {
    "selectedCountry": "",
    "selectedCity": "",
    "SelectedStartDate": null,
    "SelectedEndDate": null,
    "SelectedPet": ""
}
interface IApiModel {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string | null;
    isJsonStringify?: boolean;
}

interface IServiceGalleriesUrl {
    image_url: string
}

interface IScheduleInfo {
    id: number;
    day: string;
    start_time: string;
    end_time: string;
}

interface IEstablishment {
    id: number;
    establishment_name: string;
    address: string;
    country: string;
    city: string;
    zipcode: string;
    email: string;
    phone_number: number;
    facebook_url: string;
    instagram_url: string;
    linkedin_url: string;
    tiktok_url: string;
    account_id: number;
    created_at: string;
    updated_at: string;
    activated: boolean;
    latitude: number;
    longitude: number;
}

interface ISubServicePrice {
    id: number;
    title: string;
    capacity: number;
    allows_pet: string;
    description: string;
    service_id: number;
    duration: string | null;
    shift: string | null;
    created_at: string;
    updated_at: string;
    prices: Price[];
}

interface IDetailsAttr {
    id: number;
    service_type: string;
    about: string;
    dog: boolean;
    fish: boolean;
    cats: boolean;
    bird: boolean;
    rabbit: boolean;
    reptile: boolean;
    rodents: boolean;
    pool: boolean;
    outdoor_hotel: boolean;
    veterinary_support: boolean;
    indoor_hotel: boolean;
    toys_at_display: boolean;
    litter_boxes: boolean;
    fresh_water: boolean;
    natural_food: boolean;
    poop_spaces: boolean;
    comfortable_beds: boolean;
    individual_room: boolean;
    cat_trees: boolean;
    daily_walks: boolean;
    group_rooms: boolean;
    catio: boolean;
    garden: boolean;
    socializing_activities: boolean;
    account_id: number;
    establishment_id: number;
    cancellation_policy: string;
    created_at: string;
    updated_at: string;
    service_galleries_urls: IServiceGalleriesUrl[];
    schedule_informations: IScheduleInfo[];
    establishment: IEstablishment;
    establishment_image: string;
    sub_services_with_prices: ISubServicePrice[];
}

interface IDetailsResponse {
    data: {
        id: string;
        type: string;
        attributes: IDetailsAttr;
    };
    meta: {
        message: string;
    };
    errors?: string
}
interface IPetListResp {
    data: ICustomerPet[];
}

interface ICreateBookingResponse {
    data: {
        id: string;
    };
    errors?: string
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
    id: string;
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    apiToken: string,
    // HEADER
    tabValue: number,
    headerData: IPropHeaderData,
    // BOOKING SUMMARY
    isRepeatWeekly: boolean,
    repeatDays: IRepeatDays[],
    // FORM
    selectedPetId: string,
    selectedStartDate: null | Date,
    selectedEndDate: null | Date,
    selectedShiftTime: string,
    selectedSubService: null | Price,
    selectedTransportaion: {
        checked: boolean,
        service: null | Price
    },
    selectedMedication: {
        checked: boolean,
        service: null | Price
    },
    error: {
        selectedPetId: string,
        selectedStartDate: string,
        selectedEndDate: string,
        selectedShiftTime: string,
    },
    // MODALS
    openSimpleModal: boolean,
    messageSimpleModal: string,
    openCapacityModal: boolean,
    // API  
    isDetailsLoading: boolean,
    getDetailsData: IDetailsResponse,
    petList: ICustomerPet[],
    createBookingLoading: boolean,
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class BookingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getDetailsApiCallId: string = "";
    getPetListApiCallId: string = "";
    createBookingApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            apiToken: localStorage.getItem("login_token") || "",
            tabValue: Number(localStorage.getItem('searchTab')) || 0,
            headerData: initialHeaderData,
            // BOOKING SUMMARY
            isRepeatWeekly: false,
            repeatDays: initialRepeatDays,
            // FORM
            selectedPetId: "",
            selectedStartDate: new Date(),
            selectedEndDate: null,
            selectedShiftTime: "",
            selectedSubService: null,
            selectedTransportaion: {
                checked: false,
                service: null
            },
            selectedMedication: {
                checked: false,
                service: null
            },
            error: {
                selectedPetId: "",
                selectedStartDate: "",
                selectedEndDate: "",
                selectedShiftTime: "",
            },
            // MODALS
            openSimpleModal: false,
            messageSimpleModal: "",
            openCapacityModal: false,
            // API
            getDetailsData: {} as IDetailsResponse,
            isDetailsLoading: false,
            petList: [],
            createBookingLoading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

            const navigationData = message.getData(
                getName(MessageEnum.NavigationPayLoadMessage)
            );
            if (navigationData && navigationData.searchFilterData) {
                const { city, country, start_date, end_date, your_pet, service_type } = navigationData.searchFilterData
                const data = {
                    "selectedCountry": country,
                    "selectedCity": city,
                    "SelectedStartDate": start_date,
                    "SelectedEndDate": end_date,
                    "SelectedPet": your_pet
                }
                this.setState({ headerData: data, tabValue: getTabValue(service_type) })
            }
            if (navigationData && navigationData.landingPageTabValue) {
                this.setState({ tabValue: navigationData.landingPageTabValue as number})
            }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getDetailsApiCallId:
                        this.getDetailsApiResp(responseJson);
                        break;
                    case this.getPetListApiCallId:
                        this.getPetListApiResp(responseJson);
                        break;
                    case this.createBookingApiCallId:
                        this.createBookingApiResp(responseJson);
                        break;
                    default:
                        break;
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({ tabValue: Number(newValue) });
    };

    goToAdvancedSearchPage = (data: SearchData) => {
        const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
        NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), 'AdvancedSearch');

        // PASS DATA
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);

        NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        NavigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(NavigateMsg);
    }

    async componentDidMount() {
        super.componentDidMount();
        defaultScroll()
        this.getDetailsApiCall();
        this.getUserPetList()
    };

    apiCall = async (data: IApiModel) => {
        const {
            contentType,
            method,
            endPoint,
            body,
            token,
            isJsonStringify,
        } = data;
        let header;
        if (token) {
            header = {
                "Content-Type": contentType,
                token: token,
            };
        } else {
            header = {
                "Content-Type": contentType,
            };
        }
        const requestedMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestedMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestedMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                isJsonStringify ? JSON.stringify(body) : body
            );
        runEngine.sendMessage(requestedMessage.id, requestedMessage);
        return requestedMessage.messageId;
    };

    getDetailsApiCall = async () => {
        const serviceId = this.props.navigation.getParam("navigationBarTitleText")
        { this.setState({ isDetailsLoading: true }) }
        this.getDetailsApiCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_SERVICE_DETAILS}/${serviceId}`,
            token: this.state.apiToken,
        });
    };
    getUserPetList = async () => {
        this.getPetListApiCallId = await this.apiCall({
            method: webConfigJSON.API_METHOD.GET,
            endPoint: webConfigJSON.ENDPOINTS.GET_PET_LIST,
            token: this.state.apiToken,
        });
    };

    createBooking = async () => {
        this.setState({ createBookingLoading: true })
        const bodyData = this.getBodyData()
        this.createBookingApiCallId = await this.apiCall({
            method: webConfigJSON.API_METHOD.POST,
            endPoint: webConfigJSON.ENDPOINTS.CREATE_BOOKING,
            token: this.state.apiToken,
            body: bodyData
        });
    };

    getDetailsApiResp = (responseJson: IDetailsResponse) => {
        { this.setState({ isDetailsLoading: false }) }
        if (responseJson.data && responseJson.data.attributes && !responseJson.errors) {
            this.setState({ getDetailsData: responseJson })
        }
        if (responseJson.errors) {
            this.simpleModalOpen(responseJson.errors)
            this.resetStates()
        }
    }
    getPetListApiResp = (responseJson: IPetListResp) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({
                petList: responseJson.data
            })
        } else {
            this.setState({
                petList: []
            })
        }
    }
    createBookingApiResp = (responseJson: ICreateBookingResponse) => {
        this.setState({ createBookingLoading: false })
        if (responseJson && responseJson.data && !responseJson.errors) {
            this.simpleModalOpen(webConfigJSON.DEFAULT_MSG.SUCCESS_BOOKING)
            this.resetStates()
            this.getDetailsApiCall()
            defaultScroll()
        }
        if (responseJson.errors) {
            this.handleBookingErrors(responseJson.errors)
        }
    }

    handleBookingErrors = (errorMsg: string) => {
        if (errorMsg.startsWith(webConfigJSON.DEFAULT_MSG.CAPACITY_FULL_ERROR)) {
            this.handleOpenCapacityModal()
            return
        }
        this.simpleModalOpen(errorMsg)
    } 
    resetStates = () => {
        this.setState({
            // BOOKING SUMMARY
            isRepeatWeekly: false,
            repeatDays: initialRepeatDays,
            // FORM
            selectedPetId: "",
            selectedStartDate: new Date(),
            selectedEndDate: null,
            selectedShiftTime: "",
            selectedSubService: null,
            selectedMedication: {
                checked: false,
                service: null
            },
            selectedTransportaion: {
                checked: false,
                service: null
            },
            error: {
                selectedPetId: "",
                selectedStartDate: "",
                selectedEndDate: "",
                selectedShiftTime: "",
            },
        })
    }
    getComplementaryService = () => {
        const subServicesWithPrices = this.state.getDetailsData?.data?.attributes?.sub_services_with_prices || [];
        const complementaryServices = subServicesWithPrices.flatMap((subService) =>
            subService.prices ? subService.prices.filter((price) => webConfigJSON.COMPLEMENTARY_SERVICE.includes(price.title)) : []
        );
        return complementaryServices;
    };

    convertCommoditiesAttributes = (
        attributes: ICommodities,
    ) => {
        return Object.entries(attributes)
            .filter(([, value]) => value === true)
            .map(([key, value],) => ({
                label: key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()),
                key: key,
                value: value,
            }));
    };

    getCommoditiesList = () => {
        const attribute = this.state.getDetailsData?.data?.attributes;
        if (attribute) {
            const {
                pool,
                outdoor_hotel,
                veterinary_support,
                indoor_hotel,
                toys_at_display,
                litter_boxes,
                fresh_water,
                natural_food,
                poop_spaces,
                comfortable_beds,
                individual_room,
                cat_trees,
                daily_walks,
                group_rooms,
                catio,
                garden,
                socializing_activities,
            } = attribute;

            const commodities = {
                pool,
                outdoor_hotel,
                veterinary_support,
                indoor_hotel,
                toys_at_display,
                litter_boxes,
                fresh_water,
                natural_food,
                poop_spaces,
                comfortable_beds,
                individual_room,
                cat_trees,
                daily_walks,
                group_rooms,
                catio,
                garden,
                socializing_activities,
            };
            return this.convertCommoditiesAttributes(commodities);
        }
        return []
    }

    getAllowedPetList = () => {
        const { petList } = this.state
        const attribute = this.state.getDetailsData?.data?.attributes;
        if (attribute) {
            const { dog, fish, cats, bird, rabbit, reptile, rodents } = attribute
            const petsObj: { [key: string]: boolean } = { dog, fish, cats, bird, rabbit, reptile, rodents };
            const allowedPetCategories = Object.keys(petsObj).filter((key) => petsObj[key as keyof typeof petsObj]);
            const filteredPets = petList.filter(pet => {
                const petCategory = pet.attributes.pet_category.toLowerCase();
                return allowedPetCategories.some(allowedPet => petCategory.includes(allowedPet));
            });
            return filteredPets
        }
        return []
    }
    serviceName = () => returnTruthyString(this.state.getDetailsData?.data?.attributes?.service_type)

    // SET FORM VALUES
    setSubService = (subServicePrice: Price | null) => {
        this.setState({
            selectedSubService: subServicePrice
        })
    }
    setPetId = (petId: string | number) => {
        this.setState({
            selectedPetId: String(petId),
            error: {
                ...this.state.error,
                selectedPetId: checkCondition(isValidValue(petId), "", webConfigJSON.FIELDS_ERROR.PET) as string
            }
        })
    }
    setStartEndDate = (date: Date | null, start_end: "start" | "end") => {
        if (start_end == "start") {
            this.setState({
                selectedStartDate: date,
                selectedEndDate: null,
                error: {
                    ...this.state.error,
                    selectedStartDate: checkCondition(isValidValue(date), "", webConfigJSON.FIELDS_ERROR.START_DATE) as string,
                    selectedEndDate: ""
                }
            })
        } else {
            this.setState({
                selectedEndDate: date,
                error: {
                    ...this.state.error,
                    selectedEndDate: checkCondition(isValidValue(date), "", webConfigJSON.FIELDS_ERROR.END_DATE) as string
                }
            })
        }
    }
    setShiftTime = (shiftTime: string) => {
        this.setState({
            selectedShiftTime: shiftTime,
            error: {
                ...this.state.error,
                selectedShiftTime: checkCondition(isValidValue(shiftTime), "", webConfigJSON.FIELDS_ERROR.SHIFT_TIME) as string
            }
        })
    }
    setComplementaryService = (checkedValue: boolean, serviceType: "medication" | "transportation", service: Price) => {
        if (serviceType == "medication") {
            if (checkedValue) {
                this.setState({
                    selectedMedication: {
                        checked: checkedValue,
                        service: service
                    }
                })
            } else {
                this.setState({
                    selectedMedication: {
                        checked: false,
                        service: null
                    }
                })
            }
        }
        if (serviceType == "transportation") {
            if (checkedValue) {
                this.setState({
                    selectedTransportaion: {
                        checked: checkedValue,
                        service: service
                    }
                })
            } else {
                this.setState({
                    selectedTransportaion: {
                        checked: false,
                        service: null
                    }
                })
            }
        }
    }
    handleRepeatBtn = () => {
        this.setState({
            isRepeatWeekly: !this.state.isRepeatWeekly
        })
        if (!this.state.isRepeatWeekly) {
            this.setState({
                repeatDays: initialRepeatDays
            })
        }
    }
    setRepeatDay = (dayIndex: number) => {
        this.setState((prevState) => ({
            repeatDays: prevState.repeatDays.map((day, index) =>
                index === dayIndex ? { ...day, checked: !day.checked } : day
            ),
        }));
    }

    onSave = () => {
        const { selectedSubService } = this.state
        if (!localStorage.getItem("isUserLogin")) {
            this.props.navigation.navigate("EmailAccountLoginBlock");
            return
        }
        
        if (!selectedSubService) {
            this.simpleModalOpen(webConfigJSON.DEFAULT_MSG.SELECT_SUB_SERVICE_MSG)
            return
        }
        const validationPassed = this.checkValidation()
        if (validationPassed) {
           this.createBooking()
        }
    }
    checkValidation = () => {
        const { selectedPetId, selectedStartDate, selectedEndDate, selectedShiftTime } = this.state
        const serviceName = this.serviceName()

        let validateEndDate = ""
        if (serviceName == webConfigJSON.SERVICES_ENUM.HOTELS) {
            validateEndDate = checkCondition(isValidValue(selectedEndDate), "", webConfigJSON.FIELDS_ERROR.END_DATE) as string
        }

        let validateShiftTime = ""
        if (serviceName == webConfigJSON.SERVICES_ENUM.DOG_WALKING) {
            validateShiftTime = checkCondition(isValidValue(selectedShiftTime), "", webConfigJSON.FIELDS_ERROR.SHIFT_TIME) as string
        }

        let errorObj = {
            selectedPetId: checkCondition(isValidValue(selectedPetId), "", webConfigJSON.FIELDS_ERROR.PET) as string,
            selectedStartDate: checkCondition(isValidValue(selectedStartDate), "", webConfigJSON.FIELDS_ERROR.START_DATE) as string,
            selectedEndDate: validateEndDate,
            selectedShiftTime: validateShiftTime,
        }
        this.setState({
            error: errorObj
        })

        const isAllPass = Object.values(errorObj).every(value => value == "");
        return isAllPass
    }
    getBodyData = () => {
        const {
            selectedPetId,
            selectedStartDate,
            selectedEndDate,
            selectedShiftTime,
            selectedSubService,
            selectedMedication,
            selectedTransportaion,
            isRepeatWeekly,
            repeatDays
        } = this.state
        const serviceName = this.serviceName()

        const formatedStartDate = moment(selectedStartDate).format('YYYY-MM-DD') 
        const formData =  new FormData()
        
        formData.append("schedule[sub_services_price_id]", returnTruthyString(selectedSubService?.id));
        formData.append("schedule[pet_id]", selectedPetId);
        formData.append("schedule[start_date]", formatedStartDate);

        if (serviceName == webConfigJSON.SERVICES_ENUM.HOTELS) {
            const formatedEndDate = moment(selectedEndDate).format('YYYY-MM-DD')
            formData.append("schedule[end_date]", formatedEndDate);
        }

        if (serviceName == webConfigJSON.SERVICES_ENUM.DOG_WALKING) {
            formData.append("schedule[shift]", selectedShiftTime);
        }

        if (selectedMedication.checked && selectedMedication.service !== null && selectedMedication.service.price) {
            formData.append("schedule[medication]", returnTruthyString(selectedMedication.service.price));
        }

        if (selectedTransportaion.checked && selectedTransportaion.service !== null && selectedTransportaion.service.price) {
            formData.append("schedule[transportation]", returnTruthyString(selectedTransportaion.service.price));
        }

        if (isRepeatWeekly) {
            // FILTER CHECKED DAYS
            const checkedDays = repeatDays.filter(day => day.checked);
            checkedDays.forEach((eachDay) => {
                formData.append("schedule[week_days][]", eachDay.value);
            })
        }
        return formData

    }
    calculateHotelNights = () => {
        const { selectedStartDate, selectedEndDate } = this.state
        const serviceName = this.serviceName()
        if ((serviceName === webConfigJSON.SERVICES_ENUM.HOTELS) && selectedStartDate && selectedEndDate) {
            const noOfDays = getDayDifference(selectedStartDate, selectedEndDate)
            return noOfDays
        }
    }
    calculateTotalPrice = () => {
        const { selectedSubService, selectedMedication, selectedTransportaion, selectedStartDate, selectedEndDate } = this.state

        let total = 0;
        if (selectedSubService) {
            let SubServicePrice = Number(selectedSubService.price)
            const noOfNights = this.calculateHotelNights()
            if (noOfNights) {
                SubServicePrice = (SubServicePrice) * (noOfNights)
            }
            total = total + SubServicePrice
        }
        if (selectedMedication.checked && selectedMedication.service !== null) {
            total = total + Number(selectedMedication.service.price)
        }
        if (selectedTransportaion.checked && selectedTransportaion.service !== null) {
            total = total + Number(selectedTransportaion.service.price)
        }
        return total
    }
    simpleModalOpen = (message: string) => {
        this.setState({
            openSimpleModal: true,
            messageSimpleModal: message
        })
    }
    simpleModalClose = () => {
        this.setState({
            openSimpleModal: false,
            messageSimpleModal: ""
        })
    }

    handleOpenCapacityModal = () => {
        this.setState({
            openCapacityModal: true,
        })
    }
    handleCloseCapacityModal = () => {
        this.setState({
            openCapacityModal: false,
        })
    }
    getRepeatDayDisable = (day: IRepeatDays) => {
        let disable = true

        const ScheduleInfo = this.state.getDetailsData?.data?.attributes?.schedule_informations
        if (ScheduleInfo && ScheduleInfo.length) {
            const matchingRecord = ScheduleInfo.find((daySchedule) => daySchedule.day == day.value)
            if (matchingRecord) {
                disable = false
            }
        }
        return disable
    } 
    // Customizable Area End
}

