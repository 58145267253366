import { useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';

const useBreakpoints = () => {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const [isTabletAndLower, setIsTabletAndLower] = useState(false);

  const updateMedia = () => {
    const width = window.innerWidth;
    setIsMobile(width < theme.breakpoints.values.sm);
    setIsTablet(width >= theme.breakpoints.values.sm && width < theme.breakpoints.values.md);
    setIsLaptop(width >= theme.breakpoints.values.md);
    setIsTabletAndLower(width < theme.breakpoints.values.md);
  };

  useEffect(() => {
    updateMedia(); 
    window.addEventListener('resize', updateMedia);
    
    return () => {
      window.removeEventListener('resize', updateMedia);
    };
  }, [theme.breakpoints.values]);

  return { isMobile, isTablet, isLaptop, isTabletAndLower };
};

export default useBreakpoints;