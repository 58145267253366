import React, { useState } from "react";
import GenericModal from "../../../../components/src/GenericModal";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, FormikErrors, FormikTouched } from "formik";
import InputField from "../../../../components/src/InputField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FormWrapper } from "./FormWrapper";

interface IProps {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    onSubmit: (values: EmailFormValues) => void;
    goToForgotPassword: () => void;
    getInputProps: <T extends object>(
        field: keyof T,
        errors: FormikErrors<T>,
        touched: FormikTouched<T>,
        values: T,
        isEdit: boolean,
    ) => any;
    error?: string;
}

export type EmailFormValues = {
    email: string;
    confirm_email: string;
    current_password: string;
}

const initialValues = {
    email: '',
    confirm_email: '',
    current_password: ''
}

const validationSchema = Yup.object().shape({
    current_password: Yup.string()
      .required('*Current password is required').trim(),
    email: Yup.string()
      .email("*Invalid email address.")
      .required("*Email is required.")
      .test("email", "*Invalid email address", (value) => {
        if (!value) return false;
        return /^[a-zA-Z0-9._+~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
      })
    .trim(),
    confirm_email: Yup.string()
      .when('email', {
        is: (email) => (email && email.length > 0),
        then: Yup.string().oneOf([Yup.ref('email')], '*Email do not match.')
      })
      .required('*Email is required').trim()
});

const ResetEmailModal = (props: IProps) => {
    const { isOpen, title = "", onClose, onSubmit, getInputProps, goToForgotPassword, error  } = props;
    const [currentVisible, setCurrentVisible] = useState(true);

    const renderEndAdornment = () => (
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setCurrentVisible(!currentVisible)}
          edge="end"
        >
          {currentVisible ? <Visibility /> : <VisibilityOff />}
        </IconButton>
    );

    return (
        <GenericModal 
            id='email-reset' 
            isOpen={isOpen} 
            title={title} 
            onClose={onClose}
            bodyStyle={{ padding: '30px 50px' }} 
            headerStyle={{ color: '#6F05EA'}}
            withoutCloseBtn
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            data-test-id="reset-email-form"
          >
            {({
                values,
                errors,
                touched,
                dirty,
                handleSubmit,
                getFieldProps,
                resetForm,
              }) => {
                return (
                    <FormWrapper>
                        {error && <Box className="error-box">{error}</Box>}
                        <form onSubmit={handleSubmit} className="form">
                            <Box>
                                <InputField
                                    {...getFieldProps("email")}
                                    {...getInputProps("email", errors, touched, values, true)}
                                    placeholder="New email"
                                    label="Insert new email"
                                />
                            </Box>
                            <Box>
                                <InputField
                                    {...getFieldProps("confirm_email")}
                                    {...getInputProps("confirm_email", errors, touched, values, true)}
                                    placeholder="Confirm New email"
                                    label="Confirm New email"
                                />
                            </Box>
                            <Box>
                                <InputField
                                    {...getFieldProps("current_password")}
                                    type={
                                        currentVisible
                                        ? "password"
                                        : "text"
                                    }
                                    {...getInputProps("current_password", errors, touched, values, true)}
                                    placeholder="Password"
                                    label="Password"
                                    endAdornment={renderEndAdornment()}
                                />
                                <Typography className="hint">
                                    Forgot your password? Click <span onClick={() => goToForgotPassword()} className="redirect-link">here.</span>.
                                </Typography>
                            </Box>
                            <Box className="box-wrapper">
                                    {dirty 
                                        ? <Button className="secondary-btn" onClick={() => resetForm()}>Clear</Button> 
                                        : <Button className="secondary-btn" onClick={() => onClose()}>Cancel</Button>
                                    }
                                    <Button className="main-btn" type="submit">Save</Button>
                            </Box>
                        </form>
                    </FormWrapper>
                )
              }}
          </Formik>
        </GenericModal>
    )
}

export default ResetEmailModal;